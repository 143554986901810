import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CompositionNavLink = ({ viewBy, currentView, children }) => {
  const url = viewBy === 'all' ? '/compositions' : `/compositions/by/${viewBy}`
  const className = viewBy === currentView ? 'active' : ''
  return (
    <li><Link to={url} className={className}>
      {children}
    </Link></li>
  )
}

CompositionNavLink.propTypes = {
  viewBy: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default CompositionNavLink
