import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

let PersonLink = ({person, role, className}) => {
  const musician_url = `/compositions/performer/${person.identifier}`
  return (
    <div className={className} key={person.id}>
      <Link to={musician_url}>{person.first_name} {person.last_name}</Link> <em>{role}</em>
    </div>
  )
}

PersonLink.propTypes = {
  person: PropTypes.object,
  role: PropTypes.string,
  className: PropTypes.string
}

export default PersonLink