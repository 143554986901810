/*eslint react/prop-types: "off"*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MediaFeature from '../components/media_features/MediaFeature'
import orm from '../models/orm'
import { fetchStreamLinksIfNeeded } from '../actions/misc_streams'
import { fetchMediaFeaturesIfNeeded, MEDIA_TYPE_MEDIA_FEATURE  } from '../actions/media_type_fetch'
import { pauseEvent } from '../actions/player'
import { flashMessage } from 'redux-flash'

class MediaFeatureFetcher extends Component {

  fetchData() {
    if(!this.props.fetchedFeatures) {
      this.props.fetchMediaFeaturesIfNeeded()
    }
  }
  componentDidMount() {
    this.fetchData(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps)
  }

  render () {
    return <MediaFeature {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchCompleteCombined = state.data.isCompleteCombined
  const fetchedFeatures = state.data.fetchedMediaType[MEDIA_TYPE_MEDIA_FEATURE]
  let feature
  if(fetchedFeatures) {
    const { MediaFeature } = orm.session(state.data.orm)
    feature = MediaFeature.findByIdentifier(ownProps.match.params.identifier)
  }
  return {
    fetchCompleteCombined: fetchCompleteCombined,
    fetchedFeatures: fetchedFeatures,
    feature: feature,
    linksFetched: !! (feature && feature.fetched_streaming_links)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchMediaFeaturesIfNeeded: () => {
      dispatch(fetchMediaFeaturesIfNeeded())
    },
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    },
    fetchStreamLinksIfNeeded: (stream) => {
      dispatch(fetchStreamLinksIfNeeded(stream))
    },
    pauseAudio: () => {
      dispatch(pauseEvent())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFeatureFetcher)