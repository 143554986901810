import orm from '../models/orm'
import { getStorage, setStorage, HandledError, fetchJwtOptions } from '../util'
import { flashMessage } from 'redux-flash'
import { fetchAllCompositionDetails } from './fetch_composition'
import { setCurrentPageRedirect, getAccessToken } from './user'

export function playNow(composition_id, performance_id) {
  return playOrAdd(composition_id, performance_id, true)
}

export function addToQueue(composition_id, performance_id) {
  return playOrAdd(composition_id, performance_id, false)
}

function playOrAdd(composition_id, performance_id, playNow) {
  return (dispatch, getState) => {

    const state = getState()
    const session = orm.session(state.data.orm)
    const { Composition, Performance } = session
    let composition = Composition.withId(composition_id)
    let performance = Performance.withId(performance_id)

    if (performance.fetched_audio === 'success') {
      return dispatch(playFetchedPerformance(composition, performance, playNow))
    }
    else {
      return dispatch(
        fetchPerformanceAudio(performance, composition)
      ).then( () => {
          // Handles case where user does not have rights
          if (performance.fetched_audio === 'success') {
            dispatch(playFetchedPerformance(composition, performance, playNow))
          }
        }
      ).catch(e => {
        // No-op, errors handled
      })
    }
  }
}

// Returns a promise
function fetchPerformanceAudio(performance) {
  return (dispatch) => {
    const uuid = performance.audioRecording().uuid
    return dispatch(getAccessToken())
    .then( (user_token) => {
        return fetch(`${process.env.REACT_APP_API_BASE}/stream/recording/${uuid}/`, fetchJwtOptions(user_token))
        .then(response => {
          return response.json().then(json => {
            if(response.ok) {
              if(json.stream_links) {
                return dispatch(receivePerformanceAudioInfo(json, performance.id, performance))
              }
              return Promise.reject(new Error('No stream links'))
            }
            else {
              return handleStreamLinksFailure(response, json, dispatch, performance, 'audio')
            }
          })
      })
      .catch(error => {
        if(error.name !== 'HandledError') {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
      })
    })
  }
}

export function handleStreamLinksFailure(response, json, dispatch, parentRecord, streamType) {
  let errorMessage, failureType
  if(response.status === 401) {
    errorMessage = "You need to be signed in to play that performance."
    failureType = 'user'
    dispatch(setCurrentPageRedirect())
    dispatch(flashMessage(errorMessage, {push: true, timeout: 5000}))
    dispatch(failedFetchingStreamInfo(failureType, errorMessage, streamType, parentRecord))
    return Promise.reject(new HandledError('Requires login'))
  }
  else if(response.status === 403) {
    let errorMessage, failureType
    if(json.detail && json.detail.indexOf("verify") !== -1) {
      failureType = 'user'
      errorMessage = "Your email is not verified! Check your inbox for an email from us."
    }
    else {
      failureType = 'rights'
      errorMessage = "We’re sorry, but this performance is not currently available in your location."
    }
    dispatch(flashMessage(errorMessage, {push: true, timeout: 5000}))
    dispatch(failedFetchingStreamInfo(failureType, errorMessage, streamType, parentRecord))
    return Promise.reject(new HandledError('Recording permission'))
  }
}

export const RECEIVE_PERFORMANCE_AUDIO_INFO = 'RECEIVE_PERFORMANCE_AUDIO_INFO'
function receivePerformanceAudioInfo(json, performance_id, performance = null) {
  return {
    type: RECEIVE_PERFORMANCE_AUDIO_INFO,
    json: json,
    performance_id: performance_id,
    performance: performance
  }
}

export const FAILED_FETCHING_STREAM_INFO = 'FAILED_FETCHING_STREAM_INFO'
export function failedFetchingStreamInfo(failureType, errorMessage, streamType = 'audio', parentRecord = null) {
  return {
    type: FAILED_FETCHING_STREAM_INFO,
    failure_type: failureType,
    parent_record: parentRecord,
    stream_type: streamType,
    error_message: errorMessage
  }
}

export const PLAY_NOW = 'PLAY_NOW'
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE'
function playFetchedPerformance(composition, performance, playNow) {
  if(playNow) {
    return {
      type: PLAY_NOW,
      performance: performance,
      composition: composition
    }
  }
  else {
    return {
      type: ADD_TO_QUEUE,
      performance: performance,
      composition: composition
    }
  }
}

export const STORAGE_KEY_QUEUE = 'queue'
export const STORAGE_KEY_CURRENT = 'current-item'

export const LOAD_QUEUE = 'LOAD_QUEUE'
export function loadQueueFromStorage() {
  return dispatch => {
    let savedQueue = getStorage(STORAGE_KEY_QUEUE) || []
    const originalQueueLength = savedQueue.length
    const savedItem = getStorage(STORAGE_KEY_CURRENT) || 0

    savedQueue.forEach( (queueItem) => {
      dispatch(receivePerformanceAudioInfo({stream_links: queueItem.stream_links }, queueItem.performance_id ))
    })

    const identifiers = savedQueue.map( (queueItem) => queueItem.composition_identifier )
    return fetchAllCompositionDetails(identifiers, dispatch)
      .then( (result) => {
        // Handle failed queue items, returned as string identifiers
        result.forEach( (resultItem) => {
          if(typeof resultItem === 'string') {
            savedQueue = savedQueue.filter( (queueItem) => {return queueItem.composition_identifier !== resultItem })
          }
        })
        if(savedQueue.length !== originalQueueLength) {
          setStorage(STORAGE_KEY_QUEUE, savedQueue)
        }
        dispatch(loadQueueAction(savedQueue, savedItem))
      }
    )
  }
}

function loadQueueAction(savedQueue, savedItem) {
  return {
    type: LOAD_QUEUE,
    savedQueue: savedQueue,
    savedItem: savedItem
  }
}

export const PAUSE_EVENT = 'PAUSE_EVENT'
export function pauseEvent() {
  return {
    type: PAUSE_EVENT
  }
}

export const RESUME_EVENT = 'RESUME_EVENT'
export function resumeEvent() {
  return {
    type: RESUME_EVENT
  }
}

export const NEXT_EVENT = 'NEXT_EVENT'
export function nextEvent() {
  return {
    type: NEXT_EVENT
  }
}

export const PREV_EVENT = 'PREV_EVENT'
export function prevEvent() {
  return {
    type: PREV_EVENT
  }
}

export const PLAYER_TIME_EVENT = 'PLAYER_TIME_EVENT'
export function playerTimeEvent(position) {
  return {
    type: PLAYER_TIME_EVENT,
    position: position
  }
}

// Player resumed itself (eg clicking on timeline)
export const PLAYER_PLAYED_EVENT = 'PLAYER_PLAYED_EVENT'
export function playerPlayedEvent() {
  return {
    type: PLAYER_PLAYED_EVENT
  }
}

export const SET_QUEUE_ITEM_EVENT = 'SET_QUEUE_ITEM_EVENT'
export function setQueueItemEvent(itemIndex) {
  return {
    type: SET_QUEUE_ITEM_EVENT,
    itemIndex: itemIndex
  }
}

export const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE'
export function removeFromQueueEvent(index) {
  return (dispatch, getState) => {
    // if index is currently playing, stop player
    const pause = getState().playerStatus.currentItem === index
    return dispatch(removeFromQueue(index, pause))
  }
}
function removeFromQueue(index, pause = false) {
  return {
    type: REMOVE_FROM_QUEUE,
    itemIndex: index,
    pause: pause
  }
}

export const ITEM_FINISHED_EVENT = 'ITEM_FINISHED_EVENT'
export function itemFinishedEvent() {
  return {
    type: ITEM_FINISHED_EVENT
  }
}
export const PLAYER_FINISHED_EVENT = 'PLAYER_FINISHED_EVENT'
export function playerFinishedEvent() {
  return {
    type: PLAYER_FINISHED_EVENT
  }
}
