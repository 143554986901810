import { connect } from 'react-redux'
import { sendForgotPassword } from '../actions/user'
import ForgotPassword from '../components/user/ForgotPassword'

const mapStateToProps = (state, ownProps) => ({
  signedIn: !! state.user.currentUser
  }
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (userInfo) => {
      dispatch(sendForgotPassword(userInfo))
      return false
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword)
