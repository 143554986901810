/*eslint react/prop-types: "off"*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Home from '../components/home/Home'
import orm from '../models/orm'
import { byUnformattedComposerAndTitle } from '../util'
import { fetchLiveStreamsIfNeeded, fetchHomeDataIfNeeded, MEDIA_TYPE_LIVE_STREAM, MEDIA_TYPE_HOME } from '../actions/media_type_fetch'
import moment from 'moment'

// Since there's no onChange in Router v4
// cf https://github.com/ReactTraining/react-router/issues/3854
class HomeDataFetcher extends Component {

  componentDidMount() {
    if(! this.props.liveStreamsFetched) {
      this.props.fetchStreamsIfNeeded()
    }
    if(! this.props.hasHomeData) {
      this.props.fetchHomeDataIfNeeded()
    }
  }

  render () {
    return <Home {...this.props} />
  }
}

const compositionHasImage = (composition) => {
  return composition.preferred_image
}

const compositions = (fetchComplete, viewBy, orm_state) => {
  if(!fetchComplete) {
    return []
  }
  if(viewBy === 'video') {
    return orm.session(orm_state).Composition.all()
    .filter({unlisted: false, forDisplay: true, has_video: true})
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
    .slice(0, 4)
  }
  else {
    return orm.session(orm_state).Composition.all()
    .filter({unlisted: false, forDisplay: true})
    .filter(compositionHasImage)
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
    .slice(0, 4)
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchComplete = state.data.isCompleteCombined

  const composerSlugs = ['ludwig-van-beethoven', 'franz-joseph-haydn', 'wolfgang-amadeus-mozart', 'jessie-montgomery']
  let videoPrograms, audioPrograms, homeBody, highlights, collections, homeVideo, homeVideoPosterImage, composers
  if(fetchComplete) {
    videoPrograms = orm.session(state.data.orm)
                .Program.all()
                .orderBy(['end_date_time'], 'desc')
                .filter({has_video: true})
                .toModelArray()
                .slice(0, 4)
    audioPrograms = orm.session(state.data.orm)
                .Program.all()
                .orderBy(['end_date_time'], 'desc')
                .filter({has_video: false, has_audio: true})
                .toModelArray()
                .slice(0, 4)
    composers = composerSlugs.map( (slug) => {
      return orm.session(state.data.orm)
                    .Person.all()
                    .filter({identifier: slug})
                    .toModelArray()[0]
    }).filter( (composer) => composer !== undefined)
  }
  const hasHomeData = state.data.fetchedMediaType[MEDIA_TYPE_HOME]
  if(hasHomeData) {
    const homeHeadItem = orm.session(state.data.orm)
                .HomeContent.all()
                .filter({identifier: 'home-headline'})
                .toModelArray()[0]
    homeBody = homeHeadItem ? homeHeadItem.body_text: null
    const homeVideoItem = orm.session(state.data.orm)
                .HomeContent.all()
                .filter({identifier: 'home-video'})
                .toModelArray()[0]

    if(homeVideoItem) {
      homeVideo = homeVideoItem.url
      homeVideoPosterImage = homeVideoItem.image
    }

    highlights = orm.session(state.data.orm)
                .HomeContent.all()
                .filter({content_type: 'composition'})
                .toModelArray()
                .slice(0, 4).map( (contentItem) => {
                  if(contentItem.performance) {
                    return contentItem.performance
                  }
                  else {
                    return null
                  }
                }).filter((p) => p !== null)

    collections = orm.session(state.data.orm)
                .HomeContent.all()
                .filter({content_type: 'curated_collection'})
                .toModelArray()
                .slice(0, 4).map( (contentItem) => {
                  if(contentItem.curated_collection) {
                    return contentItem.curated_collection
                  }
                  else {
                    return null
                  }
                })
  }

  const liveStreamsFetched = state.data.fetchedMediaType[MEDIA_TYPE_LIVE_STREAM]
  let nextStream, showUpcomingStream
  if(liveStreamsFetched) {
    const upcomingStreams = orm.session(state.data.orm)
                .LiveStream.all()
                .filter((stream) => stream.status !== 'U' && stream.status !== 'C' && ! stream.is_sample_stream)
                .orderBy('start_date_time')
    if(upcomingStreams) {
      nextStream = upcomingStreams.toModelArray()[0]
      if(nextStream) {
        showUpcomingStream = nextStream.bannerDisplayStart() < moment() && ! nextStream.isComplete()
      }
    }
  }

  return {
    fetchComplete: fetchComplete,
    hasHomeData: hasHomeData,
    videoPrograms: videoPrograms,
    audioPrograms: audioPrograms,
    nextStream: nextStream,
    showUpcomingStream: showUpcomingStream,
    compositionsWithVideo: compositions(fetchComplete, 'video', state.data.orm),
    homeBody: homeBody,
    homeVideo: homeVideo,
    homeVideoPosterImage: homeVideoPosterImage,
    highlights: highlights,
    collections: collections,
    composers: composers

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchStreamsIfNeeded: () => {
      dispatch(fetchLiveStreamsIfNeeded())
    },
    fetchHomeDataIfNeeded: () => {
      dispatch(fetchHomeDataIfNeeded())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeDataFetcher)
