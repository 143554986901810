import React from 'react'
import PropTypes from 'prop-types'
import ProgramRow from './ProgramRow'

let ProgramList = ({programs, viewBy, viewAsTable}) => {

  const listClass = viewAsTable ? 'programs image_list' : 'programs image_grid'
  const programItems = programs.map(program =>
    <ProgramRow program={program} key={program.id} viewAsTable={viewAsTable} />
  )
  return (
    <ul className={listClass}>{programItems}</ul>
  )
}

ProgramList.propTypes = {
  programs: PropTypes.array,
  viewBy: PropTypes.string,
  viewAsTable: PropTypes.bool
}

export default ProgramList