import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlashMessageContainer from '../../containers/FlashMessageContainer'
import spco from '../../../images/icons/SPCO.svg'
import concert_library from '../../../images/icons/concert_library.svg'
import SvgIcon from '../SvgIcon'
import { Link } from 'react-router-dom'

class SessionHeader extends Component {
  componentDidMount() {
    document.body.classList.toggle('session', true)
  }
  componentWillReceiveProps(nextProps) {
    document.body.classList.toggle('session', true)
  }
  componentWillUnmount() {
    document.body.classList.remove('session')
  }
  render() {
    return (
      <div className="session_page row">
        <div className="columns medium-6 large-5 small-10">
          <div className="session_header ">
            <FlashMessageContainer />
              <div className="logos">
                <div>
                    <a href="https://www.thespco.org"><SvgIcon icon={spco} className="logo" fill="#ffffff" /></a>
                </div>
                <div>
                    <Link to="/"><SvgIcon icon={concert_library} className="library"  fill="#ffffff" /></Link>
                </div>
              </div>
            </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}
SessionHeader.propTypes = {
  children: PropTypes.node
}
export default SessionHeader
