import {fk, attr} from "redux-orm"
import { SpcoModel } from './spco_model'

export class CuratedItem extends SpcoModel {
  toString() {
    return this.title;
  }
}

CuratedItem.modelName = 'CuratedItem'
CuratedItem.fields = {
  performance: fk('Performance'),
  note: attr(),
  curated_collection: fk('CuratedCollection', 'curated_items'),
  sequence: attr({ getDefault: () => 0 }),
}