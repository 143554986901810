import { connect } from 'react-redux'
import { confirmEmailAction } from '../actions/user'
import EmailConfirm from '../components/user/EmailConfirm'

const mapStateToProps = (state, ownProps) => ({
  signedIn: !! state.user.currentUser
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    confirmEmail: (signedIn) => {
      return dispatch(confirmEmailAction(ownProps.match.params.key, signedIn))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailConfirm)
