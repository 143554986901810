import { SpcoModel } from './spco_model'

export class MediaFeature extends SpcoModel {
  toString() {
    return this.title;
  }
  firstCategory() {
    return this.category_hierarchy[0]
  }
  hasSubcategories() {
    return this.category_hierarchy.length > 1
  }
  lastCategory() {
    return this.category_hierarchy.length === 2 ? this.category_hierarchy[1] : this.category_hierarchy[0]
  }
  lastCategoryTitle() {
    return this.lastCategory().title
  }
  isVideo() {
    return this.type === 'V'
  }
  captionsUrl() {
    return this.captions_url ? `${process.env.REACT_APP_AUTH_HOST}${this.captions_url}` : null
  }

  static createDecorated(rawData) {
    // Set base category on raw data
    rawData.baseCategory = rawData.category_hierarchy[rawData.category_hierarchy.length - 1].identifier
    this.create(rawData)
  }
}

MediaFeature.modelName = 'MediaFeature'
MediaFeature.fields = {

}