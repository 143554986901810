/*eslint react/prop-types: "off"*/

import React from 'react'
import { reduxForm } from 'redux-form'
// import InputField from '../form/InputField'
import { Link } from 'react-router-dom'
import SessionHeader from './SessionHeader'
import {setTitle} from '../../util'
import PropTypes from 'prop-types'


const DeleteUser = ({error, handleSubmit, pristine, reset, submitting, currentUser}, context) => {
  setTitle('Your Account')
  if(! currentUser) {
    context.router.history.push('/')
    return <div></div>
  }
  const name = currentUser.user.first_name ? `, ${currentUser.user.first_name} ${currentUser.user.last_name}.` : '.'

  function confirmSubmit(e) {
    if(window.confirm("Are you sure you want to delete your account?")) {
      handleSubmit(e)
    }
  }
  return (
    <SessionHeader>
      <form onSubmit={confirmSubmit} className="hide_labels">
        <h2>Your account</h2>
        <p className="note">
          Signed in as <strong>{currentUser.user.email}</strong>{name}.
        </p>
        <br />
        <h4>Delete Account</h4>
        <p className="note">
          Deleting your user account will delete all data associated with your account. This is a permanent deletion and cannot be undone.
        </p>
        {error && <div className="callout alert"><p>{error}</p></div>}
        <div className="">
          <button type="submit" className="button rounded strong" disabled={error || submitting}>
          Delete my user account
        </button>
        </div>
      </form>

      <div className="extra_links text-center">
        <Link to="/">Back home</Link>
      </div>
    </SessionHeader>
  )
}

DeleteUser.contextTypes = {
  router: PropTypes.object
}

// Decorate the form component
export default reduxForm({
  form: 'DeleteUser' // a unique name for this form
})(DeleteUser)

