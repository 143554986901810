/*eslint react/prop-types: "off"*/

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from '../form/InputField'
import { Link } from 'react-router-dom'
import SessionHeader from './SessionHeader'
import { required, minLength6 } from '../form/validations'
import {setTitle} from '../../util'

const ResetPassword = ({error, handleSubmit, pristine, reset, submitting}) => {
  setTitle('Reset your password')

  return (
    <SessionHeader>
      <form onSubmit={handleSubmit} className="hide_labels">
        <p className="note">
          Enter a new password below to set a new password on your account.
        </p>
        <Field name="password" label="Choose a password" type="password" component={InputField} required="true" validate={[ required, minLength6 ]} />
        <Field name="password_confirmation" label="Confirm your password" type="password" component={InputField} required="true" validate={[ required ]} />

        {error && <div className="callout alert"><p>{error}</p></div>}
        <div className="submit_section">
          <button type="submit" className="button large rounded strong" disabled={pristine || error || submitting}>
          Reset Your Password
        </button>
        </div>
      </form>

      <div className="extra_links text-center">
        <Link to="/sign-in">Sign In</Link>
      </div>
    </SessionHeader>
  )
}

// Decorate the form component
export default reduxForm({
  form: 'ResetPassword' // a unique name for this form
})(ResetPassword)

