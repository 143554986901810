import React from 'react'
import PropTypes from 'prop-types'
import ImageSelector from '../ImageSelector'

let ComposerRow = ({composer, viewAsTable}, context) => {
  let handleClick = (e) => {
    context.router.history.push(`/compositions/composer/${composer.identifier}`)
  }
  return (
    <li onClick={handleClick} key={composer.id}>
      <div className="image">
        <ImageSelector imageList={composer.preferred_image} size="wd_256x144" />
      </div>
      <div className="title">
        <h4><span>{composer.first_name} {composer.last_name}</span></h4>
      </div>
    </li>
  )
}

ComposerRow.propTypes = {
  composer: PropTypes.object,
  viewAsTable: PropTypes.bool
}

ComposerRow.contextTypes = {
  router: PropTypes.object
}

export default ComposerRow