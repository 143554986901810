import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../containers/PageHeaderContainer'
import CompositionNav from './CompositionNav'
import CompositionsHeader from './CompositionsHeader'
import LoadingIndicator from '../LoadingIndicator'
import PersonCompositionListContainer from '../../containers/PersonCompositionListContainer'
import CategoryCompositionListContainer from '../../containers/CategoryCompositionListContainer'

let CompositionsForGroupWrapper = ({viewBy, fetchComplete, viewAsTable, title, categoryIdentifier}) => {

  const mainListing = ((viewBy, fetchComplete) => {
    if(! fetchComplete) {
      return <LoadingIndicator margin="70px" />;
    }
    switch (viewBy) {
    case 'composer':
    case 'performer':
    case 'conductor':
      return <PersonCompositionListContainer viewBy={viewBy} personIdentifier={categoryIdentifier} />
    case 'genre':
    case 'nationality':
      return <CategoryCompositionListContainer viewBy={viewBy} categoryIdentifier={categoryIdentifier} />
    }

  })(viewBy, fetchComplete)

  return (
    <div>
      <PageHeader section="compositions">
        <CompositionsHeader title={title} viewBy={viewBy} />
        <CompositionNav viewBy={viewBy} />
      </PageHeader>
      <main className="row">
        <div className="columns">
          {mainListing}
        </div>
      </main>
    </div>
  )
}

CompositionsForGroupWrapper.propTypes = {
  fetchComplete: PropTypes.bool,
  title: PropTypes.string,
  viewBy: PropTypes.string,
  viewAsTable: PropTypes.bool,
  categoryIdentifier: PropTypes.string
}

export default CompositionsForGroupWrapper