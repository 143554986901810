import React from 'react'
import PropTypes from 'prop-types'
import { getImageSizeInstance } from '../util'

let ImageSelector = ({imageList, size, alt="", ...restProps }) => {
  if(!imageList) { return null}

  const image = getImageSizeInstance(imageList, size)
  if(! image) { return null }
  if(! alt && imageList.title) {
    alt = imageList.title
  }
  return (
    <img src={image.url} alt={alt} {...restProps} />
  )
}

ImageSelector.propTypes = {
  imageList: PropTypes.object,
  size: PropTypes.string,
  alt: PropTypes.string
}

export default ImageSelector