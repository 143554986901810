import React from 'react'
import PropTypes from 'prop-types'
import ProgramNavLink from './ProgramNavLink'
import ProgramNavItems from './ProgramNavItems'
import Popover from 'react-popover'
import SvgIcon from '../SvgIcon'
import chevron from '../../../images/icons/16/chevron-down.svg'

class ProgramNav extends React.Component {

  constructor() {
    super()
    this.toggle = this.toggle.bind(this)
    this.onOuterAction = this.onOuterAction.bind(this)
    this.state = {
     isOpen: false
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onOuterAction() {
    this.setState({ isOpen: false })
  }

  render() {
    const {viewBy} = this.props

    const navLinks = ProgramNavItems.map( (item) =>
      <ProgramNavLink viewBy={item.viewBy} currentView={viewBy} key={`comp-${item.viewBy}`}>{item.title}</ProgramNavLink>
    )

    const mobileNavCurrent = ProgramNavItems.find( (item) =>  item.viewBy === viewBy)
    const mobileList = ProgramNavItems.map( (item) =>
      <ProgramNavLink viewBy={item.viewBy} currentView={viewBy} key={`mob-${item.viewBy}`}>{item.title}</ProgramNavLink>
    )
    const dropdown = <ul onClick={this.onOuterAction}>{mobileList}</ul>
    const mobileNav = <Popover isOpen={this.state.isOpen} place="below" className="mobile_comp_popover" onOuterAction={this.onOuterAction} body={dropdown}>
        <div onClick={this.toggle}>
          <strong>{mobileNavCurrent.title}</strong>
          <SvgIcon icon={chevron} fill="#858585" height="16" width="16" />
        </div>
    </Popover>

    return (
      <div className="row collapse">
        <div className="columns show-for-medium">
          <ul id="composition_nav">{navLinks}</ul>
        </div>
        <div id="composition_nav_mobile" className="columns show-for-small-only">
          {mobileNav}
        </div>
      </div>
    )
  }
}

ProgramNav.propTypes = {
  viewBy: PropTypes.string
}

export default ProgramNav