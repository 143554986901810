import React from 'react'
import PropTypes from 'prop-types'
import SessionHeader from './SessionHeader'

const EmailConfirm = ({signedIn, confirmEmail}, context) => {
  confirmEmail(signedIn)

  return (
    <SessionHeader>
      <h4 className="text-center">Confirming your email...</h4>
    </SessionHeader>
  )
}

EmailConfirm.propTypes = {
  signedIn: PropTypes.bool,
  confirmEmail: PropTypes.func,
  alreadySignedIn: PropTypes.func
}

EmailConfirm.contextTypes = {
  router: PropTypes.object
}

export default EmailConfirm