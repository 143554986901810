import { connect } from 'react-redux'
import ViewBy from '../components/ViewBy'
import { setViewAsTable, setViewAsGrid } from '../actions/compositions'

const mapStateToProps = (state, ownProps) => ({
  viewAsTable: state.compositions.viewAsTable
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setViewAsTable: () => {
      dispatch(setViewAsTable())
    },
    setViewAsGrid: () => {
      dispatch(setViewAsGrid())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewBy)
