import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ProgramNavLink = ({ viewBy, currentView, children }) => {
  const url = viewBy === 'all' ? '/concerts' : `/concerts/${viewBy}`
  const className = viewBy === currentView ? 'active' : ''
  return (
    <li><Link to={url} className={className}>
      {children}
    </Link></li>
  )
}

ProgramNavLink.propTypes = {
  viewBy: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ProgramNavLink
