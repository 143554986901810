import React from 'react'
import PropTypes from 'prop-types'
import {formatMarkdown, stripFormatting, formatInline, setTitle, isHeadless, logAnalyticEvent} from '../../util'
import LoadingIndicator from '../LoadingIndicator'
import PageHeader from '../../containers/PageHeaderContainer'
import CompositionPerformanceContainer from '../../containers/CompositionPerformanceContainer'
import ProgramAllVideoContainer from '../../containers/ProgramAllVideoContainer'
import ReadMore from '../ReadMore'
import ImageSelector from '../ImageSelector'
import play from '../../../images/icons/24/play.svg'
import SvgIcon from '../SvgIcon'
import { Link } from 'react-router-dom'
import Meta from '../Meta'
import ShareButtons from '../ShareButtons'
import MetaTags from 'react-meta-tags'

class Program extends React.Component {
  constructor() {
    super()
    this.toggleBackToBack = this.toggleBackToBack.bind(this)
    this.state = {
     showBackToBack: false,
     loggedFullConcertStart: false
    }
  }
  toggleBackToBack(event) {
    event.preventDefault()
    if(!this.state.loggedFullConcertStart) {
      const program = this.props.program
      logAnalyticEvent('full_concert_start', {title: `${stripFormatting(program.title)} (${program.formatted_date_range})`})
    }
    this.setState({ showBackToBack: !this.state.showBackToBack, loggedFullConcertStart: true })
  }
  render() {
    const {program, fetchComplete, programFetched, flashMessage, audioOnly, relatedMediaFeatures} = this.props
    let programPerformances, programHeader, relatedFeatureWrap

    if (fetchComplete && ! program) {
      if(isHeadless()) {
        return <React.Fragment>
          <MetaTags>
            <meta name="prerender-status-code" content="404" />
          </MetaTags>
          <div className="row"><div className="columns text-center"><h1>Concert not found</h1></div></div>
        </React.Fragment>
      }
      else {
        flashMessage("Sorry, that concert was not found in the library.")
        this.context.router.history.replace(`/concerts`)
        return <div></div>
      }

    }
    else if(! programFetched) {
      programPerformances = <LoadingIndicator margin="70px" />
    }
    else {
/*eslint no-debugger: "off"*/


      const fullTitle = `${stripFormatting(program.title)} (${program.formatted_date_range})`
      setTitle(fullTitle)
      let underwriter = null
      if(program.underwriter_logo) {
        underwriter = (
          <div className="underwriter_logo">
            <p>Underwriting support provided by</p>
            <img src={`${process.env.REACT_APP_MEDIA_BASE}/${program.underwriter_logo}`} alt={program.underwriter_name} />
          </div>
        )
      }
      const image = <ImageSelector imageList={program.preferred_image} size="wd_512x288" />
      const imageCredit = program.preferred_image && program.preferred_image.credit ? <div className="image_credit">{program.preferred_image.credit}</div> : null
      const path = `/concert/${program.identifier}`
      const fbMessage = "Check out this on-demand concert from The Saint Paul Chamber Orchestra! You can stream it for free."
      const twMessage = "Check out this @thespco #OnDemand concert! #NowStreaming for #Free"
      const nativeMessage = "Check out this free on-demand SPCO concert!"
      // eslint-disable-next-line
      programHeader = (
            <div className="row">
              <div className="columns medium-4 small-12 show-for-medium">
                <div className="image">{image}</div>
                {imageCredit}
              </div>
              <div className="text columns medium-8 small-12">
                <h1 dangerouslySetInnerHTML={{__html: formatInline(program.title)}} />
                <div className="header_image show-for-small-only">
                  {image}
                  {imageCredit}
                </div>
                <h4 className="date">{program.formatted_date_range}</h4>
                <div className="program_note long_description">
                  <ReadMore lines={5} ellipsis={<span>... <a href='#'>Read more</a></span>}>
                      <div dangerouslySetInnerHTML={{__html: formatMarkdown(program.description)}} />
                  </ReadMore>
                </div>
                {underwriter}
                <div className="row">
                  {! audioOnly && ! this.state.showBackToBack && <div className="columns"><div className="play_buttons">
                    <button type="button" className="button play" onClick={this.toggleBackToBack}>
                                 <SvgIcon icon={play} fill="#ffffff" />
                                 Watch the entire concert
                    </button>
                  </div></div>}
                  <div className="columns shrink"><ShareButtons path={path} fb={fbMessage} tw={twMessage} native={nativeMessage} /></div>
                </div>
              </div>
            </div>
          )
      const performances = program.performances.orderBy('position').toModelArray().map(performance => {
        const compositionLink = performance.composition.forDisplay
        ? <Link to={`/composition/${performance.composition.identifier}`}>
            <span dangerouslySetInnerHTML={{__html: formatInline(performance.composition.title)}} />
          </Link>
          : <strong><span dangerouslySetInnerHTML={{__html: formatInline(performance.composition.title)}} /></strong>
        return (
        <div className="program_performance" key={`${performance.id}-wrap`}>
          <h3>
            {compositionLink}
            <span>{performance.composition.combined_composer_arranger_credit}</span>
          </h3>
            <CompositionPerformanceContainer performance={performance} composition={performance.composition} key={performance.id} showMPRLogo={true} showUnderwriterLogo={false} showDate={false} />
        </div>
        )
      }
      )
      programPerformances = (
        <div>
        <Meta title={fullTitle} image={program.preferred_image} description={stripFormatting(program.description)} />
          <ul className="program_performances">
            {performances}
          </ul>
        </div>
      )
      if(relatedMediaFeatures.length > 0) {
        const relatedFeatures = relatedMediaFeatures.map(relatedFeature => {
          const featureImage = <ImageSelector imageList={relatedFeature.media_feature.image} size="wd_512x288" />

          return <div className="related_feature" key={`${relatedFeature.id}-related-feature`}>
          <Link to={`/feature/${relatedFeature.media_feature.identifier}`}>
            {featureImage}
           <div className="related_title"><strong dangerouslySetInnerHTML={{__html: formatInline(relatedFeature.media_feature.title)}} /></div></Link>
            {relatedFeature.description && <div dangerouslySetInnerHTML={{__html: formatMarkdown(relatedFeature.description)}} />}
          </div>
        })

        relatedFeatureWrap = <div className="row">
          <div className="columns medium-4 small-12 show-for-medium"></div>
          <div className="columns medium-8 small-12">
            <h5>Related viewing</h5>
            <div className="related_features">
              {relatedFeatures}
            </div>
          </div>
        </div>
      }
    }

    return (
      <div>
        <PageHeader section="programs">
          <div className="row composition_show">
            <div className="columns small-12">
              <header className="image_header program">
                <div className="page_label">Concert</div>
                {programHeader}
                {relatedFeatureWrap}

              </header>
            </div>
          </div>
        </PageHeader>
        <main>
          <div className="row">
            <div className="columns">
              {programFetched && ! audioOnly && this.state.showBackToBack && <ProgramAllVideoContainer program={program} />}
              {programPerformances}
            </div>
          </div>
        </main>
      </div>
    )
  }

}

Program.propTypes = {
  program: PropTypes.object,
  relatedMediaFeatures: PropTypes.array,
  fetchComplete: PropTypes.bool,
  programFetched: PropTypes.bool,
  flashMessage: PropTypes.func,
  audioOnly: PropTypes.bool
}

Program.contextTypes = {
  router: PropTypes.object
}
export default Program