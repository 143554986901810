import React from 'react'
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import { getImageSizeInstance, stripFormatting } from '../util'

let Meta = ({title, image, description, hide, type }) => {
  if(hide) {
    return null
  }
  const fullTitle = title ? `${stripFormatting(title)} | Concert Library — The Saint Paul Chamber Orchestra` : 'Concert Library — The Saint Paul Chamber Orchestra'
  const descriptionText = description || "Enjoy more than 100 streaming performances. Always free, thanks to audience members like you."
  let imageNode, typeNode

  if(image) {
    const ogImage = getImageSizeInstance(image, "wd_512x288")
    imageNode =
        <React.Fragment>
          <meta property="og:image" content={ogImage.url} />
          <meta property="og:image:width" content={ogImage.width} />
          <meta property="og:image:height" content={ogImage.height} />
        </React.Fragment>
  }
  else {
    imageNode =
    <React.Fragment>
      <meta property="og:image" content={`${process.env.REACT_APP_AUTH_HOST}/${process.env.REACT_APP_BASEPATH}/concert-library-1200x630.png`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </React.Fragment>
  }
  if(type) {
    typeNode = <meta property="og:type" content={type} />
  }
  return (
    <MetaTags>
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      <meta property="og:url" content={'https://content.thespco.org' + window.location.pathname} />
      <meta name="description" content={descriptionText} />
      <meta property="og:description" content={descriptionText} />
    { imageNode }
    { typeNode }
    </MetaTags>
  )
}

Meta.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  hide: PropTypes.bool
}
export default Meta