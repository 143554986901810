import { many} from "redux-orm";
import { SpcoModel } from './spco_model'

export class Person extends SpcoModel {
    toString() {
      return `${this.first_name} ${this.last_name}`;
    }
    fullName() {
      return this.toString()
    }
    yearDisplay() {
      if(!this.birth_year) {
        return null
      }
      return this.death_year ? `${this.birth_year}-${this.death_year}` : `b. ${this.birth_year}`
    }
}
Person.modelName = 'Person'

Person.fields = {
  // defined on associations: compositions, conducted_performances, featured_performances
  featured_performances: many({
    to: 'Performance',
    relatedName: 'featured_people',
    through: 'FeaturedArtist'
  })
}