/*eslint react/prop-types: "off"*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Composition from '../components/composition/Composition'
import orm from '../models/orm'
import { fetchCompositionDetailIfNeeded } from '../actions/fetch_composition'
import { flashMessage } from 'redux-flash'

class CompositionFetcher extends Component {

  componentDidMount() {
    if(! this.props.hasDetail) {
      this.props.fetchCompositionIfNeeded()
    }
  }

  render () {
    return <Composition {...this.props} />
  }
}

const getComposition = (identifier, orm_state) => {
  return orm.session(orm_state).Composition.findByIdentifier(identifier)
}

const mapStateToProps = (state, ownProps) => {
  const fetchComplete = state.data.isCompleteCombined
  // temporarily handle URLs with a URL at the end
  // eg https://content.thespco.org/music/concert-library/composition/messiah-george-frideric-handelhttps://content.thespco.org/music/concert-library/composition/messiah-george-frideric-handel
  const identifier = ownProps.match.params.identifier.split(/https:/)[0]
  const composition = fetchComplete ? getComposition(identifier, state.data.orm) : null

  return {
    fetchComplete: state.data.isCompleteCombined,
    hasDetail: (composition && composition.hasDetail),
    composition: composition
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCompositionIfNeeded: () => {
      const identifier = ownProps.match.params.identifier.split(/https:/)[0]
      dispatch(fetchCompositionDetailIfNeeded(identifier))
    },
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompositionFetcher)