import React from 'react'
import PropTypes from 'prop-types'
import CategoryRow from './CategoryRow'

let GroupList = ({groups, viewBy}) => {
  const groupedItems = groups.map(group =>
     <CategoryRow key={group.identifier} viewBy={viewBy} identifier={group.identifier} name={group.name}/>
  )
  return (
    <ul className="category_listing">{groupedItems}</ul>
  )
}

GroupList.propTypes = {
  groups: PropTypes.array,
  viewBy: PropTypes.string
}

export default GroupList