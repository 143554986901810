import React from 'react'
import PropTypes from 'prop-types'
import MediaFeatureNavLink from './MediaFeatureNavLink'
import Popover from 'react-popover'
import SvgIcon from '../SvgIcon'
import chevron from '../../../images/icons/16/chevron-down.svg'
import values from 'lodash/values'

class MediaFeatureNav extends React.Component {

  constructor() {
    super()
    this.toggle = this.toggle.bind(this)
    this.onOuterAction = this.onOuterAction.bind(this)
    this.state = {
     isOpen: false
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onOuterAction() {
    this.setState({ isOpen: false })
  }

  render() {
    const {viewBy, toplevelCategories, currentMenuItem } = this.props


    const navLinks = values(toplevelCategories).map( (item) =>
      <MediaFeatureNavLink viewBy={item.identifier} currentView={viewBy} key={`comp-${item.identifier}`}>{item.title}</MediaFeatureNavLink>
    )

    const mobileList = values(toplevelCategories).map( (item) =>
      <MediaFeatureNavLink viewBy={item.identifier} currentView={viewBy} key={`mob-${item.identifier}`}>{item.title}</MediaFeatureNavLink>
    )
    const dropdown = <ul onClick={this.onOuterAction}>{mobileList}</ul>
    const mobileNav = <Popover isOpen={this.state.isOpen} place="below" className="mobile_comp_popover" onOuterAction={this.onOuterAction} body={dropdown}>
        <div onClick={this.toggle}>
          <strong>{currentMenuItem.title}</strong>
          <SvgIcon icon={chevron} fill="#858585" height="16" width="16" />
        </div>
    </Popover>

    return (
      <div className="row collapse">
        <div className="columns show-for-medium">
          <ul id="composition_nav">{navLinks}</ul>
        </div>
        <div id="composition_nav_mobile" className="columns show-for-small-only">
          {mobileNav}
        </div>
      </div>
    )
  }
}

MediaFeatureNav.propTypes = {
  viewBy: PropTypes.string,
  toplevelCategories: PropTypes.object,
  currentMenuItem: PropTypes.object
}

export default MediaFeatureNav