import React from 'react'
import PropTypes from 'prop-types'
import { stripFormatting, isHeadless } from '../../util'
import { Link } from 'react-router-dom'
import ImageSelector from '../ImageSelector'
import LazyLoad from 'react-lazyload'
import {FaVideo} from 'react-icons/fa'

let ProgramRow = ({program, viewAsTable}, context) => {
  const url = `/concert/${program.identifier}`
  let handleClick = (e) => {
    context.router.history.push(url)
  }
  let ignoreClick = (e) => {
    e.stopPropagation()
  }
  const imageHeight = viewAsTable ? 78 : 140
  const offset = isHeadless() ? 2000 : 500
  return (
    <li onClick={handleClick}>
      <div className="image">
        <LazyLoad height={imageHeight} offset={offset} once>
          <ImageSelector imageList={program.preferred_image} size="wd_256x144" />
        </LazyLoad>
      </div>
      <div className="title">
        <h4><Link to={url} onClick={ignoreClick}>{stripFormatting(program.title)}</Link>
        {program.has_video && (<span className="icons"><FaVideo /></span>) }
        </h4>
        <div className="date">{program.formatted_date_range}</div>
      </div>
    </li>
  )
}

ProgramRow.propTypes = {
  program: PropTypes.object,
  viewAsTable: PropTypes.bool
}

ProgramRow.contextTypes = {
  router: PropTypes.object
}

export default ProgramRow