import orm from '../models/orm'
import { NOT_FOUND_MESSAGE } from './fetch_handlers'
import { HandledError } from '../util'

/* Composition detail */

export const RECEIVE_PROGRAM_DETAIL= 'RECEIVE_PROGRAM_DETAIL'
function receiveProgramDetail(json) {
  return {
    type: RECEIVE_PROGRAM_DETAIL,
    json: json,
    receivedAt: Date.now()
  }
}

export const REQUEST_PROGRAM_DETAIL = 'REQUEST_PROGRAM_DETAIL'
function requestProgramDetail(identifier) {
  return {
    type: REQUEST_PROGRAM_DETAIL,
    identifier: identifier
  }
}

function fetchProgramDetail(identifier) {
  return dispatch => {
    dispatch(requestProgramDetail(identifier))
    return fetch(`${process.env.REACT_APP_API_BASE}/programs/${identifier}/?format=json`)
      .then(response => {
        if(response.status === 404) {
          return Promise.reject(new HandledError(NOT_FOUND_MESSAGE))
        }
        return response
      })
      .then(response => response.json())
      .then(json => dispatch(receiveProgramDetail(json)))
      .catch(error => {
        if(error.name !== 'HandledError') {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes, or contact us if the problem persists.")
        }
      })
  }
}

function shouldFetchProgramDetail(state, identifier) {
  const session = orm.session(state.data.orm)
  const target = session.Program.findByIdentifier(identifier)
  // Do not try to fetch detail if program does not exist
  if(state.data.isCompleteCombined && ! target) {
    return false
  }
  else {
    return ! target || ! target.hasDetail
  }
}

export function fetchProgramDetailIfNeeded(identifier) {
  return (dispatch, getState) => {
    if (shouldFetchProgramDetail(getState(), identifier)) {
      return dispatch(fetchProgramDetail(identifier))
    } else {
      return Promise.resolve()
    }
  }
}
