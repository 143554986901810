import { connect } from 'react-redux'
import Compositions from '../components/compositions/Compositions'

export const compositionTitleMap = {
  'all': 'Compositions',
  'video': 'Compositions with Video',
  'audio': 'Compositions with Audio',
  'composer': 'Composers',
  'performer': 'Performers',
  'conductor': 'Conductors',
  'genre': 'Genres',
  'nationality': 'Nationalities',
  'spco-premiere': 'SPCO Premiere'
}

const mapStateToProps = (state, ownProps) => {
  const viewBy = ownProps.match.params.viewBy || 'all'
  return {
    title: compositionTitleMap[viewBy],
    fetchComplete: state.data.isCompleteCombined,
    viewBy: viewBy
  }
}


export default connect(mapStateToProps, null)(Compositions)