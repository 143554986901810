import React from 'react'
import PropTypes from 'prop-types'
import { formatInline } from '../../util'
import ImageSelector from '../ImageSelector'

let CuratedCollectionRow = ({collection }, context) => {
  let handleClick = (e) => {
    context.router.history.push(`/collections/${collection.identifier}`)
  }
  return (
    <li onClick={handleClick} key={collection.id}>
      <div className="image">
        <ImageSelector imageList={collection.image} size="wd_256x144" />
      </div>
      <div className="title">
        <h4><span dangerouslySetInnerHTML={{__html: formatInline(collection.title)}} /></h4>
        {collection.byline && <span className="composer">{collection.byline}</span>}
      </div>
    </li>
  )
}

CuratedCollectionRow.propTypes = {
  collection: PropTypes.object
}

CuratedCollectionRow.contextTypes = {
  router: PropTypes.object
}

export default CuratedCollectionRow