function getPlayerOpts(opts) {
  const {
    aspectRatio,
    width,
    height,
    file,
    generatePrerollUrl,
    image,
    isAutoPlay,
    isMuted,
    playlist,
    allowCast,
    displaytitle
  } = opts;

  const hasAdvertising = !!generatePrerollUrl;

  const playerOpts = {
    mute: !!isMuted,
    displaytitle: displaytitle
  };

  if (playlist) {
    playerOpts.playlist = playlist;
  } else if (file) {
    playerOpts.file = file;
  }

  if (aspectRatio && aspectRatio !== 'inherit') {
    playerOpts.aspectratio = aspectRatio;
  }

  if (hasAdvertising) {
    playerOpts.advertising = {
      client: 'googima',
      admessage: 'Ad — xxs left',
      autoplayadsmuted: true,
    };
  }

  if (typeof isAutoPlay !== 'undefined') {
    playerOpts.autostart = !!isAutoPlay;
  }

  if (image) {
    playerOpts.image = image;
  }

  if(allowCast) {
    playerOpts.cast = {}
  }

  if(width) {
    playerOpts.width = width
  }
  if(height) {
    playerOpts.height = height
  }

  // default 50000; 27,000,000 on fast connection
  // This prevents jwp from loading black video with audio
  playerOpts.defaultBandwidthEstimate = 850000;

  return playerOpts;
}

export default getPlayerOpts;
