import React from 'react'
import PropTypes from 'prop-types'
import CompositionList from './CompositionList'
import { formatMarkdown, setTitle, isHeadless } from '../../util'
import ReadMore from '../ReadMore'
import MetaTags from 'react-meta-tags'
import ImageSelector from '../ImageSelector'
import Meta from '../Meta'

let PersonCompositionList = ({person, compositions, viewAsTable, viewBy, flashMessage}, context) => {
  if(! person) {
    if(isHeadless()) {
      return <React.Fragment>
      <MetaTags>
               <meta name="prerender-status-code" content="404" />
             </MetaTags>
        <div className="row"><div className="columns text-center"><h1>Performer not found</h1></div></div>
      </React.Fragment>
    }
    else {
      flashMessage("Sorry, that performer was not found in the library.")
      context.router.history.push(`/compositions/by/performer`)
      return <div></div>
    }
  }

  setTitle(person.fullName())
  const subtitle = person.custom_title ? (
    <div className="subtitle"><em>{person.custom_title}</em></div>
  ) : ''

  const biography = person.biography ? (
    <div className="bio long_description">
      <ReadMore lines={5}>
        <div dangerouslySetInnerHTML={{__html: formatMarkdown(person.biography, {breaks: true})}} />
      </ReadMore>
    </div>
  ) : ''

  const image = <ImageSelector imageList={person.preferred_image} size="wd_512x288" />
  const imageCredit = person.preferred_image && person.preferred_image.credit ? <div className="image_credit">{person.preferred_image.credit}</div> : null

  return (
    <div>
      <Meta title={person.fullName()} image={person.preferred_image} description={person.biography}  />

      <header className="composition_subhead row person image_header">
        <div className="columns medium-4 small-12 show-for-medium">
          <div className="image">{image}</div>
          {imageCredit}
        </div>
        <div className="columns text medium-8 small-12">
          <h2>{person.fullName()}</h2>
          {subtitle}
          {biography}
        </div>
      </header>
      <CompositionList compositions={compositions} viewAsTable={viewAsTable} viewBy={viewBy} personVideoAttribute={true} />
    </div>
  )
}

PersonCompositionList.propTypes = {
  person: PropTypes.object,
  compositions: PropTypes.array,
  viewAsTable: PropTypes.bool,
  viewBy: PropTypes.string,
  flashMessage: PropTypes.func
}

PersonCompositionList.contextTypes = {
  router: PropTypes.object
}
export default PersonCompositionList