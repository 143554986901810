import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../containers/PageHeaderContainer'
import LoadingIndicator from '../LoadingIndicator'
import { Link } from 'react-router-dom'
import {formatInline, setTitle} from '../../util'

let LiveStreams = ({streams, fetchComplete }, context) => {
  setTitle('Live Streams')

  let streamList = null
  if(! fetchComplete || ! streams ) {
    streamList = <LoadingIndicator margin="70px" />
  }
  else {
    const streamItems = streams.toModelArray().map( (stream) => {
      const url = `/live-stream/${stream.identifier}`
      let handleClick = () => {
        context.router.history.push(url)
      }
      const startDate = stream.startMoment()
      const className = `status_${stream.status}`

      let ignoreClick = (e) => {
        e.stopPropagation()
      }

      return (
        <li key={stream.id} onClick={handleClick} className={className}>
          <div className="title">
            <h4><Link to={url} onClick={ignoreClick} dangerouslySetInnerHTML={{__html: formatInline(stream.title)}} /></h4>
            <span><strong>{startDate.format("dddd, MMMM D, [at] h:mm a")}</strong> {stream.addTimeZone()}</span>
            {stream.short_description && (
              <div className="short_description">{stream.short_description}</div>)
            }
          </div>
          <div>
          {stream.isLive() && <div className="live_flag">
                              <i className="play_circle"></i>Live&nbsp;now!</div>
          }
          </div>
        </li>
      )
    })
    streamList = (
      <ul className="live_stream_list image_list">{streamItems}</ul>
    )
  }


  return (
    <div>
      <PageHeader section="live_streams">
        <div className="row list_header programs">
          <div className="columns small-12">
            <header className="row list_header programs">
              <div className="columns medium-8">
                <h1>Live Streams</h1>
              </div>
            </header>
          </div>
         </div>
      </PageHeader>

      <main>
        <div className="row collapse">
          <div className="columns">
          {streamList}
          </div>
        </div>
      </main>
    </div>
  )
}

LiveStreams.propTypes = {
  streams: PropTypes.object,
  fetchComplete: PropTypes.bool
}

LiveStreams.contextTypes = {
  router: PropTypes.object
}

export default LiveStreams