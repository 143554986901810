import {Component } from 'react'

class MediaPlayer extends Component {
  constructor() {
    super()

    this.onReady = this.onReady.bind(this)
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this)
    this.onPlay = this.onPlay.bind(this)
    this.onEnterFullScreen = this.onEnterFullScreen.bind(this)
    this.onExitFullScreen = this.onExitFullScreen.bind(this)
    this.showPlayerByDefault = !! (process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID)

    this.state = {
      playerInitialized: false,
      playing: false,
      playerReady: false,
      playerLoaded: false
    }
  }

  onPlayerLoaded() {
    this.setState({
      playerLoaded: true
    })
  }
  // For some reason iOS app doesn't automatically play;
  // telling an already-playing player to play is a noop, so
  // not worrying about repeat calls
  onReady() {
    if(this.state.playing && this.state.playerLoaded) {
      window.jwplayer(this.playerId()).play()
    }
  }
  onPlay() {
    if (process.env.REACT_APP_ANDROID) {
      window.jwplayer(this.playerId()).setFullscreen(true);
    }
  }

  onEnterFullScreen() {
    if (process.env.REACT_APP_ANDROID) {
      androidNav.onFullscreen(true);
    }
  }

  onExitFullScreen() {
   if (process.env.REACT_APP_ANDROID) {
      androidNav.onFullscreen(false);
    }
  }
}

export default MediaPlayer