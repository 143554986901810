import { connect } from 'react-redux'
import CompositionVideo from '../components/composition/CompositionVideo'
import { pauseEvent } from '../actions/player'
import { fetchPerformanceVideoIfNeeded } from '../actions/fetch_composition'
import orm from '../models/orm'

const mapStateToProps = (state, ownProps) => {
  const { Composition, Performance } = orm.session(state.data.orm)
  const composition = Composition.withId(ownProps.composition_id)
  const performance = Performance.withId(ownProps.performance_id)
  const showPlayerByDefault = !! (process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID)
  const signedIn = !!state.user.currentUser

  let videoError
  if(! performance.fetched_video || performance.fetched_video === 'success') {
    videoError = ''
  }
  else if (performance.fetched_video === 'user' && signedIn) {
    videoError = ''
  }
  else {
    videoError = performance.fetched_video
  }
  return {
    performance: performance,
    composition: composition,
    videoLoaded: performance.fetched_video === 'success',
    videoError: videoError,
    signedIn: signedIn,
    showPlayerByDefault: showPlayerByDefault
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadPerformanceVideo: (performance) => {
      dispatch(fetchPerformanceVideoIfNeeded(performance.id))
    },
    pauseAudio: () => {
      dispatch(pauseEvent())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompositionVideo)