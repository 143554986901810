import { connect } from 'react-redux'
import FlashMessage from '../components/FlashMessage'
import { getLatestMessage, clearMessages } from 'redux-flash'

function mapStateToProps (state) {
  return {
    flash: getLatestMessage(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearFlash: () => {
      dispatch(clearMessages())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlashMessage)