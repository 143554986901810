import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class AccountBenefits extends Component {
  render() {
    return (
      <p className="extra_links text-center login_note">Don’t have a Concert Library account? <Link to="/sign-up">Get one now.</Link></p>
    )
  }
}

export default AccountBenefits
