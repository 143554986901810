import { SpcoModel } from './spco_model'
import {attr} from "redux-orm"

export class CuratedCollection extends SpcoModel {
  toString() {
    return this.title;
  }
}

CuratedCollection.modelName = 'CuratedCollection'
CuratedCollection.fields = {
  id: attr(),
  title: attr(),
  introduction_text: attr(),
  sequence: attr(),
  byline: attr(),
  image: attr(),
  identifier: attr(),
  duration_formatted: attr(),
  has_video: attr()
}