import { connect } from 'react-redux'
import { registerAction, facebookLogin, appleLogin } from '../actions/user'
import Registration from '../components/user/Registration'

const mapStateToProps = (state, ownProps) => ({
  signedIn: !! state.user.currentUser
  }
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (userInfo) => {
      dispatch(registerAction(userInfo))
    },
    facebookLogin: (userInfo) => {
      return dispatch(facebookLogin(userInfo))
    },
    appleLogin: (userInfo) => {
      return dispatch(appleLogin(userInfo))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration)
