import React from 'react'
import PropTypes from 'prop-types'
import ProgramNav from './ProgramNav'
import ProgramListContainer from '../../containers/ProgramListContainer'
import PageHeader from '../../containers/PageHeaderContainer'
import ViewByContainer from '../../containers/ViewByContainer'
import LoadingIndicator from '../LoadingIndicator'
import {setTitle} from '../../util'

let Programs = ({fetchComplete, viewBy, title }) => {
  setTitle(title)

  const mainListing = (() => {
    if(! fetchComplete) {
      return <LoadingIndicator margin="70px" />;
    }
    switch (viewBy) {
    case 'video':
    case 'audio':
    case 'all':
    default:
      return <ProgramListContainer viewBy={viewBy} />
    }
  })()

  return (
    <div>
      <PageHeader section="programs">
        <div className="row list_header programs">
          <div className="columns small-12">
            <header className="row list_header programs">
              <div className="columns medium-8">
                <h1>{title}</h1>
              </div>
              <div className="view_tools columns medium-4">
                <ViewByContainer />
              </div>
            </header>
            <ProgramNav viewBy={viewBy} />
          </div>
         </div>
      </PageHeader>
      <div className="row">
        <div className="columns small-12">
          <main>{mainListing}</main>
        </div>
      </div>
    </div>
  )
}

Programs.propTypes = {
  viewBy: PropTypes.string,
  fetchComplete: PropTypes.bool,
  title: PropTypes.string
}

export default Programs