import {Model, fk, attr} from "redux-orm"

export class HomeContent extends Model {
}

HomeContent.modelName = 'HomeContent'

HomeContent.fields = {
  content_type: attr(),
  identifier: attr(),
  sequence: attr(),
  performance: fk('Performance'),
  curated_collection: attr(),
  url: attr(),
  body_text: attr(),
  image: attr()
}