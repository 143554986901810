import React from 'react'

const OrLine = () => {
  return (
      <div className="or_line row collapse">
        <div className="columns"></div>
        <div className="columns shrink or">Or</div>
        <div className="columns"></div>
      </div>
  )
}
export default OrLine