/*eslint no-case-declarations: "off"*/

/*
Listing of Compositions for individual Composers, Artists, Conductors
*/
import { connect } from 'react-redux'
import PersonCompositionList from '../components/compositions/PersonCompositionList'
import orm from '../models/orm'
import { byUnformattedComposerAndTitle } from '../util'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import { flashMessage } from 'redux-flash'

const findPerson = (personIdentifier, orm_state) => {
  const { Person } = orm.session(orm_state)
  return Person.get({identifier: personIdentifier})
}

/* CompositionList expects to call toModelArray on an object */
const filterCompositionsForDisplay = (compositions) => {
  return uniqBy(filter(compositions, {unlisted: false, forDisplay: true}).sort(byUnformattedComposerAndTitle), 'id')
}

const findCompositions = (viewBy, person) => {
  if(! person) {
    return []
  }
  switch (viewBy) {
  case 'composer':
    return uniqBy(
          person.compositions
          .filter({unlisted: false, forDisplay: true})
          .orderBy('title')
          .toModelArray(), 'id')
  case 'performer':
    // Get performances by this performer, to mark composition as video or not
    // which will ignore video-performances by other artists
    // Then collect the performances to compositions
    const performer_compositions = person.featured_performances.all()
              .orderBy('title')
    .toModelArray().map((performance) => {
      const composition = performance.composition
      composition.videoForPerson = performance.has_video
      composition.premiereForPerson = performance.premiere
      return composition
      }
    )
    return filterCompositionsForDisplay(performer_compositions)
  case 'conductor':
    const conductor_compositions = person.conducted_performances.all()
          .orderBy('title')
        .toModelArray().map((performance) => {
          const composition = performance.composition
          composition.videoForPerson = performance.has_video
          return composition
          }
        )
    return filterCompositionsForDisplay(conductor_compositions)
  }
}

const mapStateToProps = (state, ownProps) => {
  var person = findPerson(ownProps.personIdentifier, state.data.orm)
  return {
    person: person,
    compositions: findCompositions(ownProps.viewBy, person),
    viewAsTable: state.compositions.viewAsTable,
    viewBy: ownProps.viewBy
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PersonCompositionList)