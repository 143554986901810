import React from 'react'
import PropTypes from 'prop-types'
import { getImageSizeInstance } from '../../util'

let QueueList = ({fullQueue, currentItem, setQueueItem, removeQueueItem, onOuterAction}) => {

  const queueItems = fullQueue.map( (item, index) => {

    const imageInstance = item.composition.preferred_image ? getImageSizeInstance(item.composition.preferred_image, 'wd_thumbnail' ) : null
    const currentImage = imageInstance ? <div className="image"><img src={imageInstance.url} alt="" /></div> : <div className="image"></div>
    let changeCurrent = (e) => {
      onOuterAction()
      setQueueItem(index)
    }

    let removeItem = (e) => {
      e.stopPropagation()
      if(queueItems.length === 1) {
        onOuterAction()
      }
      removeQueueItem(index)
    }

    return (
      <li key={`${item.performance.id}-${index}`} onClick={changeCurrent} className={index === currentItem ? 'current' : null}>
        {currentImage}
        <div className="title">
          <strong>{item.composition.alternative_formatted_title}</strong>
          {item.composition.composer.full_name}
        </div>
        <div onClick={removeItem} className="delete">—</div>
      </li>
    )
  })

  return (
    <div className="queue_popup">
      <h5>Music Queue</h5>
      <ul>
        {queueItems}
      </ul>
    </div>
  )
}

QueueList.propTypes = {
  fullQueue: PropTypes.array,
  currentItem: PropTypes.number,
  setQueueItem: PropTypes.func,
  removeQueueItem: PropTypes.func,
  onOuterAction: PropTypes.func
}

export default QueueList