import React from 'react'
import PropTypes from 'prop-types'
import CompositionList from './CompositionList'
import {setTitle} from '../../util'

let CategoryCompositionList = ({category, compositions, viewAsTable, viewBy}) => {
  setTitle(category)
  return (
    <div>
      <header className="composition_subhead category">
        <div className="text">
          <h2>{category}</h2>
        </div>
      </header>
      <CompositionList compositions={compositions} viewAsTable={viewAsTable} viewBy={viewBy} />
    </div>
  )
}

CategoryCompositionList.propTypes = {
  category: PropTypes.string,
  compositions: PropTypes.array,
  viewAsTable: PropTypes.bool,
  viewBy: PropTypes.string
}

export default CategoryCompositionList