import { setStorage, getStorage } from '../util'
import { loadQueueFromStorage } from './player'
import { handleErrors } from './fetch_handlers'

export const REQUEST_COMBINED = 'REQUEST_COMBINED'
function requestCombined() {
  return {
    type: REQUEST_COMBINED
  }
}

export const RECEIVE_COMBINED = 'RECEIVE_COMBINED'
function receiveCombined(json) {
  return {
    type: RECEIVE_COMBINED,
    data: json,
    receivedAt: Date.now()
  }
}


/* Combined fetch, local & remote
==================  */
const STORAGE_KEY_DATA = 'combined-data'
const STORAGE_KEY_TIMESTAMP = 'combined-timestamp'
const STORAGE_KEY_API_VERSION = 'combined-version'
const CURRENT_API_VERSION = '1'

function fetchCombined() {
  const storedDate = getStorage(STORAGE_KEY_TIMESTAMP)
  const storedVersion = getStorage(STORAGE_KEY_API_VERSION)
  const storedData = getStorage(STORAGE_KEY_DATA)
  if(storedDate && storedData && storedDate > Date.now() - 1000 * 60 * 60 && storedVersion === CURRENT_API_VERSION) {
    return fetchLocalCombined(storedData)
  }
  else {
    return fetchRemoteCombined()
  }
}

function fetchRemoteCombined() {
  return dispatch => {
    dispatch(requestCombined())
    return fetch(`${process.env.REACT_APP_API_BASE}/combined/?format=json`)
      .then(handleErrors)
      .then(response => response.json())
      .then((json) => {
        if(json.compositions) { storeFetchedData(json) }// sanity check
        return json
      })
      .then((json) =>  {
        dispatch(receiveCombined(json))
      })
      .catch( (error) => {
        if(process.env.NODE_ENV === 'production') {
          window.Rollbar.error("Problem fetching combined data", error)
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        } else {
          throw error
        }
      })
  }
}

function fetchLocalCombined(json) {
  return dispatch => {
    return new Promise( (resolve, reject) => {
      dispatch(receiveCombined(json))
      return resolve()
    })
  }
}

function storeFetchedData(json) {
  setStorage(STORAGE_KEY_DATA, json)
  setStorage(STORAGE_KEY_TIMESTAMP, Date.now())
  setStorage(STORAGE_KEY_API_VERSION, CURRENT_API_VERSION)
}


function shouldFetchCombined(state) {
  const data = state.data
  if (! data.isCompleteCombined) {
    return true
  } else if (data.isFetchingCombined) {
    return false
  } else {
    return data.didInvalidateCombined
  }
}

export function fetchCombinedIfNeeded() {
  return (dispatch, getState) => {
    if(shouldFetchCombined(getState())) {
      return dispatch(fetchCombined())
      .then( () => {
        dispatch(loadQueueFromStorage())
      })
    } else {
      return Promise.resolve()
    }
  }
}

