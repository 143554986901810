import {fk, attr} from "redux-orm"
import { SpcoModel } from './spco_model'

import moment from 'moment'
import {formatDuration} from '../util'
export class Performance extends SpcoModel {

  audioRecording() {
    return this.recordings ? this.recordings.find( recording => recording.type === 'A' ) : null
  }

  hasVideo() {
    return this.recordings ? this.recordings.some( recording => recording.type === 'V' ) : null
  }

  hasAudio() {
    return this.recordings ? this.recordings.some( recording => recording.type === 'A' ) : null
  }

  videoRecording() {
    return this.recordings ? this.recordings.find( recording => recording.type === 'V' ) : null
  }

  programNote() {
    return this.program_note ? this.program_note.text : ''
  }
  programNoteByline() {
    return this.program_note ? this.program_note.formatted_credit_html : ''
  }
  queuepointVtt(recording) {
    return recording && recording.cuepoint_webvtt ? `${process.env.REACT_APP_AUTH_HOST}${recording.cuepoint_webvtt}`  : null
  }

  captionsUrl(recording) {
    return recording && recording.captions_url ? `${process.env.REACT_APP_AUTH_HOST}${recording.captions_url}` : null
  }

  getCuepoints() {
    const recording = this.audioRecording()
    const cuepoints = recording.movement_cuepoints
    cuepoints.forEach((cuepoint, index) => {
      let duration
      if(index === cuepoints.length - 1) {
        duration = moment.duration( recording.duration_ms - moment.duration(cuepoint.start), 'milliseconds')
      }
      else {
        duration = moment.duration(moment.duration(cuepoints[index + 1].start) - moment.duration(cuepoint.start), 'milliseconds')
      }
      cuepoint.duration_formatted = formatDuration(duration)
    })
    return cuepoints
  }

  dateDisplay() {
    let startDate = moment(this.start_date_time)
    let endDate = moment(this.end_date_time)
    if(startDate.dayOfYear() === endDate.dayOfYear()) {
      return startDate.format('MMMM D, YYYY')
    }
    else if(startDate.month() === endDate.month()) {
      return `${startDate.format('MMMM D')}–${endDate.format('D, YYYY')}`
    }
    else {
      return `${startDate.format('MMMM D')}–${endDate.format('MMMM D, YYYY')}`
    }
  }
}

Performance.modelName = 'Performance'

Performance.fields = {
  composition: fk('Composition', 'performances'),
  conductor: fk('Person', 'conducted_performances'),
  position: attr({ getDefault: () => 0 }),
  program: fk('Program', 'performances'),
  unlisted: attr({ getDefault: () => false })
}