import { SubmissionError, reset } from 'redux-form';  // ES6
import { flashMessage } from 'redux-flash'
import { getStorage, setStorage, getUser, setUser, HandledError, logAnalyticsSignup, logAnalyticsSignin,fetchJwtOptions } from '../util'
import { handleErrors, NOT_FOUND_MESSAGE } from './fetch_handlers'
import forOwn from 'lodash/forOwn'
import history from '../history'
import jwt_decode from 'jwt-decode'
const auth_base_url = `${process.env.REACT_APP_AUTH_HOST}/rest-auth`
const token_url = `${process.env.REACT_APP_AUTH_HOST}/api/token/`

function handleSignInErrors(response) {
  if (response.status === 400) {
    return response.json().then( (json) => {
      if(json.non_field_errors && json.non_field_errors[0].indexOf('not verified') !== -1) {
        throw new SubmissionError("Your email is not verified! Check your inbox for an email from us.")
      }
      else {
        throw new SubmissionError("Sorry, we couldn't sign you in with that email and password.")
      }
    })
  }
  else if(!response.ok) {
    throw new SubmissionError("Sorry, we're having problems connecting.");
  }
  return response;
}

export const USER_SIGN_IN = 'USER_SIGN_IN'
export function signInAction(userData) {
  return (dispatch, getState) => {
    var formData = buildFormData({
      'username': userData.email,
      'password': userData.password
    })

    return fetch(token_url, {
        method: 'POST',
        body: formData
      })
      .then(handleSignInErrors)
      .then(response => response.json())
      .then(json => {
        dispatch(reset('SignIn'))
        dispatch(flashMessage("You're signed in.", {push: true, timeout: 6000}))
        setUser(json)
        dispatch(setCurrentUser(json))
        notifyNativeAppSignin()
        logAnalyticsSignin()
        handleSigninRedirect(getState)
      })
      .catch(error => {
        if(error.message === 'Submit Validation Failed') {
          dispatch(flashMessage(error.errors, {push: true, timeout: 8000}))
        }
        else {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
      })
  }
}

function handleSigninRedirect(getState) {
  const locationHistory = getState().user.locationHistory
  if(getCurrentPageRedirect()) {
    history.push(getCurrentPageRedirect())
    clearPageRedirect()
  }
  else if(locationHistory.length > 1) {
    history.goBack()
  }
  else {
    history.push('/compositions')
  }
}


function notifyNativeAppSignin() {
  if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
    // HAX: tell native app we're signed in so it can remove
    // sign-in from nav stack
    window.webkit.messageHandlers.pageTitle.postMessage({
      url: 'signedIn'
    });
  }
}

export const CHECK_REFRESH_EXPIRY = 'CHECK_REFRESH_EXPIRY'
export const LOAD_USER = 'LOAD_USER'
function setCurrentUser(json) {
  return dispatch => {
    if(!json) {
      dispatch({
        type: LOAD_USER,
        userInfo: null
      })
    }
    else {
      if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
        // iOS app uses this to register for push notifications.
        window.webkit.messageHandlers.userLogin.postMessage({
          email: json.user.email,
          id: json.user.pk,
          first_name: json.user.first_name,
          last_name: json.user.last_name
        });
      }
      if(process.env.REACT_APP_ANDROID) {
        // Android app uses this to register for push notifications.
        androidPush.registerUser(JSON.stringify({
          email: json.user.email,
          id: json.user.pk,
          first_name: json.user.first_name,
          last_name: json.user.last_name
        }));
      }
      json.expires = jwt_decode(json.token).exp
      json.refreshExpires = jwt_decode(json.refresh).exp
      const refreshEpiresInMS = parseInt(json.refreshExpires * 1000 - Date.now())

      if(refreshEpiresInMS > 0) {
        dispatch({
          type: LOAD_USER,
          userInfo: json
        })
        dispatch(expiryTimerAction(CHECK_REFRESH_EXPIRY, refreshEpiresInMS))
      }
      else {
        // Clear storage user if current user is expired
        // Prevents flash of user name
        setUser(null)
      }
    }
  }
}
function expiryTimerAction(action, expiresMS) {
  return {
    type: 'START_TIMER',
    payload: {
      timerAction: action,
      timerName: action,
      timerOnce: expiresMS - 1000
    }
  }
}

export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN'
export function getAccessToken() {
  return (dispatch, getState) => {
    const user = getState().user.currentUser
    if(user) {
      const expiresIn = parseInt(user.expires - Date.now() / 1000)
      if(expiresIn >= 10) {
        return Promise.resolve(user.token)
      }
      else {
        var formData = buildFormData({
          'refresh': user.refresh
        })
        return fetch(`${token_url}refresh/`,{
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(json => {
          // Note that the new token comes in as json.access,
          // while the current token is saved as user.token
          const expires = jwt_decode(json.access).exp
          // Set up user for saving to storage
          const newUser = Object.assign({}, user, {
            token: json.access,
            expires: expires
          })
          setUser(newUser)

          dispatch({
            type: UPDATE_ACCESS_TOKEN,
            accessToken: json.access,
            expires: expires
          })
          return Promise.resolve(json.access)
        })
      }
    }
    else {
      // No token!
      return Promise.resolve(null)
    }
  }
}

export function signOutAction() {
  return dispatch => {
    setUser(null)
    dispatch(flashMessage("You're signed out.", {push: true, timeout: 4000}))
    return dispatch(setCurrentUser(null))
  }
}

export const USER_REGISTER = 'USER_REGISTER'
export function registerAction(userData) {
  return dispatch => {
    // TODO: Dispatch event to trigger waiting
    let formData = buildFormData({
      'email': userData.email,
      'password1': userData.password,
      'password2': userData.password,
      'first_name': userData.first_name,
      'last_name': userData.last_name
    })

    return fetch(`${auth_base_url}/registration/?format=json`, {
      method: 'POST',
      body: formData
    })
    .then(response => {
        response.json().then(json => {
          if(response.ok) {
            dispatch(reset('Register'))
            dispatch(flashMessage("You're signed up! Check your email for a verification link.", {push: true, timeout: 8000}))
            dispatch(userSignUpSuccess(json))
            history.push('/compositions')
          }
          else {
            let errorMessage
            if(json.password1) {
              errorMessage = `We couldn't create your account; ${json.password1[0]}`
            }
            else if(json.email) {
              errorMessage = `We couldn't create your account; ${json.email[0]}`
            }
            else {
              errorMessage = "Sorry, we couldn't create your account!"
            }
            dispatch(flashMessage(errorMessage, {push: true, timeout: 8000}))
          }
        })
    })
    .catch(error => {
      if(error.name === 'SubmissionError') {
        dispatch(flashMessage(error.errors, {push: true, timeout: 8000}))
      }
      else {
        alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
      }
    })
  }
}

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
function userSignUpSuccess(json) {
  logAnalyticsSignup()
  return {
    type: USER_REGISTER_SUCCESS,
    userInfo: json
  }
}

export const LOCATION_HISTORY = 'LOCATION_HISTORY'
export function setLocationHistory(url) {
  return {
    type: LOCATION_HISTORY,
    url: url
  }
}

export function loadCurrentUser() {
  return dispatch => {
    const savedUser = getUser() || null
    if(savedUser) {
      dispatch(setCurrentUser(savedUser))
    }
  }
}


export function appleLogin(userData) {
  return (dispatch, getState) => {
    if(!userData.authorization.id_token) {
      return false
    }
    let form_data = {
      id_token: userData.authorization.id_token,
      code: userData.authorization.code,
      user: userData.user
    }
    if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
      form_data.client_id = 'org.thespco.mobile.iphone.SPCO'
    }
    return fetch(`${auth_base_url}/apple-jwt/?format=json`, {
      method: 'POST',
      body: buildFormData(form_data)
    })
    .then(response => {
      response.json().then(json => {
        if(response.ok) {
          const flash = json.new_user ? "You're now signed up to login to the Listening Library with Apple." : "You're signed in with Apple."
          dispatch(flashMessage(flash, {push: true, timeout: 8000}))
          setUser(json)
          dispatch(setCurrentUser(json))
          notifyNativeAppSignin()
          if(json.new_user) {
            logAnalyticsSignup()
          }
         handleSigninRedirect(getState)
        }
        else {
          if(json.non_field_errors && /already registered/.test(json.non_field_errors[0])) {
            let email = userData.email || `that email`
            let errorMessage = `Looks like you're already signed up for an account at ${email}. Try logging in with the email and the password you selected.`
            dispatch(flashMessage(errorMessage, {push: true, timeout: 8000}))
          }
          else {
            alert("Sorry, there was a problem signing you in! Try again in a few minutes.")
          }
        }
      })
    })
    // .catch(error => alert("Sorry, there was a problem signing you in! Try again in a few minutes.") )
  }
}

export function facebookLogin(userData) {
  return (dispatch, getState) => {
    if(!userData.accessToken) {
      return false
    }
    return fetch(`${auth_base_url}/facebook-jwt/?format=json`, {
      method: 'POST',
      body: buildFormData({access_token: userData.accessToken})
    })
    .then(response => {
      response.json().then(json => {
        if(response.ok) {
          const flash = json.new_user ? "You're now signed up to login to the Listening Library with Facebook." : "You're signed in with Facebook."
          dispatch(flashMessage(flash, {push: true, timeout: 8000}))
          setUser(json)
          dispatch(setCurrentUser(json))
          notifyNativeAppSignin()
          if(json.new_user) {
            logAnalyticsSignup()
          }
         handleSigninRedirect(getState)
        }
        else {
          if(json.non_field_errors && /already registered/.test(json.non_field_errors[0])) {
            let email = userData.email || `that email`
            let errorMessage = `Looks like you're already signed up for an account at ${email}. Try logging in with the email and the password you selected.`
            dispatch(flashMessage(errorMessage, {push: true, timeout: 8000}))
          }
          else {
            alert("Sorry, there was a problem signing you in! Try again in a few minutes.")
          }
        }
      })
    })
    .catch(error => alert("Sorry, there was a problem signing you in! Try again in a few minutes.") )
  }
}

export function confirmEmailAction(key, signedIn) {
  return dispatch => {
    return fetch(`${auth_base_url}/registration/verify-email/`, {
      method: 'POST',
      body: buildFormData({key: key})
    })
    .then(handleErrors)
    .then(response => response.json())
    .then(json => {
      if(signedIn) {
        dispatch(flashMessage("Thanks for verifying your email; you now have access to the entire concert library.", {push: true, timeout: 8000}))
        history.push('/compositions')
      }
      else {
        dispatch(flashMessage("Thanks for verifying your email; you can now sign in.", {push: true, timeout: 8000}))
        history.push('/sign-in')
      }
    })
    .catch(error => alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.") )
  }
}

export function sendForgotPassword(userData) {
  return dispatch => {
    return fetch(`${auth_base_url}/password/reset/`, {
      method: 'POST',
      body: buildFormData({email: userData.email})
    })
    .then(response => {
      if(response.status === 404) {
        throw Error(NOT_FOUND_MESSAGE);
      }
      else if(response.status === 412) {
        dispatch(flashMessage(`It looks like the email ${userData.email} signed up through Facebook. Try using the Facebook button!`, {push: true, timeout: 8000}))
        history.push('/sign-in')
        return Promise.reject(new HandledError(''))
      }
      return response
    })
    .then(response => response.json())
    .then(json => {
      dispatch(flashMessage("If you have previously set a password, you will shortly receive an email with instructions to change it. If you do not receive an email or need assistance, please contact us. We're happy to help!", {push: true, timeout: 14000}))
      history.push('/sign-in')
    })
    .catch(error => {
      if(error.name !== 'HandledError') {
        alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
      }
    })
  }
}

export function resetPassword(userData, uid, token) {
  return dispatch => {
    var formData = buildFormData({
      'uid': uid,
      'token': token,
      'new_password1': userData.password,
      'new_password2': userData.password_confirmation
    })

    return fetch(`${auth_base_url}/password/reset/confirm/`, {
      method: 'POST',
      body: formData
    })
    // .then(handleErrors)
    .then(response => response.json())
    .then(json => {
      dispatch(flashMessage("Great, we reset your password. Sign in with your new password to continue.", {push: true, timeout: 8000}))
      history.push('/sign-in')
    })
    // .catch(error => alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.") )
  }
}

export function deleteUser() {
  return dispatch => {

    return dispatch(getAccessToken())
    .then( (user_token) => {
      const option = Object.assign({method: 'DELETE'}, fetchJwtOptions(user_token))
      return fetch(`${process.env.REACT_APP_API_BASE}/user_delete/`, option)
        // .then(handleErrors)
        .then(response => response.json())
        .then(json => {
          setUser(null)
          dispatch(flashMessage("Ok, we deleted your account.", {push: true, timeout: 8000}))
          dispatch(setCurrentUser(null))
        })
      })
    // .catch(error => alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.") )
  }
}

function buildFormData(data) {
  var formData = new FormData()
  forOwn(data, (value, key) => {
    formData.append(key, value)
  })
  return formData
}


export const STORAGE_KEY_REDIRECT_TO = 'redirectTo'
// Leaving this as an action since it's used in other controllers
export function setCurrentPageRedirect() {
  return dispatch => setStorage(STORAGE_KEY_REDIRECT_TO, history.location.pathname)
}

export function getCurrentPageRedirect() {
  return getStorage(STORAGE_KEY_REDIRECT_TO)
}

export function clearPageRedirect() {
  setStorage(STORAGE_KEY_REDIRECT_TO, null)
}
