import React from 'react'
import PropTypes from 'prop-types'
import ViewByContainer from '../../containers/ViewByContainer'

let CompositionsHeader = ({title}) => {

  return (
      <header className="row list_header">
      <div className="columns medium-8">
        <h1>{title}</h1>
      </div>
      <div className="view_tools columns medium-4">
      <ViewByContainer />

      </div>
      </header>
  )
}

CompositionsHeader.propTypes = {
  fetchComplete: PropTypes.bool,
  title: PropTypes.string
}

export default CompositionsHeader