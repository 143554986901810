import React from 'react'
import PropTypes from 'prop-types'
import { formatInline } from '../../util'
import ImageSelector from '../ImageSelector'

let ProgramRow = ({program, viewAsTable}, context) => {
  let handleClick = (e) => {
    context.router.history.push(`/concert/${program.identifier}`)
  }
  return (
    <li onClick={handleClick} key={program.id}>
      <div className="image">
        <ImageSelector imageList={program.preferred_image} size="wd_256x144" />
      </div>
      <div className="title">
        <h4 className="date">{program.formatted_date_range}</h4>
        <span className="program_title" dangerouslySetInnerHTML={{__html: formatInline(program.title)}} />
      </div>
    </li>
  )
}

ProgramRow.propTypes = {
  program: PropTypes.object,
  viewAsTable: PropTypes.bool
}

ProgramRow.contextTypes = {
  router: PropTypes.object
}

export default ProgramRow