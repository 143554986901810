
export const NOT_FOUND_MESSAGE = 'Not found'
export function handleErrors(response) {
  if(response.status === 404) {
    throw Error(NOT_FOUND_MESSAGE);
  }
  if (!response.ok) {
    window.Rollbar.error("Fetch error", {}, response)
    throw Error(response.statusText);
  }
  return response;
}