import orm from '../models/orm'
import { handleErrors, NOT_FOUND_MESSAGE } from './fetch_handlers'
import uniq from 'lodash/uniq'
import { HandledError, fetchJwtOptions } from '../util'
import { handleStreamLinksFailure } from './player'
import { getAccessToken } from './user'

/* Composition detail */

export const RECEIVE_COMPOSITION_DETAIL = 'RECEIVE_COMPOSITION_DETAIL'
function receiveCompositionDetail(json) {
  return {
    type: RECEIVE_COMPOSITION_DETAIL,
    composition: json,
    receivedAt: Date.now()
  }
}

export const REQUEST_COMPOSITION_DETAIL = 'REQUEST_COMPOSITION_DETAIL'
function requestCompositionDetail(identifier) {
  return {
    type: REQUEST_COMPOSITION_DETAIL,
    identifier: identifier
  }
}

function fetchCompositionDetail(identifier) {
  return dispatch => {
    dispatch(requestCompositionDetail(identifier))
    return fetch(`${process.env.REACT_APP_API_BASE}/compositions/${identifier}/?format=json`)
      .then(response => {
        if(response.status === 404) {
          return Promise.reject(new HandledError(NOT_FOUND_MESSAGE))
        }
        return response
      })
      .then(response => response.json())
      .then(json => dispatch(receiveCompositionDetail(json)))
      .catch(error => {
        if(error.name !== 'HandledError') {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
      })
  }
}

export function fetchAllCompositionDetails(identifiers, dispatch) {
  return Promise.all(
    uniq(identifiers).map( (identifier) => {
      return fetch(`${process.env.REACT_APP_API_BASE}/compositions/${identifier}/?format=json`)
        .then(handleErrors)
        .then(response => response.json())
        .then(json => dispatch(receiveCompositionDetail(json)))
        .catch(error => {
          if(error.message === NOT_FOUND_MESSAGE) {
            return identifier;
          }
          else {
            alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.");
          }
        })
    })
  )
}

function shouldFetchCompositionDetail(state, identifier) {
  const session = orm.session(state.data.orm)
  const target = session.Composition.findByIdentifier(identifier)
  return ! target || ! target.hasDetail
}

export function fetchCompositionDetailIfNeeded(identifier) {
  return (dispatch, getState) => {
    if (shouldFetchCompositionDetail(getState(), identifier)) {
      return dispatch(fetchCompositionDetail(identifier))
    } else {
      return Promise.resolve()
    }
  }
}

export const RECEIVE_ALL_PROGRAM_VIDEO = 'RECEIVE_ALL_PROGRAM_VIDEO'
export function receiveAllProgramVideo(program_id) {
  return {
    type: RECEIVE_ALL_PROGRAM_VIDEO,
    program_id: program_id
  }
}

export function fetchAllProgramVideoIfNeeded(program) {
  return (dispatch, getState) => {
    const performances = program.performances.toModelArray()

    return Promise.all(
      performances.map( (perf) => {
        const session = orm.session(getState().data.orm)
        const performance = session.Performance.findById(perf.id)
        if(!performance.hasVideo()) {
          return Promise.resolve()
        }
        else {
          return dispatch(fetchPerformanceVideoIfNeeded(performance.id))
        }
      })
    ).then(data => {
      dispatch(receiveAllProgramVideo(program.id))
    })
  }
}

export function fetchPerformanceVideoIfNeeded(performance_id) {
  return (dispatch, getState) => {
    const session = orm.session(getState().data.orm)
    const performance = session.Performance.findById(performance_id)
    if(performance.fetched_video === 'in_progress' || performance.fetched_video === 'success') {
      return Promise.resolve()
    }
    else if (!performance.fetched_video || (performance.fetched_video === 'user' && !!getState().user.currentUser)) {
      dispatch(requestPerformanceVideo(performance.id))
      return dispatch(fetchPerformanceVideo(performance))
    } else {
      return Promise.resolve()
    }
  }
}

export const REQUEST_PERFORMANCE_VIDEO = 'REQUEST_PERFORMANCE_VIDEO'
function requestPerformanceVideo(performance_id) {
  return {
    type: REQUEST_PERFORMANCE_VIDEO,
    performance_id: performance_id
  }
}

function fetchPerformanceVideo(performance) {
  return (dispatch, getState) => {
    const uuid = performance.videoRecording().uuid
    return dispatch(getAccessToken())
    .then( (user_token) => {
       return fetch(`${process.env.REACT_APP_API_BASE}/stream/recording/${uuid}/`, fetchJwtOptions(user_token))
      .then(response => {
        return response.json().then(json => {
          if(response.ok) {
            return dispatch(receivePerformanceVideoInfo(json, performance.id, performance))
          }
          else {
            return handleStreamLinksFailure(response, json, dispatch, performance, 'video')
          }
        })
      })
      .catch(error => {
        if(error.name !== 'HandledError') {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
      })
      }
    )
  }
}

export const RECEIVE_PERFORMANCE_VIDEO_INFO = 'RECEIVE_PERFORMANCE_VIDEO_INFO'
function receivePerformanceVideoInfo(json, performance_id, performance = null) {
  return {
    type: RECEIVE_PERFORMANCE_VIDEO_INFO,
    json: json,
    performance_id: performance_id,
    performance: performance
  }
}
