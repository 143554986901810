/*
Program listing
*/

import { connect } from 'react-redux'
import Programs from '../components/programs/Programs'

export const programTitleMap = {
  'all': 'Concerts',
  'video': 'Video Concerts',
  'audio': 'Audio Concerts'
}


const mapStateToProps = (state, ownProps) => {
  const viewBy = ownProps.match.params.viewBy || 'all'
  return {
    title: programTitleMap[viewBy],
    viewBy: viewBy,
    fetchComplete: state.data.isCompleteCombined
  }
}

const ProgramsContainer = connect(
  mapStateToProps,
  null
)(Programs)

export default ProgramsContainer