import React from 'react'
import PropTypes from 'prop-types'
import list from '../../images/icons/24/list.svg'
import grid from '../../images/icons/24/grid.svg'
import SvgIcon from './SvgIcon'

let ViewBy = ({viewAsTable, setViewAsTable, setViewAsGrid}) => {

  return (
    <ul className="view_by">
      <li onClick={setViewAsTable} className={viewAsTable ? 'active': ''}>
        <SvgIcon icon={list} fill={ ! viewAsTable ? '#ffffff' : ''}/>
      </li>
      <li onClick={setViewAsGrid} className={! viewAsTable ? 'active': ''}>
        <SvgIcon icon={grid} fill={ viewAsTable ? '#ffffff' : ''} />
      </li>
    </ul>
  )
}

ViewBy.propTypes = {
  viewAsTable: PropTypes.bool,
  setViewAsTable: PropTypes.func,
  setViewAsGrid: PropTypes.func
}

export default ViewBy