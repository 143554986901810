import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'

import marked from 'marked'
import moment from 'moment'

// require('moment-duration-format')
// ?
import 'moment-duration-format'


export function byUnformattedComposerAndTitle(a,b) {
  if(a.composer.id === b.composer.id) {
    return a.unformatted_title.localeCompare(b.unformatted_title);
  }
  else {
    return a.composer.sort_name.localeCompare(b.composer.sort_name)
  }
}

export function sortByLocale(arr) {
  return arr.sort( (a,b) => {
    return a.localeCompare(b)
  })
}

export function sortByTitle(arr) {
  return arr.sort( (a,b) => {
    // hax
    return a.title.replace(/\*/g, '').localeCompare(b.title.replace(/\*/g, ''))
  })
}

export function formatMarkdown(str, opts = {}) {
  if(opts.breaks) {
    opts.gfm = true
  }
  return marked(str, opts)
}

export function formatInline(str) {
  return str.replace(/\*(.+?)\*/g,'<em>$1</em>')
}

export function stripFormatting(str) {
  return str.replace(/\*/g, '')
}

export function byId(object_array) {
  return object_array.reduce((obj, item) => {
            obj[item.id] = item
            return obj
          }, {})
}

export function humanize(identifier) {
    return startCase(identifier.replace(/-/g, ' '))
}

export function titleize(identifier) {
    return capitalize(identifier.replace(/-/g, ' '))
}

export function parameterize(title) {
  return lowerCase(title).replace(/\s/g, '-')
}

export function formatDuration(moment_duration) {
  const duration = moment_duration.format("hh:mm:ss")
  return duration.replace(/^0/, '')
}

export function secondsToMinutes(seconds) {
  const duration = moment.duration(seconds, 'seconds')
  return formatDuration(duration)
}

export function setStorage(key, value) {
  try {
    if(value === null) {
      localStorage.removeItem(key)
    }
    else {
      localStorage.setItem(key, JSON.stringify(value))
    }
  } catch (err) {
    // ignore if localstorage is disabled
  }
}

export function getStorage(key) {
  try {
    let value = localStorage.getItem(key)
    return value && JSON.parse(value)
  } catch (err) {
    return null
  }
}

const STORAGE_KEY_USER = 'currentuser'
export function setUser(json) {
  if(process.env.REACT_APP_ANDROID) {
    window.androidStorage.setUser(JSON.stringify(json))
  }
  else {
    setStorage(STORAGE_KEY_USER, json)
  }
}

export function getUser() {
  if(process.env.REACT_APP_ANDROID) {
    try {
      let str = window.androidStorage.getUser()
      return str && JSON.parse(str)
    } catch(err) {
      return null
    }
  }
  else {
    return getStorage(STORAGE_KEY_USER)
  }
}


export function compositionHasPremiere(composition) {
  if(!composition.performances_with_recordings) {
    return false
  }
  return composition.performances_with_recordings.some( (performance) => {
    return performance.premiere !== ''
  })
}

export function getPlaylistItem(queueItem)  {
  const tracks = queueItem.vtt ? [{
        file: queueItem.vtt,
        kind: 'chapters'
  }] : []

  return [{
    file: queueItem.url,
    title: queueItem.composition.title,
    tracks: tracks
  }]
}

export function logAnalyticsSignup() {
  // Logging a mock pageview because of UA limitations; this could be discontinued
  // if it's hot helpful to have continuity
  const url = process.env.REACT_APP_BASEPATH + '/signup-success'
  window.gtag('event', 'page_view', {page_title: 'Signup Success', page_location: url})
  window.gtag('event', 'sign_up', {page_location: process.env.REACT_APP_BASEPATH + '/sign-up'})
}

export function logAnalyticsSignin() {
  window.gtag('event', 'login', {page_location: process.env.REACT_APP_BASEPATH + '/sign-in'})
}


export function logAnalyticEvent(eventName, opts = {}) {
  window.gtag('event', eventName, opts)
}


export function setTitle(title = "") {
  let newTitle = 'Concert Library — The Saint Paul Chamber Orchestra'
  if(title) {
    newTitle = `${stripFormatting(title)} | ${newTitle}`
  }
  const oldTitle = document.title
  document.title = newTitle
  if(oldTitle !== newTitle) {
    if(process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID) {
      window.gtag('pageview', 'pageview', {page_title: newTitle, page_location: process.env.REACT_APP_BASEPATH + location.pathname})
    }

    if(process.env.REACT_APP_QUANTCAST_ID) {
      // This prevents the initial quantcast page request, which pushes the wrong title
      setupQuantcast()
    }

    if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
      // Native app uses this to handle navigation
      window.webkit.messageHandlers.pageTitle.postMessage({
        url: window.location.toString(),
        title: title
      });
    }
    if(title && process.env.REACT_APP_ANDROID) {
      // Android app uses this to handle navigation
      window.androidNav.pageTitle(JSON.stringify({title: stripFormatting(title)}));
    }
  }
}

export function setOgUrl() {
  const url = 'https://content.thespco.org' + window.location.pathname
  document.querySelector('meta[property="og:url"]').setAttribute("content", url);
}

function setupQuantcast() {
  if(! window.spcoQuantcastSetup) {
    var _qevents = window._qevents || [];
    _qevents.push({
      qacct: process.env.REACT_APP_QUANTCAST_ID,
      labels: 'Concert Library'
    });

    var elem = document.createElement('script')
    elem.src = "https://secure.quantserve.com/quant.js"
    elem.async = true
    elem.type = "text/javascript"
    var scpt = document.getElementsByTagName('script')[0]
    scpt.parentNode.insertBefore(elem, scpt)
    window.spcoQuantcastSetup = true
  }
}

export function addTimeZoneIfNecessary(time) {
  const isCentralTime = moment.tz.guess() === 'America/Chicago'
  let startInCentral = time.tz('America/Chicago')
  return isCentralTime ? '' : ` (${startInCentral.format('h:mm a')} Central)`
}
export function safariOrIos() {
  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  return isSafari || iOS
}

export function getImageSizeInstance(imageList, size) {
  return imageList.instances.find( image => image.type === size )
}

export function fetchJwtOptions(token) {
  if(token) {
    return {
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      })
    }
  }
  else {
    return {}
  }
}

export function initAppleID() {
  window.AppleID.auth.init({
      clientId : 'org.thespco.content',
      scope : 'name email',
      redirectURI: `${process.env.REACT_APP_AUTH_HOST}/accounts/apple/login/callback/`,
      usePopup : true
  })
}

export class HandledError extends Error {
  constructor(...args) {
    super(...args)
    this.name = 'HandledError'
  }
}

export function isHeadless() {
  navigator.userAgent.match(/Headless/)
}

export const JW_PLAYER_SRC='https://content.jwplatform.com/libraries/LpXAiQE7.js'