import { connect } from 'react-redux'
import PersonList from '../components/compositions/PersonList'
import orm from '../models/orm'

let people = (viewBy, orm_state) => {
  const { Person } = orm.session(orm_state)

  switch (viewBy) {
  case 'composer':
    return Person.all()
      .filter((person) => {
        return Person.withId(person.id).compositions.exists()
      })
  case 'performer':
    return Person.all()
      .filter((person) => {
        return Person.withId(person.id).featured_performances.exists()
      })
  case 'conductor':
    return Person.all()
      .filter((person) => {
        return Person.withId(person.id).conducted_performances.exists()
      })
  }
}

const mapStateToProps = (state, ownProps) => ({
  people: people(ownProps.viewBy, state.data.orm).orderBy('last_name', 'first_name'),
  fetchComplete: state.data.isCompleteCombined,
  viewAsTable: state.compositions.viewAsTable
})

export default connect(
  mapStateToProps,
  null
)(PersonList)