// see https://github.com/ReactTraining/react-router/issues/3498

// TODO would be nice to conditionally import?
import hashHistory from 'history/createHashHistory'
import browserHistory from 'history/createBrowserHistory'

let historyLib = process.env.REACT_APP_USE_HASH_HISTORY === "1" ? hashHistory : browserHistory
export default historyLib({
  basename: process.env.REACT_APP_BASEPATH || ''
})
