const CompositionNavItems = [
  {viewBy: 'all', title: 'All'},
  {viewBy: 'video', title: 'With Video'},
  {viewBy: 'audio', title: 'With Audio'},
  {viewBy: "composer", title: 'Composer'},
  {viewBy: "performer", title: 'Performer'},
  {viewBy: "conductor", title: 'Conductor'},
  {viewBy: "genre", title: 'Genre'},
  {viewBy: "nationality", title: 'Nationality'},
  {viewBy: "spco-premiere", title: 'SPCO Premiere'}
]

export default CompositionNavItems