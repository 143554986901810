import { connect } from 'react-redux'
import CompositionList from '../components/compositions/CompositionList'
import orm from '../models/orm'
import { byUnformattedComposerAndTitle, compositionHasPremiere } from '../util'

const compositions = (viewBy, orm_state) => {
  if(viewBy === 'spco-premiere') {
    return orm.session(orm_state).Composition.all()
    .filter(compositionHasPremiere)
    .filter({unlisted: false, forDisplay: true})
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
  }
  else if(viewBy === 'video') {
    return orm.session(orm_state).Composition.all()
    .filter({unlisted: false, forDisplay: true, has_video: true})
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
  }
  else if(viewBy === 'audio') {
    return orm.session(orm_state).Composition.all()
    .filter({unlisted: false, forDisplay: true, has_video: false})
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
  }
  else {
    return orm.session(orm_state).Composition.all()
    .filter({unlisted: false, forDisplay: true})
    .toModelArray()
    .sort(byUnformattedComposerAndTitle)
  }
}

const mapStateToProps = (state, ownProps) => ({
  compositions: state.data.isCompleteCombined ? compositions(ownProps.viewBy, state.data.orm) : [],
  fetchComplete: state.data.isCompleteCombined,
  viewAsTable: state.compositions.viewAsTable,
  viewBy: ownProps.viewBy || 'all'
})

const CompositionListContainer = connect(
  mapStateToProps,
  null
)(CompositionList)

export default CompositionListContainer