/*eslint react/prop-types: "off"*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CuratedCollection from '../components/curated_collections/CuratedCollection'
import orm from '../models/orm'
import { fetchStreamLinksIfNeeded } from '../actions/misc_streams'
import { fetchCuratedCollectionsIfNeeded, MEDIA_TYPE_CURATED_COLLECTION  } from '../actions/media_type_fetch'
import { pauseEvent } from '../actions/player'
import { flashMessage } from 'redux-flash'

class MediaFeatureFetcher extends Component {

  fetchData() {
    if(!this.props.fetchedCollections) {
      this.props.fetchCuratedCollectionsIfNeeded()
    }
  }
  componentDidMount() {
    this.fetchData(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps)
  }

  render () {
    return <CuratedCollection {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchCompleteCombined = state.data.isCompleteCombined
  const fetchedCollections = state.data.fetchedMediaType[MEDIA_TYPE_CURATED_COLLECTION]
  let collection, items
  if(fetchedCollections) {
    const { CuratedCollection, CuratedItem} = orm.session(state.data.orm)
    collection = CuratedCollection.findByIdentifier(ownProps.match.params.identifier)
    items = CuratedItem.all().filter(item => item.curated_collection === collection.id).toModelArray()
  }
  return {
    fetchComplete: fetchedCollections && fetchCompleteCombined,
    fetchedCollections: fetchedCollections,
    collection: collection,
    hasVideo: !! (collection && collection.has_video),
    linksFetched: !! (collection && collection.fetched_streaming_links),
    curatedItems: items
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCuratedCollectionsIfNeeded: () => {
      dispatch(fetchCuratedCollectionsIfNeeded())
    },
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    },
    fetchStreamLinksIfNeeded: (stream) => {
      dispatch(fetchStreamLinksIfNeeded(stream))
    },
    pauseAudio: () => {
      dispatch(pauseEvent())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFeatureFetcher)