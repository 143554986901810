import React from 'react'
import PropTypes from 'prop-types'
import { formatInline } from '../../util'
import ImageSelector from '../ImageSelector'

let PerformanceRow = ({performance, viewAsTable}, context) => {
  const composition = performance.composition
  let handleClick = (e) => {
    context.router.history.push(`/composition/${composition.identifier}`)
  }
  return (
    <li onClick={handleClick} key={composition.id}>
      <div className="image">
        <ImageSelector imageList={composition.preferred_image} size="wd_256x144" />
      </div>
      <div className="title">
        <h4><span dangerouslySetInnerHTML={{__html: formatInline(composition.title)}} /></h4>
        <span className="composer">{composition.composer.first_name} {composition.composer.last_name}</span>
      </div>
    </li>
  )
}

PerformanceRow.propTypes = {
  performance: PropTypes.object,
  viewAsTable: PropTypes.bool
}

PerformanceRow.contextTypes = {
  router: PropTypes.object
}

export default PerformanceRow