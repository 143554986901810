import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

let CategoryRow = ({identifier, name, viewBy}, context) => {
  const url = `/compositions/${viewBy}/${identifier}`
  let handleClick = () => {
    context.router.history.push(url)
  }

  return (
    <li className="category" onClick={handleClick}>
      <div><Link to={url}>{name}</Link></div>
    </li>
  )
}

CategoryRow.propTypes = {
  identifier: PropTypes.string,
  name: PropTypes.string,
  viewBy: PropTypes.string
}
CategoryRow.contextTypes = {
  router: PropTypes.object
}
export default CategoryRow