import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MediaFeatureNavLink = ({ viewBy, currentView, children }) => {
  const url = viewBy === 'latest' ? '/features' : `/features/${viewBy}`
  const className = viewBy === currentView ? 'active' : ''
  return (
    <li><Link to={url} className={className}>
      {children}
    </Link></li>
  )
}

MediaFeatureNavLink.propTypes = {
  viewBy: PropTypes.string.isRequired,
  currentView: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default MediaFeatureNavLink
