import { connect } from 'react-redux'
import PageHeader from '../components/PageHeader'

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  section: ownProps.section
})


export default connect(
  mapStateToProps,
  null
)(PageHeader)
