import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {formatInline } from '../../util'

import ImageSelector from '../ImageSelector'
import {FaVideo} from 'react-icons/fa'
import moment from 'moment'
import values from 'lodash/values'

let MediaFeaturesList = ({features, viewBy, currentMenuItem, viewAsTable}, context) => {
  let ignoreClick = (e) => {
    e.stopPropagation()
  }

  let items
  if(currentMenuItem.has_subcategories) {

    items = values(currentMenuItem.subcategories).map( (category) => {
      let url = `/features/${viewBy}/${category.identifier}`
      let handleClick = () => {
        context.router.history.push(url)
      }
      return <li key={category.identifier} onClick={handleClick}><Link to={url} dangerouslySetInnerHTML={{__html: formatInline(category.title)}} /></li>
    })
    return <ul className="category_listing media_feature_category_list">{items}</ul>
  }
  else {
    items = features.map( (feature) => {
      let url = `/feature/${feature.identifier}`
      let handleClick = () => {
        context.router.history.push(url)
      }

      return (
        <li key={feature.id} onClick={handleClick}>
          <div className="image">
            <ImageSelector imageList={feature.image} size="wd_256x144" />
          </div>
          <div className="title">
            <h4><Link to={url} onClick={ignoreClick} dangerouslySetInnerHTML={{__html: formatInline(feature.title)}}></Link>
            {feature.isVideo() && (<span className="icons"><FaVideo /></span>) }
            </h4>
            <div className="date">{moment(feature.date_time).format('MMMM D, YYYY')}</div>
             {viewBy === 'all' && <div className="category">{feature.lastCategoryTitle()}</div>}
          </div>
        </li>
      )
    })
    const listClass = viewAsTable ? 'media_feature_list image_list' : 'media_feature_list image_grid'
    return <ul className={listClass}>{items}</ul>
  }
}


MediaFeaturesList.propTypes = {
  viewBy: PropTypes.string,
  features: PropTypes.array,
  currentMenuItem: PropTypes.object,
  viewAsTable: PropTypes.bool
}

MediaFeaturesList.contextTypes = {
  router: PropTypes.object
}

export default MediaFeaturesList