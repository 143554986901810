import {Model} from "redux-orm";

export class SpcoModel extends Model {
  static findByIdentifier(identifier) {
    return this.findBy({identifier: identifier})
  }

  // Like #withId, but without throwing errors
  static findById(id) {
    return this.findBy({id: id})
  }


  // Like #get, but without throwing errors
  static findBy(lookupObj) {
    const ModelClass = this;

    const rows = this._findDatabaseRows(lookupObj);

    if (rows.length === 0) {
      return null
    } else {
      return new ModelClass(rows[0])
    }
  }
}