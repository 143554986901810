import React from 'react'
import PropTypes from 'prop-types'
import { formatInline, parameterize, isHeadless, compositionHasPremiere } from '../../util'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import ImageSelector from '../ImageSelector'
import {FaVideo} from 'react-icons/fa'

let CompositionRow = ({composition, viewAsTable, personVideoAttribute}, context) => {
  const url = `/composition/${composition.identifier}`
  let handleClick = (e) => {
    context.router.history.push(url)
  }

  let ignoreClick = (e) => {
    e.stopPropagation()
  }

  const genres = composition.genre.map( (genre, index) =>
    <li key={index}><Link to={`/compositions/genre/${parameterize(genre)}`} onClick={ignoreClick}>{genre}</Link></li>
  )
  // const imageHeight = viewAsTable ? 78 : 140
  const offset = isHeadless() ? 2000 : 300
  const has_video = personVideoAttribute ? composition.videoForPerson : composition.has_video
  const isPremiere = personVideoAttribute ? composition.premiereForPerson : compositionHasPremiere(composition)
  if(isPremiere) {
    genres.push(<li key={200}><Link to={`/compositions/by/spco-premiere/`} onClick={ignoreClick}>SPCO Premiere</Link></li>)
  }
  return (
    <li onClick={handleClick} key={composition.id}>
      <div className="image">
        <LazyLoad offset={offset} once>
          <ImageSelector imageList={composition.preferred_image} size="wd_256x144" />
        </LazyLoad>
      </div>
      <div className="title">
        <h4><Link to={url} dangerouslySetInnerHTML={{__html: formatInline(composition.title)}} onClick={ignoreClick} />
        {has_video && <span className="icons"><FaVideo /></span> }
        </h4>
        <div className="composer">{composition.composer.first_name} {composition.composer.last_name}</div>
      </div>
      <div><ul className="genres">{genres}</ul></div>
    </li>
  )
}

CompositionRow.propTypes = {
  composition: PropTypes.object,
  viewAsTable: PropTypes.bool,
  personVideoAttribute: PropTypes.bool
}

CompositionRow.contextTypes = {
  router: PropTypes.object
}

export default CompositionRow