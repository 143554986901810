import React from 'react'
import PropTypes from 'prop-types'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import {JW_PLAYER_SRC, stripFormatting} from '../../util'
import MediaPlayer from '../MediaPlayer'

class ProgramAllVideo extends MediaPlayer {
  constructor() {
    super()
  }

  playerId() {
    return `perf-video-${this.props.program.id}`
  }

  getQueue() {
    const queue = this.props.program.performances.orderBy('position').toModelArray()
      .filter(performance => performance.hasVideo())
      .map(performance => {
        const recording = performance.videoRecording()
        const vtt_url = performance.queuepointVtt(recording)
        const tracks = vtt_url ? [{
              file: vtt_url,
              kind: 'chapters'
        }] : []
        const composerName = `${performance.composition.composer.first_name} ${performance.composition.composer.last_name}`
        return {
          file: performance.video_stream_links.broadband_standard_dynamics,
          title: `${stripFormatting(performance.program.title)}, ${composerName}`,
          tracks: tracks,
          _gaId: `V-${recording.uuid}`,
          _gaTitle: `${performance.composition.alternative_formatted_title} / ${composerName}`,
          _gaProgram: `${stripFormatting(performance.program.title)} (${performance.dateDisplay()})`,
        }
    })
    return queue
  }
  render() {
    let videoPlayer = null
    if(this.props.allVideoLoaded) {
      const playerQueue = this.getQueue()
      videoPlayer = (
          <div className="video_player">
            <ReactJWPlayer
                playerId={this.playerId()}
                isAutoPlay={true}
                aspectRatio="16:9"
                gaCategory="Media"
                playerScript={JW_PLAYER_SRC}
                onReady={this.onReady}
                file=""
                onPlayerLoaded={this.onPlayerLoaded}
                onPlay={this.onPlay}
                onEnterFullScreen={this.onEnterFullScreen}
                onExitFullScreen={this.onExitFullScreen}
                muxKey={process.env.REACT_APP_MUX_KEY}
                muxPlayer="Program Full Video"
                muxType="on-demand"
                displaytitle={false}
                playlist={playerQueue} />
          </div>
      )
    }

    return (
      <div className="composition_video full_program_video">
        {videoPlayer}
      </div>
    )
  }

}

ProgramAllVideo.propTypes = {
  program: PropTypes.object,
  allVideoLoaded: PropTypes.bool,
  pauseAudio: PropTypes.func
}

export default ProgramAllVideo