import { connect } from 'react-redux'
import CompositionPerformance from '../components/composition/CompositionPerformance'
import { playNow, addToQueue } from '../actions/player'

const mapStateToProps = (state, ownProps) => {
  return {
    composition: ownProps.composition,
    performance: ownProps.performance,
    queueHasItems: state.playerStatus.playerQueue.length !== 0,
    showMPRLogo: ownProps.showMPRLogo
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    playNow: (e) => {
      e.preventDefault()
      dispatch(playNow(ownProps.composition.id, ownProps.performance.id))
    },
    addToQueue: (e) => {
      e.preventDefault()
      dispatch(addToQueue(ownProps.composition.id, ownProps.performance.id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompositionPerformance)