import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as flashReducer } from 'redux-flash'

import playerStatus from './playerStatus'
import compositions from './compositions'
import user from './user'
import data from './data'

const listeningApp = combineReducers({
  playerStatus,
  compositions,
  user,
  form: formReducer,
  flash: flashReducer,
  data
})

export default listeningApp
