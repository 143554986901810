import { getStorage, setStorage } from '../util'

export function loadCurrentViewBy() {
  return dispatch => {
    const currentViewBy = getViewBy() || null
    if(currentViewBy == 'grid') {
      dispatch(setViewAsGrid())
    }
    else {
      dispatch(setViewAsTable())
    }
  }
}

const STORAGE_KEY_VIEW_BY = 'viewby'

function getViewBy() {
  return getStorage(STORAGE_KEY_VIEW_BY)
}

function setViewBy(val) {
  setStorage(STORAGE_KEY_VIEW_BY, val)
}

export const COMPOSITIONS_VIEW_TABLE = 'COMPOSITIONS_VIEW_TABLE'
export function setViewAsTable() {
  setViewBy('table')
  return {
    type: COMPOSITIONS_VIEW_TABLE
  }
}

export const COMPOSITIONS_VIEW_GRID = 'COMPOSITIONS_VIEW_GRID'
export function setViewAsGrid() {
  setViewBy('grid')
  return {
    type: COMPOSITIONS_VIEW_GRID
  }
}

export const COMPOSITIONS_THEATER_MODE_ON = 'COMPOSITIONS_THEATER_MODE_ON'
export function setTheaterModeOn() {
  return {
    type: COMPOSITIONS_THEATER_MODE_ON
  }
}
export const COMPOSITIONS_THEATER_MODE_OFF = 'COMPOSITIONS_THEATER_MODE_OFF'
export function setTheaterModeOff() {
  return {
    type: COMPOSITIONS_THEATER_MODE_OFF
  }
}