/*
Main Wrapper for listing of compositions for individual Composers, Artists, Conductors, Genres, etc
*/

import { connect } from 'react-redux'
import CompositionsForGroupWrapper from '../components/compositions/CompositionsForGroupWrapper'
import {compositionTitleMap} from './CompositionsContainer'

const mapStateToProps = (state, ownProps) => {
  const viewBy = ownProps.location.pathname.split('/')[2]
  return {
    title: compositionTitleMap[viewBy],
    viewBy: viewBy,
    categoryIdentifier: ownProps.match.params.categoryIdentifier,
    fetchComplete: state.data.isCompleteCombined,
    viewAsTable: state.compositions.viewAsTable
  }
}

export default connect(mapStateToProps, null)(CompositionsForGroupWrapper)