import React from 'react'
import PropTypes from 'prop-types'
import {formatMarkdown, stripFormatting, formatInline, setTitle, isHeadless, getImageSizeInstance, JW_PLAYER_SRC} from '../../util'
import LoadingIndicator from '../LoadingIndicator'
import PageHeader from '../../containers/PageHeaderContainer'
import CompositionPerformanceContainer from '../../containers/CompositionPerformanceContainer'
import ReadMore from '../ReadMore'
import ImageSelector from '../ImageSelector'
import MediaPlayer from '../MediaPlayer'
import SvgIcon from '../SvgIcon'
import playIcon from '../../../images/icons/24/play.svg'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import { Link } from 'react-router-dom'
import Meta from '../Meta'
import MetaTags from 'react-meta-tags'
import ShareButtons from '../ShareButtons'

/*eslint no-unused-vars: "off"*/
/*eslint no-debugger: "off"*/

class CuratedCollection extends MediaPlayer {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if(! this.state.playing) {
      this.props.fetchStreamLinksIfNeeded(this.props.collection)
      this.props.pauseAudio()
      this.setState({
        playing: true
      })
    }
  }
  playerId() {
    return `perf-collection-${this.props.collection.id}`
  }

  // {collection, fetchComplete, flashMessage}, context
  render() {
    let {collection, fetchComplete, flashMessage, hasVideo, curatedItems} = this.props
    let collectionItems = null
    let collectionHeader = null

    if (fetchComplete && ! collection) {
      if(isHeadless()) {
        return <React.Fragment>
          <MetaTags>
            <meta name="prerender-status-code" content="404" />
          </MetaTags>
          <div className="row"><div className="columns text-center"><h1>Collection not found</h1></div></div>
        </React.Fragment>
      }
      else {
        flashMessage("Sorry, that collection was not found in the library.")
        this.context.router.history.replace(`/collections`)
        return <div></div>
      }
    }
    else if(! fetchComplete) {
      collectionItems = <LoadingIndicator margin="70px" />
    }
    else {
      const fullTitle = stripFormatting(collection.title)
      setTitle(fullTitle)

      const image = <ImageSelector imageList={collection.image} size="wd_512x288" />
      const imageCredit = collection.image && collection.image.credit ? <div className="image_credit">{collection.image.credit}</div> : null
      let mediaPlayer
      if (! hasVideo) {
        // no-op
      }
      else if (this.showPlayerByDefault && ! this.props.linksFetched) {
        this.props.fetchStreamLinksIfNeeded(this.props.collection)
        mediaPlayer = <LoadingIndicator margin="70px" />;
      }
      else if(this.props.linksFetched && (this.showPlayerByDefault || this.state.playing)) {
        const playerQueue = [{
          file: this.props.collection.stream_links.broadband_standard_dynamics,
          title: this.props.collection.title
        }]

        // Need a poster image in case of showing <video> by default
        if (this.showPlayerByDefault) {
          const image_obj = getImageSizeInstance(collection.image, "wd_512x288")
          if(image_obj) {
            playerQueue[0].image = image_obj.url
          }
        }

        const uuid = this.props.collection.uuid
        mediaPlayer = (
            <div className="video_player">
              <ReactJWPlayer
                          playerId={this.playerId()}
                          isAutoPlay={!this.showPlayerByDefault}
                          aspectRatio="16:9"
                          gaCategory="Curated Collection"
                          gaUuid={`F-${uuid}`}
                          playerScript={JW_PLAYER_SRC}
                          onReady={this.onReady}
                          file=""
                          onPlayerLoaded={this.onPlayerLoaded}
                          onPlay={this.onPlay}
                          onEnterFullScreen={this.onEnterFullScreen}
                          onExitFullScreen={this.onExitFullScreen}
                          playlist={playerQueue} />
            </div>
        )
      }
      else {
        const playButton = (
          <div className="play_icon">
            <SvgIcon className="play" icon={playIcon} alt="Play" height="24" width="24" fill="#ffffff" />
          </div>
        )
        mediaPlayer = (
         <div className="row video_preview">
            <div className="columns medium-6 small-12">
              <div className="video_thumbnail" onClick={this.onClick} >
                <ImageSelector imageList={collection.image} size="wd_512x288" />
                <div className="overlay">
                  {playButton}
                </div>
              </div>
            </div>
            <div className="columns medium-6 small-12">
              <strong className="watch_link" onClick={this.onClick}>Watch Intro</strong>
              <br/>
              <span className="time">{collection.duration_formatted}</span>
          </div>
        </div>
        )
      }

      const path = `/collections/${collection.identifier}`
      const fbMessage = "Check out this cool collection of music from The Saint Paul Chamber Orchestra! You can stream it for free."
      const twMessage = "Check out this @thespco collection of music! #NowStream for #Free and #OnDemand"
      const nativeMessage = 'Check out this free collection of SPCO digital performances!'

      // eslint-disable-next-line
      collectionHeader = (
            <div className="row">
              <div className="columns medium-4 small-12 show-for-medium">
                <div className="image">{image}</div>
                {imageCredit}
              </div>
              <div className="text columns medium-8 small-12">
                <h1 dangerouslySetInnerHTML={{__html: formatInline(collection.title)}} />
              {collection.byline && <div className="composer byline">
                {collection.byline}
              </div>}
                <div className="header_image show-for-small-only">
                  {image}
                  {imageCredit}
                </div>
                <div className="program_note long_description">
                  <ReadMore lines={5} ellipsis={<span>... <a href='#'>Read more</a></span>}>
                      <div dangerouslySetInnerHTML={{__html: formatMarkdown(collection.introduction_text || '')}} />
                  </ReadMore>
                </div>
                <ShareButtons path={path} fb={fbMessage} tw={twMessage} native={nativeMessage} />

                {hasVideo && mediaPlayer
                }
              </div>
            </div>
          )

      const items = curatedItems.map(collectionItem => {
        const performance = collectionItem.performance
        const compositionLink = performance.composition.forDisplay
        ? <Link to={`/composition/${performance.composition.identifier}`}>
            <span dangerouslySetInnerHTML={{__html: formatInline(performance.composition.title)}} />
          </Link>
          : <strong dangerouslySetInnerHTML={{__html: formatInline(performance.composition.title)}} />
        return (
        <div className="program_performance" key={`${performance.id}-wrap`}>
          <h3>
            {compositionLink}
            <span>{performance.composition.composer.first_name} {performance.composition.composer.last_name}</span>
          </h3>
            {collectionItem.note && <div className="curated_item_note long_description" dangerouslySetInnerHTML={{__html: formatMarkdown(collectionItem.note || '')}} />}
            <CompositionPerformanceContainer performance={performance} composition={performance.composition} key={performance.id} showMPRLogo={true} showUnderwriterLogo={false} showDate={false} />
        </div>
        )
      }
      )
      collectionItems = (
        <div>
        <Meta title={fullTitle} image={collection.image} />
          <ul className="program_performances">
            {items}
          </ul>
        </div>
      )
    }

    return (
      <div>
        <PageHeader section="collections">
          <div className="row composition_show">
            <div className="columns small-12">
              <header className="image_header program">
                <div className="page_label">Curated Collection</div>
                {collectionHeader}
              </header>
            </div>
          </div>
        </PageHeader>
        <main>
          <div className="row">
            <div className="columns">
              {collectionItems}
            </div>
          </div>
        </main>
      </div>
    )
  }
}

CuratedCollection.propTypes = {
  collection: PropTypes.object,
  fetchComplete: PropTypes.bool,
  fetchedCollections: PropTypes.bool,
  flashMessage: PropTypes.func,
  curatedItems: PropTypes.array
}

CuratedCollection.contextTypes = {
  router: PropTypes.object
}
export default CuratedCollection