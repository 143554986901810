/*
Listing of Compositions for individual Genres, Nationalities
*/

import { connect } from 'react-redux'
import CategoryCompositionList from '../components/compositions/CategoryCompositionList'
import orm from '../models/orm'
import { humanize, titleize, byUnformattedComposerAndTitle } from '../util'
import arrayIncludes from 'lodash/includes'

const findCompositions = (viewBy, identifier, orm_state) => {
  const { Composition } = orm.session(orm_state)
  const originalName = humanize(identifier)
  switch (viewBy) {
  case 'genre':
    return Composition.all()
          .filter({unlisted: false, forDisplay: true}).filter((composition) => {
            return arrayIncludes(composition.genre, originalName)
          })
          .toModelArray()
          .sort(byUnformattedComposerAndTitle)
  case 'nationality':
    return Composition.all()
            .filter({country: originalName, unlisted: false, forDisplay: true})
            .toModelArray()
            .sort(byUnformattedComposerAndTitle)
  default:
    return []
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    category: titleize(ownProps.categoryIdentifier),
    compositions: findCompositions(ownProps.viewBy, ownProps.categoryIdentifier, state.data.orm),
    viewAsTable: state.compositions.viewAsTable,
    viewBy: ownProps.viewBy
  }
}

export default connect(
  mapStateToProps,
  null
)(CategoryCompositionList)