import React from 'react'
import PropTypes from 'prop-types'
import CompositionRow from './CompositionRow'

let CompositionList = ({compositions, viewAsTable, viewBy, personVideoAttribute}) => {
  const compositionItems = compositions.map(composition =>
    <CompositionRow composition={composition} key={composition.id} viewAsTable={viewAsTable} personVideoAttribute={personVideoAttribute} />
  )
  const listClass = viewAsTable ? 'compositions image_list' : 'compositions image_grid'
  // const listClass = 'compositions image_list'
  return (
    <ul className={listClass}>{compositionItems}</ul>
  )
}

CompositionList.propTypes = {
  compositions: PropTypes.array,
  viewAsTable: PropTypes.bool,
  viewBy: PropTypes.string,
  personVideoAttribute: PropTypes.bool
}

export default CompositionList