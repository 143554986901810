import React, { Component } from 'react';

import createEventHandlers from './create-event-handlers';
import getCurriedOnLoad from './helpers/get-curried-on-load';
import getPlayerOpts from './helpers/get-player-opts';
import initialize from './helpers/initialize';
import installPlayerScript from './helpers/install-player-script';

import defaultProps from './default-props';
import propTypes from './prop-types';
import fireGa from './helpers/ga';
const displayName = 'ReactJWPlayer';

class ReactJWPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adHasPlayed: false,
      hasPlayed: false,
      hasFired: {},
    };
    this.eventHandlers = createEventHandlers(this);
    this.uniqueScriptId = 'jw-player-script';
    this._initialize = this._initialize.bind(this);
    this.fireGa = fireGa;
  }
  componentDidMount() {
    const isJWPlayerScriptLoaded = !!window.jwplayer;
    if (isJWPlayerScriptLoaded) {
      this._initialize();
      return;
    }

    const existingScript = document.getElementById(this.uniqueScriptId);

    if (!existingScript) {
      installPlayerScript({
        context: document,
        onLoadCallback: this._initialize,
        scriptSrc: this.props.playerScript,
        uniqueScriptId: this.uniqueScriptId,
      });
    } else {
      existingScript.onload = getCurriedOnLoad(existingScript, this._initialize);
    }
  }
  _initialize() {
    this.props.onPlayerLoaded()

    const component = this;
    const player = window.jwplayer(this.props.playerId);
    this.player = player
    const playerOpts = getPlayerOpts(this.props);

    if((process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID) && window.spcoCustomJWPlayerProvider) {
      // The native app injects spcoCustomJWPlayerProvider on page load. We need to register
      // it just once after the jwplayer script is initialized but before we create a player.
      window.jwplayer.api.registerProvider(window.spcoCustomJWPlayerProvider);
      window.spcoCustomJWPlayerProvider = null;
    }

    initialize({ component, player, playerOpts });

    if(this.props.muxKey) {
      const muxLoaded = !! window.initJWPlayerMux;
      if(muxLoaded) {
        this.setupMux()
      }
      else {
        const muxScript = document.getElementById('mux-script');
        muxScript.onload = this.setupMux;
      }
    }
  }
  getGaAttribute(attributeName) {
    const index = this.player.getPlaylistIndex()
    const attrCapitalized = attributeName.charAt(0).toUpperCase()
    + attributeName.slice(1) // eg _gaTitle
    return this.props.playlist[index][`_ga${attrCapitalized}`]
  }
  setupMux() {
    if(typeof window.initJWPlayerMux !== 'undefined') {
      const player = window.jwplayer(this.props.playerId)
      window.initJWPlayerMux(player, {
        debug: false,
        data: {
          env_key: this.props.muxKey, // required
          // Metadata
          player_name: this.props.muxPlayer, // ex: 'My Main Player'
          player_init_time: Date.now(), // ex: 1451606400000
          video_title: this.props.playlist[0].title,
          video_stream_type: this.props.muxType
        }
      });
    }
  }
  render() {
    return (
      <div className={this.props.className} id={this.props.playerId} />
    );
  }
}

ReactJWPlayer.defaultProps = defaultProps;
ReactJWPlayer.displayName = displayName;
ReactJWPlayer.propTypes = propTypes;
export default ReactJWPlayer;
