/*eslint react/prop-types: "off"*/

import React, {PureComponent} from 'react'

export default class SvgIcon extends PureComponent {
  render() {
    const {className, icon, ...restProps} = this.props

    const iconHref = process.env.REACT_APP_EMBED_SVG === "1" ? `#${icon.id}` : icon.url

    return (
      <svg className={className} viewBox={icon.viewBox}{...restProps}>
        {restProps.title &&
          <title>{restProps.title}</title>
        }
        <use xlinkHref={iconHref} />
      </svg>
    );
  }
}

SvgIcon.defaultProps = {
  className: 'icon'
}