import { connect } from 'react-redux'
import { resetPassword } from '../actions/user'
import ResetPassword from '../components/user/ResetPassword'

const mapStateToProps = (state, ownProps) => ({
  signedIn: !! state.user.currentUser
  }
)

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (userInfo) => {
      dispatch(resetPassword(userInfo, ownProps.match.params.uid, ownProps.match.params.token))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
