import React from 'react'
import PropTypes from 'prop-types'

let LoadingIndicator = (props) => {
  const divStyle = {
    marginTop: props.margin,
    marginBottom: props.margin
  }
  if(props.alt === 'A') {
    return (
      <div className="sk-double-bounce">
        <div className="sk-child sk-double-bounce1"></div>
        <div className="sk-child sk-double-bounce2"></div>
      </div>
    )
  }
  else if(props.alt) {
    return (
      <div className="spinner_alt" style={divStyle}>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
      </div>
    )
  }
  return (
    <div className="spinner" style={divStyle}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  )
}

LoadingIndicator.propTypes = {
  margin: PropTypes.string,
  alt: PropTypes.string
}
export default LoadingIndicator


