import { ORM } from 'redux-orm';
import { Composition } from "./composition";
import { Performance } from "./performance";
import { Person } from "./person";
import { Program } from "./program";
import { FeaturedArtist } from "./featured_artist";
import { LiveStream } from "./live_stream";
import { MediaFeature } from "./media_feature";
import { CuratedCollection } from "./curated_collection";
import { CuratedItem } from "./curated_item";
import { HomeContent } from "./home_content";
import { RelatedMediaFeature } from "./related_media_feature";

const orm = new ORM();
orm.register(Composition, Performance, FeaturedArtist, Person, Program, LiveStream, MediaFeature, CuratedCollection, CuratedItem, HomeContent, RelatedMediaFeature);

export default orm;