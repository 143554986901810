import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../containers/PageHeaderContainer'
import LoadingIndicator from '../LoadingIndicator'
import HomeCompositionRow from './HomeCompositionRow'
import HomeProgramRow from './HomeProgramRow'
import HomePerformanceRow from './HomePerformanceRow'
import HomeCuratedCollectionRow from './HomeCuratedCollectionRow'
import HomeComposerRow from './HomeComposerRow'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import { setTitle, formatInline, getImageSizeInstance, logAnalyticEvent, JW_PLAYER_SRC} from '../../util'
import { Link } from 'react-router-dom'
import ImageSelector from '../ImageSelector'
import mpr from '../../../images/icons/classical_mpr.svg'
import uhc from '../../../images/icons/uhc_medicare_advantage.svg'
import SvgIcon from '../SvgIcon'
import Meta from '../Meta'

/*eslint no-unused-vars: "off"*/
/* eslint react/jsx-no-target-blank: 0 */

let Home = ({fetchComplete, videoPrograms, audioPrograms, compositionsWithVideo, showUpcomingStream, nextStream, homeBody, homeVideo, homeVideoPosterImage, highlights, collections, composers}) => {
  setTitle(null)

  let contributeAnalytics = () => {
    logAnalyticEvent('contribute_click', {page_location: window.location.pathname})
  }

  let itemList, liveStream, videoPlayer, highlightRows, curatedRows, showHomeContent

  if(!fetchComplete) {
    itemList = <LoadingIndicator margin="70px" />
  }
  else {
    const videoProgramRows = videoPrograms.map((program) =>
      <HomeProgramRow key={`comp-${program.id}`} program={program} />
    )
    const audioProgramRows = audioPrograms.map((program) =>
      <HomeProgramRow key={`comp-${program.id}`} program={program} />
    )
    if(highlights) {
      highlightRows = highlights.map((performance) =>
        <HomePerformanceRow key={`perf-${performance.id}`} performance={performance} />
      )
    }
    if(collections) {
      curatedRows = collections.map((collection) =>
        <HomeCuratedCollectionRow key={`collection-${collection.identifier}`} collection={collection} />
      )
    }

    const composerRows = composers.map((composer) =>
      <HomeComposerRow key={`composer-${composer.id}`} composer={composer} />
    )

    if(homeVideo) {
      const playerQueue = [{
        file: homeVideo,
        title: "",
        _gaTitle: 'Home Video',
        _gaId: ''
      }]

      if (homeVideoPosterImage) {
        const image_obj = getImageSizeInstance(homeVideoPosterImage, "wd_512x288")
        if(image_obj) {
          playerQueue[0].image = image_obj.url
        }
      }
      videoPlayer = (
          <div className="video_player">
            <ReactJWPlayer
                        playerId="home-browse"
                        isAutoPlay={false}
                        aspectRatio="16:9"
                        gaCategory="Home Video"
                        playerScript={JW_PLAYER_SRC}
                        file=""
                        playlist={playerQueue}
                        />
          </div>
      )
    }

    itemList = (
      <div>
      <section>
        <h2><Link to="/concerts/video">Video Concerts</Link></h2>
        <Link to="/concerts/video" className="button light">Browse All</Link>
        <ul className="home_list">
          {videoProgramRows}
        </ul>
      </section>

      {highlights && highlights.length > 0 && <section>
        <h2><Link to="/compositions">Highlights</Link></h2>
        <Link to="/compositions" className="button light">Browse All</Link>
        <ul className="home_list">
          {highlightRows}
        </ul>
      </section>
      }

      {collections && collections.length > 0 && <section>
        <h2><Link to="/collections">Curated Collections</Link></h2>
        <Link to="/collections" className="button light">Browse All</Link>
        <ul className="home_list">
          {curatedRows}
        </ul>
      </section>
      }

      <section>
        <h2><Link to="/compositions/by/audio">Audio Concerts</Link></h2>
        <Link to="/compositions/by/audio" className="button light">Browse All</Link>
        <ul className="home_list">
          {audioProgramRows}
        </ul>
      </section>

      <section>
        <h2><Link to="/compositions/by/composer">Composers</Link></h2>
        <Link to="/compositions/by/composer" className="button light">Browse All</Link>
        <ul className="home_list">
          {composerRows}
        </ul>
      </section>

      </div>
      )
      if(showUpcomingStream) {
        const liveStreamNote = nextStream.isLive() ? `Live stream now!` : `Live stream starts ${nextStream.startDisplay()}.`
        liveStream = <div className="row">
            <div className="columns">
              <div className="live_stream_highlight">
              <div className="row">
                <div className="image columns medium-4 small-5 collapse align-self-middle">
                  <ImageSelector imageList={nextStream.preferred_image} size="wd_512x288" />
                </div>
                <div className="columns small-7 medium-8">
                  <div className="label"><i className="play_circle"></i>Watch Live</div>
                  <h3><Link className="title" to={`/live-stream/${nextStream.identifier}`}><span dangerouslySetInnerHTML={{__html: formatInline(nextStream.title)}} /></Link></h3>
                  <div className="start">{liveStreamNote}</div>

                  <Link className="button small" to={`/live-stream/${nextStream.identifier}`}>View stream</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    }
    showHomeContent = homeBody && ! (showUpcomingStream && nextStream.isLive())
  }
  return (
    <div>
      <PageHeader>
        <Meta title={null} description={null} />
        <div className="row">
          <div className="columns small-12">
            <header className="row home_header">
              <div className="columns medium-8">
                <h1>Concert Library</h1>
                <h2>Enjoy more than 100 streaming performances. Always free, thanks to audience members like you.</h2>
              </div>
            </header>
          </div>
         </div>
      </PageHeader>

      <main>
        { showHomeContent &&
          <div className="row">
            <div className="columns small-12 medium-6 large-6 home_video">{videoPlayer}</div>
            <div className="columns small-12 medium-6 large-6 home_highlight"><div dangerouslySetInnerHTML={{__html: homeBody}} /></div>
          </div>
        }
        {liveStream}
        <div className="row">
          <div className="columns home_lists">
            {itemList}
          </div>
        </div>
        <div className="row align-middle">
          <div className="contribute_block columns">
            <div className="row">
              <div className="columns align-self-middle">
                <h5>Contribute Online.</h5>
                <p>With your help, we’re sharing the music we play with more people everywhere — free of charge.</p>
              </div>
              <div className="columns shrink align-self-middle"><a className="button" href="https://www.thespco.org/concertlibrarysupport" onClick={contributeAnalytics}>Contribute Now</a></div>
            </div>
          </div>
        </div>

        <div className="row home_blocks align-center">

          <div className="columns small-12 medium-3">
            <h2>Underwriting Support</h2>
              <p>Underwriting Support Provided By</p>
            <a href="http://advantageuhc.com" target="_blank"><SvgIcon icon={uhc} title="United Healthcare"/></a>
          </div>

          <div className="columns small-12 medium-3">
            <h2>Classical MPR</h2>
            <p>Concert audio provided by</p>
            <a href="https://www.yourclassical.org/mpr" target="_blank"><SvgIcon icon={mpr} title="classicalMPR" className="mpr" /></a>
          </div>


          <div className="columns small-12 medium-3">
            <h2>Feedback</h2>
            <h3>Your feedback helps us improve the Concert Library Experience.</h3>
            <p>We welcome your feedback, suggestions, and questions about our Concert Library.</p>
            <div><a className="button" href="https://www.thespco.org/about-us/contact-us/">Share your feedback</a></div>
          </div>

        </div>
      </main>
    </div>
  )
}

Home.propTypes = {
  fetchComplete: PropTypes.bool,
  hasHomeData: PropTypes.bool,
  videoPrograms: PropTypes.array,
  audioPrograms: PropTypes.array,
  highlights: PropTypes.array,
  collections: PropTypes.array,
  composers: PropTypes.array,
  compositionsWithVideo: PropTypes.array,
  showUpcomingStream: PropTypes.bool,
  nextStream: PropTypes.object,
  homeBody: PropTypes.string,
  homeVideo: PropTypes.string,
  homeVideoPosterImage: PropTypes.object,
}
export default Home