import React from 'react'
import { logAnalyticEvent } from '../util'

let ContributeFooter = () => {
  let contributeAnalytics = () => {
    logAnalyticEvent('contribute_click', {page_location: window.location.pathname})
  }

  return (
  <div className="row align-middle">
    <div className="contribute_block columns">
      <div className="row">
        <div className="columns align-self-middle">
          <h5>Contribute Now.</h5>
          <p>We need your support to continue sharing music with people everywhere, completely free of charge.</p>
        </div>
        <div className="columns shrink align-self-middle"><a className="button" href="https://www.thespco.org/concertlibrarysupport" target="_blank" rel="noopener noreferrer" onClick={contributeAnalytics}>Contribute Online</a></div>
      </div>
    </div>
  </div>
  )
}

export default ContributeFooter