import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Popover from 'react-popover'
import SvgIcon from './SvgIcon'
import chevron from '../../images/icons/16/chevron-down.svg'
import {FaQuestionCircle} from 'react-icons/fa'

class UserMenu extends Component {
  constructor() {
    super()
    this.toggle = this.toggle.bind(this)
    this.onOuterAction = this.onOuterAction.bind(this)
    this.state = {
     isOpen: false
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onOuterAction() {
    this.setState({ isOpen: false })
  }

/* eslint react/jsx-no-target-blank: 0 */
  render() {
    let {currentUser, signOut, mobile} = this.props

    if(! currentUser) {
      return (
        <ul className="user_nav">
          <li><a className="small_link" href="https://www.thespco.org/concert-library-help/" target="_blank">Concert Library Help</a></li>
          <li><a className="large_link" href="https://www.thespco.org/concert-library-help/" target="_blank"><span className="icons"><FaQuestionCircle /></span> Help</a></li>
          <li><Link to="/sign-in">Sign in</Link></li>
        </ul>
      )
    }
    else {
      const name = currentUser.user.first_name ? `${currentUser.user.first_name} ${currentUser.user.last_name}` : 'Signed in'

      const signOutClick = (e) => {
        e.preventDefault()
        if(this.props.onClose) {
          this.props.onClose()
        }
        signOut()
      }
      if(mobile) {
        return (
          <ul className="user_nav signed_in">
            <li className="user_name">{name}</li>
            <li><Link to="/account">My Account</Link></li>
            <li><a href="#" onClick={signOutClick}>Sign Out</a></li>
          </ul>
        )
      }
      else {
        const dropDown = (
          <ul className="user_dropdown">
            <li><a className="large_link" href="https://www.thespco.org/concert-library-help/" target="_blank">Concert Library Help</a></li>
            <li><Link to="/account">My Account</Link></li>
            <li><a href="#" onClick={signOutClick}>Sign Out</a></li>
          </ul>
        )
        // eslint-disable-next-line
        return (
          <div className="user_nav user_nav signed_in">
            <Popover isOpen={this.state.isOpen} onOuterAction={this.onOuterAction} body={dropDown} className="user_dropdown_popover">
              <div className="user_menu_toggle" onClick={this.toggle}>
                <span className="link">{name}</span>
                <SvgIcon icon={chevron} fill="#b5b5b5" height="16" width="16" />
              </div>
            </Popover>
          </div>
        )
      }
    }
  }
}

UserMenu.propTypes = {
  currentUser: PropTypes.object,
  signOut: PropTypes.func,
  mobile: PropTypes.bool,
  onClose: PropTypes.func
}

export default UserMenu