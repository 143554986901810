/*eslint react/prop-types: "off"*/
/*eslint no-case-declarations: "off"*/

/* Listing of Genres and Nationalities  */

import { connect } from 'react-redux'
import GroupList from '../components/compositions/GroupList'
import orm from '../models/orm'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import without from 'lodash/without'
import {sortByLocale, parameterize } from '../util'

const groups = (viewBy, orm_state) => {
  const { Composition } = orm.session(orm_state)
  switch (viewBy) {
  case 'genre':
    const genres = uniq(
      flatten(Composition.all().filter({unlisted: false, forDisplay: true}).toRefArray().map(composition => composition.genre)
    ))
    return sortByLocale(genres).map(genre => ({name: genre, identifier: parameterize(genre) }))
  case 'nationality':
    const nationalities = uniq(
      Composition.all().filter({unlisted: false, forDisplay: true}).toRefArray().map(composition => composition.country)
    )
    return sortByLocale(without(nationalities, null)).map(nation => ({name: nation, identifier: parameterize(nation)}))
  }
}

const mapStateToProps = (state, ownProps) => ({
  groups: groups(ownProps.viewBy, state.data.orm),
  fetchComplete: state.data.isCompleteCombined,
  viewBy: ownProps.viewBy,
  viewAsTable: state.compositions.viewAsTable
})

export default connect(
  mapStateToProps,
  null
)(GroupList)