import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgramAllVideo from '../components/programs/ProgramAllVideo'
import { pauseEvent } from '../actions/player'
import { fetchAllProgramVideoIfNeeded } from '../actions/fetch_composition'
import PropTypes from 'prop-types'

class ProgramVideoFetcher extends Component {
  componentDidMount() {
    if(! this.props.allVideoLoaded) {
      this.props.fetchAllProgramVideoIfNeeded()
    }
  }

  render () {
    return <ProgramAllVideo {...this.props} />
  }
}

ProgramVideoFetcher.propTypes = {
  program: PropTypes.object,
  allVideoLoaded: PropTypes.bool,
  fetchAllProgramVideoIfNeeded: PropTypes.func,
  pauseAudio: PropTypes.func
}


const mapStateToProps = (state, ownProps) => {
  const program = ownProps.program

  return {
    program: program,
    allVideoLoaded: program.fetched_all_video
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAllProgramVideoIfNeeded: () => {
      dispatch(fetchAllProgramVideoIfNeeded(ownProps.program))
    },
    pauseAudio: () => {
      dispatch(pauseEvent())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramVideoFetcher)