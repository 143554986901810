import { connect } from 'react-redux'
import Player from '../components/player/Player'
import { pauseEvent, resumeEvent, nextEvent, prevEvent, playerPlayedEvent, setQueueItemEvent, itemFinishedEvent, playerFinishedEvent, removeFromQueueEvent } from '../actions/player'
import orm from '../models/orm'

const mapStateToProps = (state, ownProps) => {
  const { Composition, Performance } = orm.session(state.data.orm)

  const fullQueue = state.playerStatus.playerQueue.map( item => {
    const composition = Composition.findById(item.composition_id)
    const performance = Performance.findById(item.performance_id)
    if(! composition || ! performance) {
      return {}
    }
    // TODO: system for selecting stream links
    // Handles edge case where stream links happens to be null,
    // which should not happen at this point
    const audio_url = performance.stream_links ? performance.stream_links.broadband_standard_dynamics || performance.stream_links.cellular_standard_dynamics : null;
    return {
      composition: composition,
      performance: performance,
      url: audio_url,
      recording_uuid: performance.audioRecording().uuid,
      vtt: performance.queuepointVtt(performance.audioRecording())
    }
  })
  const activeQueue = fullQueue.filter(queue_item => queue_item.url)
  return {
    status: state.playerStatus.status,
    finishedAt: state.playerStatus.finishedAt,
    currentItem: Math.min(state.playerStatus.currentItem, activeQueue.length - 1), // handle case where items were removed in filter
    fullQueue: activeQueue
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onPause: () => {
      dispatch(pauseEvent())
    },
    onNext: () => {
      dispatch(nextEvent())
    },
    onPrev: () => {
      dispatch(prevEvent())
    },
    onResume: () => {
      dispatch(resumeEvent())
    },
    setQueueItem: (itemIndex) => {
      dispatch(setQueueItemEvent(itemIndex))
    },
    removeQueueItem: (itemIndex) => {
      dispatch(removeFromQueueEvent(itemIndex))
    },
    onItemFinished: () => {
      dispatch(itemFinishedEvent())
    },
    onPlayerFinished: () => {
      dispatch(playerFinishedEvent())
    },
    onPlayerPlay: () => {
      dispatch(playerPlayedEvent())
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Player)