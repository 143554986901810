import React from 'react'
import PropTypes from 'prop-types'
import { FacebookShareButton, FacebookIcon } from 'react-share'

import { IoShareOutline } from 'react-icons/io5'

let ShareButtons = ({path, fb, tw, native}) => {

  function triggerShare(e) {
    e.preventDefault()
    navigator.share({text: native, url: `${process.env.REACT_APP_AUTH_HOST}${process.env.REACT_APP_BASEPATH}${path}`})
  }

  if(!path) { return null}

  const url = `${process.env.REACT_APP_AUTH_HOST}${process.env.REACT_APP_BASEPATH}${path}`

  if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
    return (
      <div className="native_share">
        <button className="button" onClick={triggerShare}><IoShareOutline /> Share</button>
      </div>
    )
  }
  else {
    return (
      <div className="share_buttons">
        <FacebookShareButton className="fb" url={url} quote={fb}><FacebookIcon size={24} />Share</FacebookShareButton>
      </div>
    )
  }
}

ShareButtons.propTypes = {
  path: PropTypes.string,
  fb: PropTypes.string,
  tw: PropTypes.string,
  native: PropTypes.string
}

export default ShareButtons