/*eslint react/prop-types: "off"*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MediaFeatures from '../components/media_features/MediaFeatures'
import orm from '../models/orm'
import { fetchMediaFeaturesIfNeeded, MEDIA_TYPE_MEDIA_FEATURE  } from '../actions/media_type_fetch'

// Since there's no onChange in Router v4
// cf https://github.com/ReactTraining/react-router/issues/3854
class MediaFeatureFetcher extends Component {

  componentDidMount() {
    if(!this.props.fetchedFeatures) {
      this.props.fetchMediaFeaturesIfNeeded()
    }
  }

  render () {
    if(! this.props.fetchedFeatures || ! this.props.fetchCompleteCombined) {
      return <MediaFeatures title={this.props.title} viewBy={this.props.viewBy} fetchComplete={false} />
    }
    // Preventing re-render, probably not necessary
    else {
      return <MediaFeatures {...this.props} fetchComplete={true} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchCompleteCombined = state.data.isCompleteCombined
  const fetchedFeatures = state.data.fetchedMediaType[MEDIA_TYPE_MEDIA_FEATURE]

  const viewBy = ownProps.match.params.viewBy || 'all'
  const subcategory = ownProps.match.params.subcategory || null

  let features, currentMenuItem
  var toplevelCategories = {}
  toplevelCategories['all'] = {title: 'All Features', identifier: 'all', subcategories: []}
  if(fetchCompleteCombined && fetchedFeatures) {
    // Collect top-level media features for navigation
   orm.session(state.data.orm).MediaFeature.all().toModelArray().forEach( (feature) => {
      let topCat = feature.category_hierarchy[0]
      let existing = toplevelCategories[topCat.identifier]
      if(! existing) {
        toplevelCategories[topCat.identifier] = topCat
        toplevelCategories[topCat.identifier].subcategories = {}
        toplevelCategories[topCat.identifier].has_subcategories = false
      }
      if(feature.category_hierarchy.length > 1) {
        toplevelCategories[topCat.identifier].subcategories[feature.category_hierarchy[1].identifier] = feature.category_hierarchy[1]
        toplevelCategories[topCat.identifier].has_subcategories = true
      }
    })
    if(subcategory) {
      currentMenuItem = toplevelCategories[viewBy].subcategories[subcategory]
    }
    else {
      currentMenuItem = toplevelCategories[viewBy]
    }
    features = (() => {
      if(viewBy === 'all') {
        return orm.session(state.data.orm)
                .MediaFeature.all()
                .orderBy(['date_time'], 'desc')
                .toModelArray()
      }
      else if(currentMenuItem.subcategories > 0) {
        return []
      }
      else {
        return orm.session(state.data.orm)
                .MediaFeature.all()
                .filter({baseCategory: subcategory || viewBy})
                .orderBy('sort_weight', 'date')
                .toModelArray()
      }
    })()
  }

  return {
    features: features,
    fetchCompleteCombined: fetchCompleteCombined,
    fetchedFeatures: fetchedFeatures,
    viewBy: viewBy,
    subcategory: subcategory,
    title: currentMenuItem && currentMenuItem.title,
    toplevelCategories: toplevelCategories,
    currentMenuItem: currentMenuItem,
    viewAsTable: state.compositions.viewAsTable
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchMediaFeaturesIfNeeded: () => {
      dispatch(fetchMediaFeaturesIfNeeded())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaFeatureFetcher)