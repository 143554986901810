import {attr} from "redux-orm"
import { SpcoModel } from './spco_model'

export class Program extends SpcoModel {

  toString() {
    return this.title;
  }
  hasPlayablePerformance() {
    return this.performances.exists()
  }
}
Program.modelName = 'Program'

Program.fields = {
  fetched_all_video: attr({ getDefault: () => false }),
  unlisted: attr({ getDefault: () => false }),
}