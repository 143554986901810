import {fk, attr} from "redux-orm"
import { SpcoModel } from './spco_model'

export class RelatedMediaFeature extends SpcoModel {
  toString() {
    return this.title;
  }
}

RelatedMediaFeature.modelName = 'RelatedMediaFeature'
RelatedMediaFeature.fields = {
  media_feature: fk('MediaFeature'),
  program: fk('Program', 'related_media_features'),
  performance: fk('Performance', 'related_media_features'),
  composition: fk('Composition', 'related_media_features'),
  description: attr()
}