import React from 'react'
import PropTypes from 'prop-types'
import { formatMarkdown, formatInline, setTitle, isHeadless } from '../../util'
import { Link } from 'react-router-dom'
import CompositionPerformanceContainer from '../../containers/CompositionPerformanceContainer'
import LoadingIndicator from '../LoadingIndicator'
import PageHeader from '../../containers/PageHeaderContainer'
import ReadMore from '../ReadMore'
import ImageSelector from '../ImageSelector'
import Meta from '../Meta'
import MetaTags from 'react-meta-tags'
import ShareButtons from '../ShareButtons'

let Composition = ({fetchComplete, hasDetail, composition, flashMessage}, context) => {
  let performances, compositionHeader, fullTitle

  if(! fetchComplete) {
    return <LoadingIndicator margin="70px" />;
  }
  else if(! composition) {
    if(isHeadless()) {
      return <React.Fragment>
      <MetaTags>
               <meta name="prerender-status-code" content="404" />
             </MetaTags>
        <div className="row"><div className="columns text-center"><h1>Composition not found</h1></div></div>
      </React.Fragment>
    }
    else {
      flashMessage("Sorry, that composition was not found in the library.")
      context.router.history.push(`/compositions`)
      return <div></div>
    }
  }
  // Still fetching
  else if(! hasDetail) {
    compositionHeader = <LoadingIndicator margin="70px" />
    performances = ''
  }
  else {
    fullTitle = `${composition.title} by ${composition.composer.first_name} ${composition.composer.last_name}`
    setTitle(fullTitle)
    let performanceList

    // on unlisted compositions, show all the performances; otherwise you never show the unlisted ones
    if(composition.unlisted) {
      performanceList = composition.performances.orderBy(['start_date_time'], 'desc').toModelArray()
    }
    else {
      performanceList = composition.performances.filter({unlisted: false}).orderBy(['start_date_time'], 'desc').toModelArray()
    }
    performances = performanceList.map(performance =>
        <CompositionPerformanceContainer performance={performance} composition={composition} key={performance.id} />
    )
    const composer = composition.composer
    const arranger = composition.arranger
    // eslint-disable-next-line
    const composerUrl = `/compositions/composer/${composer.identifier}`
    const image = <ImageSelector imageList={composition.preferred_image} size="wd_512x288" />
    const imageCredit = composition.preferred_image && composition.preferred_image.credit ? <div className="image_credit">{composition.preferred_image.credit}</div> : null

    const fbMessage = "Check out this performance by The Saint Paul Chamber Orchestra! You can stream it for free."
    const twMessage = "Check out this @thespco performance! #NowStreaming for #Free"
    const nativeMessage = 'Check out this free digital SPCO performance!'
    let path = `/composition/${composition.identifier}`

    // eslint-disable-next-line
    compositionHeader = (
      <div className="row">
        <div className="columns medium-4 small-12 show-for-medium">
          <div className="image">{image}</div>
          {imageCredit}
        </div>
        <div className="text columns medium-8 small-12">
          {composition.spco_commission && <div className="commission">SPCO Commissioned</div>}
          <h1><span dangerouslySetInnerHTML={{__html: formatInline(composition.title)}} /> <span className="opus">{composition.opus}</span></h1>
          <div className="composer">
            <Link to={composerUrl}>{composer.first_name} {composer.last_name}</Link>
            &nbsp;<span className="composer_year">{composer.yearDisplay()}</span>

            {arranger &&
              <span className="arranger">
                arr. {arranger.first_name} {arranger.last_name}
              </span>
            }
          </div>

          <div className="header_image show-for-small-only">
            {image}
            {imageCredit}
          </div>
          <div className="program_note long_description">
            <ReadMore lines={5} ellipsis={<span>... <a href='#'>Read more</a></span>}>
                <div dangerouslySetInnerHTML={{__html: formatMarkdown(composition.programNote())}} />
                <div className="byline" dangerouslySetInnerHTML={{__html: composition.programNoteByline()}} />
            </ReadMore>
          </div>
          <ShareButtons path={path} fb={fbMessage} tw={twMessage} native={nativeMessage} />
          </div>
      </div>
    )
  }

  const description = composition.has_video ? "Watch the Saint Paul Chamber Orchestra's performance" : "Listen to the Saint Paul Chamber Orchestra's performance"
  const ogType = composition.has_video ? 'video.other' : 'music.song'
  return (
    <React.Fragment>
      <Meta hide={!hasDetail} title={fullTitle} image={composition.preferred_image} description={description} type={ogType} />
      <PageHeader section="compositions">
        <div className="row composition_show">
          <div className="columns small-12">
            <header className="image_header composition">
              <div className="page_label">Composition</div>
              {compositionHeader}
            </header>
          </div>
        </div>
      </PageHeader>
      <main>
        {performances}
      </main>
    </React.Fragment>
  )
}

Composition.propTypes = {
  fetchComplete: PropTypes.bool,
  hasDetail: PropTypes.bool,
  composition: PropTypes.object,
  flashMessage: PropTypes.func
}

Composition.contextTypes = {
  router: PropTypes.object
}

export default Composition