import {fk, attr} from "redux-orm"
import { SpcoModel } from './spco_model'
import { getImageSizeInstance } from '../util'

export class Composition extends SpcoModel {
  toString() {
    return `Composition: ${this.name}`;
  }
  programNote() {
    return this.primary_program_note ? this.primary_program_note.text : ''
  }
  programNoteByline() {
    return this.primary_program_note ? this.primary_program_note.formatted_credit_html : ''
  }
  getImage(size) {
    if(this.preferred_image) {
      const image = getImageSizeInstance(this.preferred_image, size)
      return image.url
    }
  }
  forListDisplay() {
    return ! this.unlisted && this.forDisplay
  }

}

Composition.modelName = 'Composition';

// Declare your related fields.
Composition.fields = {
    id: attr(),
    identifier: attr(),
    title: attr(),
    alternative_formatted_title: attr(),
    opus: attr(),
    combined_composer_arranger_credit: attr(),
    spco_commission: attr(),
    country: attr(),
    preferred_image: attr(),
    hasDetail: attr(),
    forDisplay: attr({ getDefault: () => true }),
    unlisted: attr({ getDefault: () => false }),
    composer: fk('Person', 'compositions'),
    arranger: fk('Person', 'arranged_compositions'),
};
