/*eslint react/prop-types: "off"*/

import React, { Component } from 'react'
import { connect } from 'react-redux'
import LiveStreams from '../components/live_streams/LiveStreams'
import orm from '../models/orm'
import { fetchLiveStreamsIfNeeded, MEDIA_TYPE_LIVE_STREAM } from '../actions/media_type_fetch'

// Since there's no onChange in Router v4
// cf https://github.com/ReactTraining/react-router/issues/3854
class LiveStreamFetcher extends Component {

  componentDidMount() {
    if(!this.props.fetchedStreams) {
      this.props.fetchStreamsIfNeeded()
    }
  }

  render () {
    return <LiveStreams {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchComplete = state.data.isCompleteCombined
  const fetchedStreams = state.data.fetchedMediaType[MEDIA_TYPE_LIVE_STREAM]
  let streams = null
  if(fetchComplete && fetchedStreams) {
    streams = orm.session(state.data.orm)
                .LiveStream.all().filter((stream) => stream.status !== 'U' && ! stream.is_sample_stream)
                .orderBy(['end_date_time'], ['asc'])
  }
  return {
    streams: streams,
    fetchComplete: fetchComplete,
    fetchedStreams: fetchedStreams,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchStreamsIfNeeded: () => {
      dispatch(fetchLiveStreamsIfNeeded())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamFetcher)