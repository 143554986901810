import React from 'react'
import PropTypes from 'prop-types'
import {formatMarkdown, formatInline, stripFormatting, setTitle, formatDuration, JW_PLAYER_SRC, getImageSizeInstance } from '../../util'
import { Link } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'
import SvgIcon from '../SvgIcon'
import playIcon from '../../../images/icons/24/play.svg'
import PageHeader from '../../containers/PageHeaderContainer'
import ImageSelector from '../ImageSelector'
import MediaPlayer from '../MediaPlayer'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import moment from 'moment'
import classnames from 'classnames'
import Meta from '../Meta'
import ShareButtons from '../ShareButtons'

class MediaFeature extends MediaPlayer {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if(! this.state.playing) {
      this.props.fetchStreamLinksIfNeeded(this.props.feature)
      this.props.pauseAudio()
      this.setState({
        playing: true
      })
    }
  }
  playerId() {
    return `perf-feature-${this.props.feature.id}`
  }

  // ({fetchCompleteCombined, fetchedFeatures, feature, flashMessage}, context)
  render() {
    let {fetchedFeatures, feature, flashMessage} = this.props
    let header, mediaPlayer

    if(! fetchedFeatures) {
      return <LoadingIndicator margin="70px" />;
    }
    else if(! feature) {
      flashMessage("Sorry, that media feature was not found in the library.")
      this.context.router.history.push(`/features`)
    }
    else {
      const title = stripFormatting(feature.title)
      setTitle(title)

      const path = `/feature/${this.props.feature.identifier}`
      const fbMessage = "Check out this Special Feature about The Saint Paul Chamber Orchestra! You can stream it for free."
      const twMessage = "Check out this #SpecialFeature about @thespco #NowStreaming for #Free"
      const nativeMessage = 'Check out this Special Feature about the SPCO!'

      const image = <ImageSelector imageList={feature.image} size="wd_512x288" />
      header = (
        <div className="row">
          <Meta title={title} description={null} image={feature.image} />
          <div className="image columns medium-4 small-12 show-for-medium">
            {image}
          </div>
          <div className="text columns medium-8 small-12">
            <h1><span dangerouslySetInnerHTML={{__html: formatInline(feature.title)}} /></h1>
            <div className="header_image show-for-small-only">
              {image}
            </div>
            <div className="program_note long_description">
              <div dangerouslySetInnerHTML={{__html: formatMarkdown(feature.description)}} />
            </div>
            <div className="row">
              <div className="columns">
                <div className="feature_date">
                  {moment(feature.date_time).format('MMMM D, YYYY')}
                </div>
              </div>
              <div className="columns shrink">
                <ShareButtons path={path} fb={fbMessage} tw={twMessage} native={nativeMessage} />
              </div>
            </div>
          </div>
        </div>
      )

      if (this.showPlayerByDefault && ! this.props.linksFetched) {
        this.props.fetchStreamLinksIfNeeded(this.props.feature)
        mediaPlayer = <LoadingIndicator margin="70px" />;
      }
      else if(this.props.linksFetched && (this.showPlayerByDefault || this.state.playing)) {

        let tracks = []
        let captionsNote
        const captionsUrl = this.props.feature.captionsUrl()
        if(captionsUrl) {
          tracks.push({
            file: captionsUrl,
            label: "English",
            kind: "captions",
            "default": true
          })
          captionsNote = <div className="captions_note">To enable or disable captions, use the <strong>CC</strong> button.</div>
        }

        const playerQueue = [{
          file: this.props.feature.stream_links.broadband_standard_dynamics,
          title: this.props.feature.title,
          tracks: tracks,
          _gaId: `F-${this.props.feature.uuid}`,
          _gaTitle: this.props.feature.title
        }]

        // Need a poster image in case of showing <video> by default
        if (this.showPlayerByDefault) {
          const image_obj = getImageSizeInstance(feature.image, "wd_512x288")
          if(image_obj) {
            playerQueue[0].image = image_obj.url
          }
        }

        const playerClass = feature.isVideo() ? 'video_player' : 'audio_only'
        mediaPlayer = (
            <div className={playerClass}>
              <ReactJWPlayer
                          playerId={this.playerId()}
                          isAutoPlay={!this.showPlayerByDefault}
                          aspectRatio="16:9"
                          gaCategory="Feature"
                          playerScript={JW_PLAYER_SRC}
                          onReady={this.onReady}
                          file=""
                          onPlayerLoaded={this.onPlayerLoaded}
                          onPlay={this.onPlay}
                          onEnterFullScreen={this.onEnterFullScreen}
                          onExitFullScreen={this.onExitFullScreen}
                          playlist={playerQueue} />
              {captionsNote}
            </div>
        )
      }
      else {
        const timeFormatted = formatDuration(moment.duration(feature.duration_ms))
        const playButton = (
          <div className="play_icon">
            <SvgIcon className="play" icon={playIcon} alt="Play" height="24" width="24" fill="#ffffff" />
          </div>
        )
        const watchWord = feature.isVideo() ? 'Watch' : 'Listen to'
        const playerClasses = classnames(
          {
            row: true,
            video_preview: true,
            audio_only: ! feature.isVideo()
          }
        )
        mediaPlayer = (
         <div className={playerClasses}>
            <div className="columns medium-6 small-12">
              <div className="video_thumbnail" onClick={this.onClick} >
                {feature.isVideo() && <ImageSelector imageList={feature.image} size="wd_512x288" />}
                <div className="overlay">
                  {playButton}
                </div>
              </div>
            </div>
            <div className="columns medium-6 small-12">
              <strong className="watch_link" onClick={this.onClick}>{watchWord} Feature</strong>
              <br/>
              <span className="time">{timeFormatted}</span>
          </div>
        </div>
        )
      }
    }
    return (
      <div>
        <PageHeader section="features">
          <div className="row composition_show">
            <div className="columns small-12">
              <header className="image_header feature">
                <div className="page_label breadcrumbs">
                  Feature
                  <span className="breadcrumb"> &gt; <Link to={`/features/${feature.firstCategory().identifier}`}>{feature.firstCategory().title}</Link></span>
                  {feature.hasSubcategories() && <span className="breadcrumb"> &gt; <Link to={`/features/${feature.firstCategory().identifier}/${feature.category_hierarchy[1].identifier}`}>{feature.category_hierarchy[1].title}</Link>
                  </span>}
                  </div>
                {header}
              </header>
            </div>
          </div>
        </PageHeader>
        <main>
          <div className="row">
            <div className="columns">
              <div className="composition_performance row collapse">
                <div className="sidebar columns medium-4 small-12 small-order-2 medium-order-1">
                  <div className="wrap">
                    <div className="feature_credits">
                      <div dangerouslySetInnerHTML={{__html: formatMarkdown(feature.production_credits, {breaks: true})}} />
                    </div>
                  </div>
                </div>
                <div className="main_container media_feature columns medium-8 small-12 small-order-1 medium-order-2">
                  <div className="media_feature_player">
                    {mediaPlayer}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    )
  }
}


MediaFeature.propTypes = {
  fetchCompleteCombined: PropTypes.bool,
  fetchedFeatures: PropTypes.bool,
  feature: PropTypes.object,
  flashMessage: PropTypes.func,
  fetchStreamLinksIfNeeded: PropTypes.func,
  pauseAudio: PropTypes.func,
  linksFetched: PropTypes.bool
}

MediaFeature.contextTypes = {
  router: PropTypes.object
}

export default MediaFeature