import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import { isHeadless } from '../../util'
import ImageSelector from '../ImageSelector'

let PersonRow = ({person}, context) => {
  const yearDisplay = person.yearDisplay()
  let info = yearDisplay ? [yearDisplay] : []
  if(person.country) {
    info.push(`${person.country}`)
  }
  const viewBy = context.router.route.match.params.viewBy
  const url = `/compositions/${viewBy}/${person.identifier}`
  let handleClick = () => {
    context.router.history.push(url)
  }

  const imageHeight = 78
  const offset = isHeadless() ? 2000 : 300
  return (
        <li className="person" onClick={handleClick}>
          <div className="image">
            <LazyLoad height={imageHeight} offset={offset} once>
              <ImageSelector imageList={person.preferred_image} size="wd_256x144" />
            </LazyLoad>
          </div>
          <div className="title">
            <Link to={url}>{person.first_name} {person.last_name}</Link>
            <span className="year">{info.join(', ')}</span>
          </div>
        </li>
  )
}

PersonRow.propTypes = {
  person: PropTypes.object,
  viewBy: PropTypes.string
}

PersonRow.contextTypes = {
  router: PropTypes.object
}

export default PersonRow