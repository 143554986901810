/*eslint react/prop-types: "off"*/

import React from 'react'
import { Field, reduxForm } from 'redux-form'
import InputField from '../form/InputField'
import { Link } from 'react-router-dom'
import SessionHeader from './SessionHeader'
import { required,email } from '../form/validations'
import {setTitle} from '../../util'

const ForgotPassword = ({error, handleSubmit, pristine, reset, submitting}) => {
  setTitle('Forgot your password?')

  return (
    <SessionHeader>
      <form onSubmit={handleSubmit} className="hide_labels">
        <p className="note">
          Forgot your password? No problem, enter your email and we’ll send a link you can use to set a new password.
        </p>
        <Field name="email" label="Email" type="email" autoFocus component={InputField} required="true" validate={[ required, email ]} />

        {error && <div className="callout alert"><p>{error}</p></div>}
        <div className="submit_section">
          <button type="submit" className="button large rounded strong" disabled={pristine || error || submitting}>
          Reset Password
        </button>
        </div>
      </form>

      <div className="extra_links text-center">
        <Link to="/sign-in">Sign In</Link>
      </div>
    </SessionHeader>
  )
}

// Decorate the form component
export default reduxForm({
  form: 'ForgotPassword' // a unique name for this form
})(ForgotPassword)

