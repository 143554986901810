/*eslint react/prop-types: "off"*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CuratedCollections from '../components/curated_collections/CuratedCollections'
import orm from '../models/orm'
import { fetchCuratedCollectionsIfNeeded, MEDIA_TYPE_CURATED_COLLECTION  } from '../actions/media_type_fetch'

// Since there's no onChange in Router v4
// cf https://github.com/ReactTraining/react-router/issues/3854
class CuratedCollectionFetch extends Component {
  componentDidMount() {
    if(!this.props.fetchedCollections) {
      this.props.fetchCuratedCollectionsIfNeeded()
    }
  }
  render () {
    if(! this.props.fetchedCollections || ! this.props.fetchCompleteCombined) {
      return <CuratedCollections title={this.props.title} fetchComplete={false} />
    }
    // Preventing re-render, probably not necessary
    else {
      return <CuratedCollections {...this.props} fetchComplete={true} />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const fetchCompleteCombined = state.data.isCompleteCombined
  const fetchedCollections = state.data.fetchedMediaType[MEDIA_TYPE_CURATED_COLLECTION]
  let collections

  if(fetchCompleteCombined && fetchedCollections) {
    collections = orm.session(state.data.orm)
                .CuratedCollection.all()
                .orderBy('sequence', 'title')
                .toModelArray()
  }

  return {
    collections: collections,
    fetchCompleteCombined: fetchCompleteCombined,
    fetchedCollections: fetchedCollections,
    viewAsTable: state.compositions.viewAsTable
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchCuratedCollectionsIfNeeded: () => {
      dispatch(fetchCuratedCollectionsIfNeeded())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedCollectionFetch)