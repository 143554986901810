/*eslint react/prop-types: "off"*/
/* eslint react/jsx-no-target-blank: 0 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import InputField from '../form/InputField'
import { Link } from 'react-router-dom'
import SessionHeader from './SessionHeader'
import { required, email, minLength6 } from '../form/validations'
// import FacebookLogin from 'react-facebook-login'
import OrLine from './OrLine'
import {setTitle, initAppleID} from '../../util'
import appleIcon from '../../../images/icons/apple_logo.svg'
import SvgIcon from '../SvgIcon'

const validate = values => {
  const errors = {}
  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = 'Does not match password'
  }
  return errors
}

class Register extends Component {
  constructor(props) {
    super()
    this.responseFacebook = this.responseFacebook.bind(this)
    this.onAppleIdSuccess = this.appleIdSuccess.bind(this)
    this.onAppleIdFailure = this.appleIdFailure.bind(this)
    this.onNativeAppleLoginClick = this.nativeAppleLogin.bind(this)
  }
  componentDidMount() {
    if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      initAppleID()
      document.addEventListener('AppleIDSignInOnSuccess', this.onAppleIdSuccess)
      document.addEventListener('AppleIDSignInOnFailure', this.onAppleIdFailure)
    }
  }
  componentWillUnmount() {
    if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      document.removeEventListener('AppleIDSignInOnSuccess', this.onAppleIdSuccess)
      document.removeEventListener('AppleIDSignInOnFailure', this.onAppleIdFailure)
    }
  }
  appleIdSuccess(event) {
    this.props.appleLogin(event.detail)
  }
  appleIdFailure(error) {
    // TODO
    if(error.detail && error.detail.error == 'popup_closed_by_user') {
      // ignore popup closed
    }
    else {
      alert('Error signing in with Apple')
    }
  }
  nativeAppleLogin() {
    window.spcoAppleLogin(this.onAppleIdSuccess);
  }
  responseFacebook(response) {
    this.props.facebookLogin(response)
  }
  render() {
    if(this.props.signedIn) {
      // Native app handles sign-in by removing it from the nav stack
      if(! process.env.REACT_APP_USE_NATIVE_PLAYER) {
      // Somehow, the reduxForm wrapper adds history to the props
        this.props.history.push(`/compositions`)
      }
      return false
    }
    setTitle("Sign up")

    let appleLogin;
    if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
      appleLogin = <button type="button" className="button" onClick={this.onNativeAppleLoginClick}><SvgIcon icon={appleIcon} alt="Apple" height="20" width="20" fill="#ffffff" /> Sign up with Apple</button>
    }
    else if(process.env.REACT_APP_ANDROID) {
      appleLogin = null
    }
    else {
      appleLogin = <button className="button strong apple_signin" onClick={() => window.AppleID.auth.signIn()}><SvgIcon icon={appleIcon} alt="Apple" height="20" width="20" fill="#ffffff" /> Sign up with Apple</button>
    }
    return (
        <SessionHeader>
            <div className="apple_login">{appleLogin}</div>
            <OrLine />

          <form onSubmit={this.props.handleSubmit} className="hide_labels">
            <Field name="first_name" label="First name" type="text" component={InputField} required="true" validate={[ required ]} />
            <Field name="last_name" label="Last name" type="text" component={InputField} required="true" validate={[ required ]} />

            <Field name="email" label="Email" type="email" component={InputField} required="true" validate={[ required, email ]} />
            <Field name="password" label="Choose a password" type="password" component={InputField} required="true" validate={[ required, minLength6 ]} />
            <Field name="password_confirmation" label="Confirm your password" type="password" component={InputField} required="true" validate={[ required ]} />

            <p className="note">
              Don’t miss a note! By signing up, you’ll be among the first to know when we add new concerts to the library. You can unsubscribe at any time.
              </p>

            {this.props.error && <div className="callout alert"><p>{this.props.error}</p></div>}
            <div className="submit_section">
              <button type="submit" className="button large rounded strong" disabled={this.props.pristine || this.props.submitting}>
              Register
            </button>
            </div>
          </form>

        <div className="extra_links text-center">
          Already registered? <Link to="/sign-in">Sign In</Link>
        </div>

        <div className="extra_links text-center">
            <p>Need a hand? <a className="small_link" href="https://www.thespco.org/concert-library-help/" target="_blank">Concert Library Help</a>
            </p>
        </div>
      </SessionHeader>
    )
  }
}

Register.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  signedIn: PropTypes.bool,
  history: PropTypes.object,
  facebookLogin: PropTypes.func,
  appleLogin: PropTypes.func
}

// Decorate the form component
export default reduxForm({
  form: 'Register',
  validate
})(Register)
