import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../containers/PageHeaderContainer'
import ViewByContainer from '../../containers/ViewByContainer'
import MediaFeatureNav from './MediaFeatureNav'
import MediaFeaturesList from './MediaFeaturesList'
import LoadingIndicator from '../LoadingIndicator'
import {setTitle, formatInline} from '../../util'

let MediaFeatures = ({fetchComplete, features, viewBy, subcategory, title, toplevelCategories, currentMenuItem, subcategories, viewAsTable}, context) => {
  setTitle(title)

  let featureList
  if( ! fetchComplete ) {
    featureList = <LoadingIndicator margin="70px" />
  }
  else {
    featureList = <MediaFeaturesList features={features} subcategories={subcategories} viewBy={viewBy} viewAsTable={viewAsTable} currentMenuItem={currentMenuItem} />
  }

  return (
    <div>
      <PageHeader section="features">
        <div className="row list_header programs">
          <div className="columns small-12">
            <header className="row list_header programs">
              <div className="columns medium-8">
                <h1 dangerouslySetInnerHTML={{__html: formatInline(title || '')}} />
              </div>
              <div className="view_tools columns medium-4">
                <ViewByContainer />
              </div>
            </header>

          <div className="row">
            <div className="columns">
              {fetchComplete && <MediaFeatureNav viewBy={viewBy} toplevelCategories={toplevelCategories} currentMenuItem={currentMenuItem} />}
            </div>
          </div>
          </div>
         </div>

      </PageHeader>

      <main>
        <div className="row collapse">
          <div className="columns">
            {featureList}
          </div>
        </div>
      </main>
    </div>
  )
}

MediaFeatures.propTypes = {
  fetchComplete: PropTypes.bool,
  features: PropTypes.array,
  viewBy: PropTypes.string,
  subcategory: PropTypes.string,
  title: PropTypes.string,
  toplevelCategories: PropTypes.object,
  currentMenuItem: PropTypes.object,
  subcategories: PropTypes.array,
  viewAsTable: PropTypes.bool
}

MediaFeatures.contextTypes = {
  router: PropTypes.object
}

export default MediaFeatures