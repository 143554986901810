function onTime(event) {
  const { hasFired } = this.state;
  const { position, duration } = event;
  let hasChanged = false;

  if(this.props.gaInterval) {
    // Not sure if position resets on play/pause of livestream;
    // checking < to be sure
    if ( ! hasFired.lastInterval || parseInt(position) < hasFired.lastInterval ) {
      hasFired.lastInterval = parseInt(position)
      hasChanged = true
    }
    else if( parseInt(position) - hasFired.lastInterval >= 600) { // 600 = 10 min
      hasFired.lastInterval = parseInt(position)
      hasChanged = true
      this.fireGa('10min')
    }
  }

  // if (hasFired.position !== parseInt(position) ) {
  //   this.props.onPositionChange(parseInt(position));
  //   hasFired.position = parseInt(position);
  //   hasChanged = true;
  // }

  // if (!hasFired.threeSeconds && position > 3) {
  //   this.props.onThreeSeconds();
  //   hasFired.threeSeconds = true;
  //   hasChanged = true;
  // }
  //
  if (!hasFired.tenSeconds && position > 10) {
    hasFired.tenSeconds = true;
    this.fireGa('Play')
    this.props.onTenSeconds();
    hasChanged = true;
  }
  //
  // if (!hasFired.thirtySeconds && position > 30) {
  //   this.props.onThirtySeconds();
  //   hasFired.thirtySeconds = true;
  //   hasChanged = true;
  // }
  //

  if (!hasFired.twentyFivePercent && ((position / duration) * 100) > 25) {
    this.props.onTwentyFivePercent();
    this.fireGa('25%');
    hasFired.twentyFivePercent = true;
    hasChanged = true;
  }

  if (!hasFired.fiftyPercent && ((position / duration) * 100) > 50) {
    this.props.onFiftyPercent();
    this.fireGa('50%')
    hasFired.fiftyPercent = true;
    hasChanged = true;
  }

  if (!hasFired.seventyFivePercent && ((position / duration) * 100) > 75) {
    this.props.onSeventyFivePercent();
    this.fireGa('75%')
    hasFired.seventyFivePercent = true;
    hasChanged = true;
  }
  //
  // if (!hasFired.ninetyFivePercent && ((position / duration) * 100) > 95) {
  //   this.props.onNinetyFivePercent();
  //   hasFired.ninetyFivePercent = true;
  //   hasChanged = true;
  // }

  if (hasChanged) {
    this.setState({
      hasFired,
    });
  }
}

export default onTime;
