import { connect } from 'react-redux'
import { signOutAction } from '../actions/user'
import UserMenu from '../components/UserMenu'

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.user.currentUser,
  mobile: ownProps.mobile,
  onClose: ownProps.onClose
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signOut: (userInfo) => {
      return dispatch(signOutAction())
    }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu)
