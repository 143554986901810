import React from 'react'
import PropTypes from 'prop-types'

let MovementDisplay = ({movement, index}) => {

  let title = movement.title ? movement.title : 'Entire Recording'

  return (
    <li>
      <strong>{title}</strong>
    <span className="duration"> {movement.duration_formatted}</span>
    </li>
  )
}

MovementDisplay.propTypes = {
  movement: PropTypes.object,
  index: PropTypes.number,
  playMovement: PropTypes.func,
  performance: PropTypes.object,
  composition: PropTypes.object
}

export default MovementDisplay