import { connect } from 'react-redux'
import MovementDisplay from '../components/composition/MovementDisplay'

const mapStateToProps = (state, ownProps) => {
  return {
    movement: ownProps.movement,
    index: ownProps.index,
    performance: ownProps.performance
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    playMovement: () => {
      // no-op for now
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MovementDisplay)