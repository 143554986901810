import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../containers/PageHeaderContainer'
import ViewByContainer from '../../containers/ViewByContainer'
import CuratedCollectionsList from './CuratedCollectionsList'
import LoadingIndicator from '../LoadingIndicator'
import {setTitle} from '../../util'

let CuratedCollections = ({fetchComplete, collections, viewAsTable}, context) => {
  setTitle('Curated Collections')

  let collectionsList
  if( ! fetchComplete ) {
    collectionsList = <LoadingIndicator margin="70px" />
  }
  else {
    collectionsList = <CuratedCollectionsList collections={collections} viewAsTable={viewAsTable} />
  }

  return (
    <div>
      <PageHeader section="collections">
        <div className="row list_header programs">
          <div className="columns small-12">
            <header className="row list_header programs">
              <div className="columns medium-8">
                <h1>Curated Collections</h1>
              </div>
              <div className="view_tools columns medium-4">
                <ViewByContainer />
              </div>
            </header>
          </div>
         </div>

      </PageHeader>

      <main>
        <div className="row collapse">
          <div className="columns">
            {collectionsList}
          </div>
        </div>
      </main>
    </div>
  )
}

CuratedCollections.propTypes = {
  fetchComplete: PropTypes.bool,
  collections: PropTypes.array,
  viewAsTable: PropTypes.bool
}

CuratedCollections.contextTypes = {
  router: PropTypes.object
}

export default CuratedCollections