import React from 'react'
import PropTypes from 'prop-types'
import PersonRow from './PersonRow'
import classnames from 'classnames'

let PersonList = ({people, viewAsTable}) => {

  const peopleItems = people.toModelArray().map(person =>
          <PersonRow person={person} key={person.id} />
      )
  const listClasses = classnames(
    {
      person_listing: true,
      grid_view: ! viewAsTable,
      table_view: viewAsTable
    }
  )
  return (
    <ul className={listClasses}>{peopleItems}</ul>
  )
}

PersonList.propTypes = {
  people: PropTypes.object,
  viewAsTable: PropTypes.bool
}

export default PersonList