import { fetchJwtOptions } from '../util'
import { getAccessToken } from './user'
import { handleStreamLinksFailure } from './player'

export function fetchStreamLinksIfNeeded(streamRecord) {
  return (dispatch, getState) => {
    if (! streamRecord.fetched_streaming_links || (streamRecord.fetched_streaming_links === 'user' && !!getState().user.currentUser)) {
      return dispatch(fetchObjectStreamLinks(streamRecord))
    } else {
      return Promise.resolve()
    }
  }
}

function fetchObjectStreamLinks(streamRecord) {
  return (dispatch) => {
    const uuid = streamRecord.uuid
    let stream_api_base
    if(streamRecord.getClass().modelName == 'LiveStream' && streamRecord.isComplete() && streamRecord.can_replay) {
      stream_api_base = 'livestream-replay'
    }
    else {
      stream_api_base = streamRecord.getClass().modelName.toLowerCase()
    }
    return dispatch(getAccessToken())
    .then( (user_token) => {
      fetch(`${process.env.REACT_APP_API_BASE}/stream/${stream_api_base}/${uuid}/?format=json`, fetchJwtOptions(user_token))
      .then(response => {
        return response.json().then(json => {
          if(response.ok) {
            return dispatch(receiveObjectStreamLinks(json, streamRecord))
          }
          else {
            return handleStreamLinksFailure(response, json, dispatch, streamRecord, 'streaming_links')
          }
        })
      })
      .catch(error => {
        if(error.name !== 'HandledError') {
          alert("Sorry, there was a problem contacting our servers! Try back in a few minutes.")
        }
      })
      }
    )
  }
}


export const RECEIVE_OBJECT_STREAM_LINKS = 'RECEIVE_OBJECT_STREAM_LINKS'
function receiveObjectStreamLinks(json, streamRecord) {
  return {
    type: RECEIVE_OBJECT_STREAM_LINKS,
    json: json,
    parent_record: streamRecord
  }
}