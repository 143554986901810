import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const InputField = ({input, label, type, meta: {touched, error, warning}}) => {
  const inputClasses = classnames(
    {
      input: true,
      [type]: type,
      with_errors: touched && error
    }
  )
  return (
    <div className={inputClasses}>
      {type !== 'checkbox' &&
        <label htmlFor={input.name}>{label}</label>
      }
      <input {...input} placeholder={label} type={type} />
      {type === 'checkbox' &&
        <label htmlFor={input.name}>{label}</label>
      }
      {touched &&
        ((error && <span className="error">{error}</span>) ||
        (warning && <span className="warning">{warning}</span>))}
    </div>
  )
}
InputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object
}
export default InputField
