
export const timerMiddleware = store => next => action => {
  let timers = {}
  if(action.type === "START_TIMER") {
      const {timerAction, timerName, timerInterval, timerOnce} = action.payload;
      if(timerOnce) {
        setTimeout( () => {
          store.dispatch({type : timerAction })
        }, timerOnce || 1000);
      }
      else {
        clearInterval(timers[timerName]);

        timers[timerName] = setInterval( () => {
            store.dispatch({type : timerAction })
        }, timerInterval || 1000);
      }
  }
  else if(action.type === "STOP_TIMER") {
      const {timerName} = action.payload;

      clearInterval(timers[timerName]);
  }
  else {
      return next(action);
  }
}