import { connect } from 'react-redux'
import ProgramList from '../components/programs/ProgramList'
import orm from '../models/orm'

const programHasPlayable = (program) => {
  return program.hasPlayablePerformance()
}

const programs = (viewBy, orm_state) => {
  if(viewBy === 'video') {
    return orm.session(orm_state).Program.all()
    .filter({unlisted: false, has_video: true})
    .orderBy(['end_date_time'], 'desc')
    .toModelArray()
  }
  else if(viewBy === 'audio') {
    return orm.session(orm_state).Program.all()
    .filter({unlisted: false, has_audio: true})
    .orderBy(['end_date_time'], 'desc')
    .toModelArray()
  }
  else {
    return orm.session(orm_state).Program.all()
    .filter({unlisted: false})
    .orderBy(['end_date_time'], 'desc')
    .toModelArray()
    .filter(programHasPlayable)
  }
}
const mapStateToProps = (state, ownProps) => ({
  programs: state.data.isCompleteCombined ? programs(ownProps.viewBy, state.data.orm) : [],
  fetchComplete: state.data.isCompleteCombined,
  viewBy: ownProps.viewBy || 'all',
  viewAsTable: state.compositions.viewAsTable
})

const ProgramListContainer = connect(
  mapStateToProps,
  null
)(ProgramList)

export default ProgramListContainer