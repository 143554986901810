/*eslint no-case-declarations: "off"*/

import {
  PLAY_NOW, ADD_TO_QUEUE, PAUSE_EVENT, RESUME_EVENT, NEXT_EVENT, PREV_EVENT, PLAYER_PLAYED_EVENT,
  SET_QUEUE_ITEM_EVENT, ITEM_FINISHED_EVENT, LOAD_QUEUE, STORAGE_KEY_QUEUE, STORAGE_KEY_CURRENT,
  REMOVE_FROM_QUEUE, PLAYER_FINISHED_EVENT
} from '../actions/player'

import { setStorage } from '../util'

// TODO: maybe this could happen in middleware, saving when queue/item change?
function saveQueue(opts) {
  if(opts.queue) {
    setStorage(STORAGE_KEY_QUEUE, opts.queue)
  }
  if(opts.currentItem) {
    setStorage(STORAGE_KEY_CURRENT, opts.currentItem)
  }
}

const playerStatus = (state = {
  status: 'paused',
  playerQueue: [],
  currentItem: 0,
  finishedAt: null
}, action) => {
  switch (action.type) {
    case PLAY_NOW:
    case ADD_TO_QUEUE:
      const playlistItem = {
        composition_id: action.composition.id,
        performance_id: action.performance.id,
        composition_identifier: action.composition.identifier,
        stream_links: action.performance.stream_links
      }
      const playerQueue = [...state.playerQueue, playlistItem]

      if(action.type === PLAY_NOW) {
        const currentItem = playerQueue.length - 1
        saveQueue({queue: playerQueue, currentItem: currentItem})
        return Object.assign({}, state, {
          status: 'playing',
          playerQueue: playerQueue,
          currentItem: currentItem,
          finishedAt: null
        })
      }
      else {
        saveQueue({queue: playerQueue} )
        // currentItem handles "add to queue" workaround for Safari / autoplay
        return Object.assign({}, state, {
          playerQueue: playerQueue,
          currentItem: playerQueue.length === 1 ? 0 : state.currentItem,
          finishedAt: null
        })
      }
    case REMOVE_FROM_QUEUE:
      // Ignore if item is outside queue length
      if(state.playerQueue.length >= action.itemIndex + 1) {
        let newCurrentItem = state.currentItem
        if(state.currentItem !== 0 && state.currentItem >= action.itemIndex) {
          newCurrentItem = state.currentItem - 1
        }

        // AKA splice, not mutating the array
        const newQueue = [
          ...state.playerQueue.slice(0, action.itemIndex),
          ...state.playerQueue.slice(action.itemIndex + 1)
        ]
        saveQueue({queue: newQueue, currentItem: newCurrentItem})
        const setStatus = action.pause ? 'paused' : state.status
        return Object.assign({}, state, {
          playerQueue: newQueue,
          currentItem: newCurrentItem,
          status: setStatus
        })
      }
      else {
        return state
      }
    case LOAD_QUEUE:
      const savedItem = Math.min(action.savedItem, action.savedQueue.length - 1)
      return Object.assign({}, state, {
        playerQueue: action.savedQueue,
        currentItem: savedItem
      })
    case PAUSE_EVENT:
      return Object.assign({}, state, {
        status: 'paused'
      })
    case RESUME_EVENT:
      return Object.assign({}, state, {
        status: 'playing'
      })
    case PLAYER_PLAYED_EVENT:
      // EG when clicking on timeline
      return Object.assign({}, state, {
        status: 'playing',
        finishedAt: null
      })
    case NEXT_EVENT:
      let next = state.currentItem + 1
      next = Math.min(next, state.playerQueue.length - 1)
      saveQueue({currentItem: next})
      return Object.assign({}, state, {
        currentItem: next
      })
    case ITEM_FINISHED_EVENT:
      let nextOrEnd = state.currentItem + 1
      if(nextOrEnd > state.playerQueue.length - 1) {
        return Object.assign({}, state, {
          status: 'paused',
          finishedAt: new Date()
        })
      }
      saveQueue({currentItem: nextOrEnd})
      return Object.assign({}, state, {
        currentItem: nextOrEnd
      })
    case PLAYER_FINISHED_EVENT:
      return Object.assign({}, state, {
        status: 'paused',
        finishedAt: null // Stop trying to stop runaway native player
      })
    case PREV_EVENT:
      let prev = state.currentItem - 1
      prev = Math.max(prev, 0)
      saveQueue({currentItem: prev})
      return Object.assign({}, state, {
        currentItem: prev
      })
    case SET_QUEUE_ITEM_EVENT:
      let newCurrent = action.itemIndex
      newCurrent = Math.min(newCurrent, state.playerQueue.length - 1)
      newCurrent = Math.max(newCurrent, 0)

      saveQueue({currentItem: newCurrent})
      return Object.assign({}, state, {
        currentItem: newCurrent,
        status: 'playing'
      })
    default:
      return state
  }
}

export default playerStatus
