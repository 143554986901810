import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { flashMessageType } from 'redux-flash'
class FlashMessage extends Component {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
    this.state = {
      isHidden: false
    }
  }
  onClick() {
    this.setState({isHidden: true})
    this.props.clearFlash()
  }

  render() {
    if(! this.props.flash || this.state.isHidden) {
      return null
    }
    else {
      return (
            <div className="flash_wrapper" onClick={this.onClick}>
              <div className="flash">{ this.props.flash.message }</div>
            </div>
          )
    }
  }
}

FlashMessage.propTypes = {
    clearFlash: PropTypes.func,
    flash: flashMessageType
}

export default FlashMessage