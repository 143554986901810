import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FlashMessageContainer from '../containers/FlashMessageContainer'
import SvgIcon from './SvgIcon'
import spco from '../../images/icons/SPCO.svg'
import concert_library from '../../images/icons/concert_library.svg'
import UserMenuContainer from '../containers/UserMenuContainer'
import Menu from 'react-burger-menu/lib/menus/slide'

class PageHeader extends Component {
  constructor() {
    super()
    this.closeMenu = this.closeMenu.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.onHomeHover = this.onHomeHover.bind(this)
    this.onHomeLeave = this.onHomeLeave.bind(this)
    this.onSPCOHover = this.onSPCOHover.bind(this)
    this.onSPCOLeave = this.onSPCOLeave.bind(this)
    this.menuToggled = this.menuToggled.bind(this)
    this.state = {
      isOpen: false,
      homeHover: false,
      spcoHover: false
    }
    if(process.env.REACT_APP_USE_NATIVE_PLAYER || process.env.REACT_APP_ANDROID) {
      // Gives native UI ability to control menu
      window.spcoToggleSidebar  = this.toggleMenu
    }
  }
  menuToggled(newState) {
    this.setState({ isOpen: newState.isOpen })
    if(process.env.REACT_APP_USE_NATIVE_PLAYER) {
      // Native app uses this to handle navigation
      window.webkit.messageHandlers.pageTitle.postMessage({
        url: 'menuChange',
        menuIsOpen: newState.isOpen
      });
    }
    if(process.env.REACT_APP_ANDROID) {
      window.androidNav.onDrawerChange(newState.isOpen)
    }
  }
  closeMenu() {
    this.setState({ isOpen: false })
  }
  toggleMenu() {
    let newState = !this.state.isOpen
    this.setState({ isOpen: newState })
    return newState
  }

  onHomeHover() { this.setState({ homeHover: true }) }
  onHomeLeave() { this.setState({ homeHover: false}) }
  onSPCOHover() { this.setState({ spcoHover: true }) }
  onSPCOLeave() { this.setState({ spcoHover: false}) }

  render() {
    const sectionClass = this.props.section || null
    const homeFill = this.state.homeHover ? '#d83b20' : '#ffffff'
    const spcoFill = this.state.spcoHover ? '#d83b20' : '#ffffff'
    const mainNav = <ul id="main_nav" className={sectionClass}>
                    <li className="live_streams"><Link to="/live-streams"><i className="play_circle"></i>Live Streams</Link></li>
                    <li className="compositions"><Link to="/compositions">Compositions</Link></li>
                    <li className="programs"><Link to="/concerts">Concerts</Link></li>
                    <li className="features"><Link to="/features">Features</Link></li>
                    <li className="collections"><Link to="/collections">Curated Collections</Link></li>
                  </ul>

    return (
      <div className="page_top">
        <div className="row">
          <div className="columns">
            <FlashMessageContainer />
            <header className="row page_header">
                <nav className="columns">
                  <ul className="home_links">
                    <li className="spco_link">
                      <a href="https://www.thespco.org" onMouseEnter={this.onSPCOHover} onMouseLeave={this.onSPCOLeave} onTouchEnd={this.onSPCOLeave}><SvgIcon icon={spco} title="SPCO" fill={spcoFill} /></a>
                    </li>
                    <li className="home_link">
                      <Link to="/" onMouseEnter={this.onHomeHover} onMouseLeave={this.onHomeLeave} onTouchEnd={this.onHomeLeave}>
                        <SvgIcon icon={concert_library} title="Concert Library" fill={homeFill} />
                      </Link>
                    </li>
                  </ul>
                  {mainNav}
                </nav>
                <div className="columns shrink text-right user_menu">
                  <UserMenuContainer />
                </div>
                <Menu right isOpen={this.state.isOpen} onStateChange={this.menuToggled}>
                  {mainNav}
                  <UserMenuContainer mobile={true} onClose={this.closeMenu} />
                </Menu>
            </header>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

PageHeader.propTypes = {
  section: PropTypes.string,
  children: PropTypes.node,
}

export default PageHeader