import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popover from 'react-popover'
import QueueList from './QueueList'
import playlist from '../../../images/icons/24/menu.svg'
import SvgIcon from '../SvgIcon'
import { getImageSizeInstance } from '../../util'

class PlayerMeta extends Component {
  constructor() {
    super()
    this.toggle = this.toggle.bind(this)
    this.onOuterAction = this.onOuterAction.bind(this)
    this.state = {
     isOpen: false
    }
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onOuterAction() {
    this.setState({ isOpen: false })
  }

  render() {
    const { fullQueue, currentItem } = this.props
    if(fullQueue.length === 0) {
      return <div className="player_meta"></div>
    }
    const item = fullQueue[currentItem]
    const title = item.composition.alternative_formatted_title
    const composer = item.composition.composer.full_name

    const imageInstance = item.composition.preferred_image ? getImageSizeInstance(item.composition.preferred_image, "wd_thumbnail") : null
    const currentImage = imageInstance ? <div className="image"><img src={imageInstance.url} alt="" /></div> : <div></div>

    const queueList = <QueueList fullQueue={fullQueue} currentItem={currentItem} onOuterAction={this.onOuterAction} setQueueItem={this.props.setQueueItem} removeQueueItem={this.props.removeQueueItem} />
    return (
      <div className="player_meta">

        <Popover isOpen={this.state.isOpen} onOuterAction={this.onOuterAction} body={queueList} className="player_meta_popover" >
        <div className="queue_toggle" onClick={this.toggle}>
          <SvgIcon icon={playlist} fill={ '#d83b20' } height="24" width="24" />
        </div>
      </Popover>
        <div className="track_info">
          {currentImage}
          <div className="title">
            <h5>{title}</h5>
            <div className="composer">{composer}</div>
          </div>
        </div>
      </div>
    )
  }
}


PlayerMeta.propTypes = {
  fullQueue: PropTypes.array,
  currentItem: PropTypes.number,
  status: PropTypes.string,
  setQueueItem: PropTypes.func,
  removeQueueItem: PropTypes.func
}

export default PlayerMeta