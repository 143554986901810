import { SpcoModel } from './spco_model'
import { addTimeZoneIfNecessary } from '../util'
import moment from 'moment-timezone'

export class LiveStream extends SpcoModel {
  toString() {
    return this.title
  }
  // U unlisted
  // L listed
  // I progress
  // C completed
  isLive() {
    return this.status === 'I' || this.isSample()
  }
  isComplete() {
    return this.status === 'C'
  }
  isUnlisted() {
    return this.status === 'U'
  }
  isSample() {
    return this.is_sample_stream
  }
  startMoment() {
    return moment(this.start_date_time)
  }
  addTimeZone() {
    let start = moment(this.start_date_time)
    return addTimeZoneIfNecessary(start)
  }
  replayEndAddtimeZone() {
    return addTimeZoneIfNecessary(this.replayEndMoment())
  }
  replayEndMoment() {
    let endTime = this.replay_end_time ? moment(this.replay_end_time) : this.startMoment().add(48, 'hours')
    return endTime
  }
  replayAvailable() {
    return this.can_replay && this.replayEndMoment() > moment()
  }
  startDisplay() {
    let start = moment(this.start_date_time)
    const addTimeZone = this.addTimeZone()

    if(start.isSame(moment(), 'day')) {
      if(start.hour() >= 17) {
        return start.format("[tonight at] h:mm a") + addTimeZone
      }
      else {
        return start.format("[today at] h:mm a") + addTimeZone
      }
    }
    else {
      return start.format("dddd, MMMM D, [at] h:mm a") + addTimeZone
    }
  }
  bannerDisplayStart() {
    return this.banner_start_date_time ? moment(this.banner_start_date_time) : this.startMoment().subtract(7, 'days')
  }

  openMoment() {
    return moment(this.open_date_time || this.start_date_time)
  }
  checkLiveTime() {
    if(! this.isLive() && ! this.isComplete() && ! this.isSample() && this.openMoment() <= moment()) {
      this.update({status: 'I'})
    }
  }
}

LiveStream.modelName = 'LiveStream'
LiveStream.fields = {

}