import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Clamp from 'react-multiline-clamp'

class ReadMore extends Component {
    constructor(...args) {
        super(...args);
    }


    render() {
        const {
            children,
            more,
            less,
            lines
        } = this.props;


        // eslint-disable-next-line
        return (
            <div>
                <Clamp
                    lines={lines}
                    maxLines={200}
                    withToggle
                    showMoreElement={({toggle}) => (
                        <span className="read_more more"><a onClick={toggle}>{more}</a></span>
                    )}
                    showLessElement={({toggle}) => (
                        <span className="read_more less"> <a onClick={toggle}>{less}</a></span>
                    )}
                >
                    {children}
                </Clamp>

            </div>
        );
    }
}

ReadMore.defaultProps = {
    lines: 3,
    more: 'More',
    less: 'Show less'
};

ReadMore.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.node,
    lines: PropTypes.number,
    more: PropTypes.string,
    less: PropTypes.string
};

export default ReadMore;