import React from 'react'
import PropTypes from 'prop-types'
import CompositionNav from './CompositionNav'
import CompositionsHeader from './CompositionsHeader'
import CompositionListContainer from '../../containers/CompositionListContainer'
import PersonListContainer from '../../containers/PersonListContainer'
import GroupListContainer from '../../containers/GroupListContainer'
import PageHeader from '../../containers/PageHeaderContainer'
import LoadingIndicator from '../LoadingIndicator'
import {setTitle} from '../../util'

let Compositions = ({fetchComplete, viewBy, viewAsTable, title}) => {
  setTitle(title)
  const mainListing = (() => {
    if(! fetchComplete) {
      return <LoadingIndicator margin="70px" />;
    }
    switch (viewBy) {
    case 'composer':
    case 'performer':
    case 'conductor':
      return <PersonListContainer viewBy={viewBy} />
    case 'genre':
      return <GroupListContainer viewBy={viewBy} />
    case 'nationality':
      return <GroupListContainer viewBy={viewBy} />
    case 'spco-premiere':
    case 'video':
    case 'all':
    default:
      return <CompositionListContainer viewBy={viewBy} />
    }
  })()

  return (
    <div>
      <PageHeader section="compositions">
        <div className="row">
          <div className="columns">
            <CompositionsHeader title={title} />
            <CompositionNav viewBy={viewBy} />
          </div>
        </div>
      </PageHeader>
      <div className="row">
        <div className="columns small-12">
          <main>{mainListing}</main>
        </div>
      </div>
    </div>
  )
}

Compositions.propTypes = {
  fetchComplete: PropTypes.bool,
  viewBy: PropTypes.string,
  title: PropTypes.string,
  viewAsTable: PropTypes.bool,
  setViewAsTable: PropTypes.func,
  setViewAsGrid: PropTypes.func
}

export default Compositions