/*eslint no-fallthrough: "off"*/
/*eslint no-case-declarations: "off"*/

import {
  LOAD_USER,
  USER_REGISTER_SUCCESS,
  CHECK_REFRESH_EXPIRY,
  LOCATION_HISTORY,
  UPDATE_ACCESS_TOKEN
} from '../actions/user'

const user = (state = {
  currentUser: null,
  locationHistory: []
}, action) => {
  switch (action.type) {
    case LOAD_USER:
      return Object.assign({}, state, {
        currentUser: action.userInfo
      })
    case USER_REGISTER_SUCCESS:
      return state
    case UPDATE_ACCESS_TOKEN:
      const newUser = Object.assign({}, state.currentUser, {
        token: action.accessToken,
        expires: action.expires
      })
      return Object.assign({}, state, {
        currentUser: newUser
      })
    case LOCATION_HISTORY:
      // Keeping a local location history for handling
      // Going back after a login in handleSigninRedirect
      let newHistory = state.locationHistory.slice()
      // prevent dupes
      if(newHistory[newHistory.length - 1] !== action.url) {
        newHistory.push(action.url)
      }
      if(newHistory.length > 3) {
        newHistory.shift()
      }
      return Object.assign({}, state, {
        locationHistory: newHistory
      })
    case CHECK_REFRESH_EXPIRY:
      if(state.currentUser) {
        const refreshExpiresIn = parseInt(state.currentUser.refreshExpires - Date.now() / 1000)
        if(refreshExpiresIn <= 0) {
          // Clear user, even if other token isn't expired... it's about to go
          return Object.assign({}, state, {
            currentUser: null
          })
        }
      }
    default:
      return state
  }
}

export default user
