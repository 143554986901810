import { connect } from 'react-redux'
import { deleteUser } from '../actions/user'
import DeleteUser from '../components/user/DeleteUser'

const mapStateToProps = (state, ownProps) => ({
    signedIn: !! state.user.currentUser,
    currentUser: state.user.currentUser,
  }
)


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: () => {
      dispatch(deleteUser())
      return false
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteUser)
