import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ImageSelector from '../ImageSelector'

let CuratedCollectionsList = ({collections, viewAsTable}, context) => {
  let ignoreClick = (e) => {
    e.stopPropagation()
  }

  let items = collections.map( (collection) => {
    let url = `/collections/${collection.identifier}`
    let handleClick = () => {
      context.router.history.push(url)
    }

    return (
      <li key={collection.id} onClick={handleClick}>
        <div className="image">
          <ImageSelector imageList={collection.image} size="wd_256x144" />
        </div>
        <div className="title">
          <h4><Link to={url} onClick={ignoreClick}>{collection.title}</Link></h4>
          <div className="composer">{collection.byline}</div>
        </div>
      </li>
    )
  })

  const listClass = viewAsTable ? 'curated_list image_list' : 'curated_list image_grid'
  return <ul className={listClass}>{items}</ul>
}

CuratedCollectionsList.propTypes = {
  viewBy: PropTypes.string,
  collections: PropTypes.array,
  currentMenuItem: PropTypes.object,
  viewAsTable: PropTypes.bool
}

CuratedCollectionsList.contextTypes = {
  router: PropTypes.object
}

export default CuratedCollectionsList