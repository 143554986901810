/*eslint react/prop-types: "off"*/

/*
Program display
*/
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Program from '../components/programs/Program'
import orm from '../models/orm'
import { fetchProgramDetailIfNeeded } from '../actions/fetch_program'
import { flashMessage } from 'redux-flash'
import PropTypes from 'prop-types'

class ProgramFetcher extends Component {
  componentDidMount() {
    if(! this.props.programFetched) {
      this.props.fetchProgramIfNeeded()
    }
  }
  render () {
    return <Program {...this.props} />
  }
}

ProgramFetcher.propTypes = {
  program: PropTypes.object,
  relatedMediaFeatures: PropTypes.array,
  fetchComplete: PropTypes.bool,
  programFetched: PropTypes.bool,
  audioOnly: PropTypes.bool,
  fetchProgramIfNeeded: PropTypes.func,
  flashMessage: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  const fetchComplete = state.data.isCompleteCombined
  let program, audioOnly, relatedMediaFeatures

  if(fetchComplete) {
    const { Program, RelatedMediaFeature } = orm.session(state.data.orm)
    program = Program.findByIdentifier(ownProps.match.params.identifier)
    relatedMediaFeatures = program ? RelatedMediaFeature.filter(rmf => rmf.program == program.id).toModelArray() : null
    audioOnly = program ? program.performances.orderBy('position').toModelArray().some((performance) => performance.audioRecording()) : false
  }
  return {
    program: program,
    relatedMediaFeatures: relatedMediaFeatures,
    fetchComplete: fetchComplete,
    audioOnly: audioOnly,
    programFetched: fetchComplete && program && program.hasDetail
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchProgramIfNeeded: () => {
      dispatch(fetchProgramDetailIfNeeded(ownProps.match.params.identifier))
    },
    flashMessage: (message) => {
      dispatch(flashMessage(message, {push: true, timeout: 5000}))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ProgramFetcher)