import React from 'react'
import PropTypes from 'prop-types'
import mpr from '../../../images/icons/classical_mpr.svg'
import play from '../../../images/icons/24/play.svg'
import playlistAdd from '../../../images/icons/16/playlist-add.svg'
import SvgIcon from '../SvgIcon'
import ReadMore from '../ReadMore'
import MovementContainer from '../../containers/MovementContainer'
import CompositionVideoContainer from '../../containers/CompositionVideoContainer'
import PersonLink from './PersonLink'
import {safariOrIos, formatMarkdown} from '../../util'

let CompositionPerformance = ({performance, composition, queueHasItems, playNow, addToQueue, showMPRLogo, showUnderwriterLogo, showDate}) => {

  let conductor
  if(performance.conductor) {
    conductor = <PersonLink person={performance.conductor} role="conductor" className="musician conductor" />
  }
  const musicians = performance.featuredartistSet.toModelArray().map((featured_artist, index) => {
    return (
      <PersonLink person={featured_artist.artist} role={featured_artist.role} className="musician" key={index} />
    )
  })

  // Audio
  // ====================

  let audioSection
  const disableOneTouchPlay = safariOrIos() && !process.env.REACT_APP_ANDROID && !process.env.REACT_APP_USE_NATIVE_PLAYER
  const audioRecording = performance.audioRecording()

  if(audioRecording) {
    let productionCredits
    const movements = performance.getCuepoints().map( (movement, index) => {
      return (
        <MovementContainer key={index} movement={movement} index={index} performance={performance} composition={composition} />
      )
    })

    if(audioRecording.production_credits) {
      const creditsLineBreaks = audioRecording.production_credits.replace(/\n\r?/g, '<br />')
      productionCredits = <div className="credit" dangerouslySetInnerHTML={{__html: creditsLineBreaks}} />
    }

    const addToQueueLink = (! queueHasItems && ! disableOneTouchPlay) ? '' : (
      <button type="button" className="button queue" onClick={addToQueue}>
             <SvgIcon icon={playlistAdd} fill="#ffffff" />
          Add to queue
       </button>
    )

   let playButtons
   if(performance.fetched_audio === 'rights') {
     playButtons = <div className="rights_message">We’re sorry, but this recording is not currently available in your location.</div>
    }
   else {
     playButtons = (
       <div className="play_buttons">
         {! disableOneTouchPlay && <button type="button" className="button play" onClick={playNow}>
             <SvgIcon icon={play} fill="#ffffff" />
             Play Performance
         </button>}
         {addToQueueLink}
       </div>
     )
   }
   audioSection = (
     <div>
       <ul className="movements">
         {movements}
       </ul>
         {playButtons}

         {productionCredits}
      </div>
    )
  }

  // Program Note
  let programNote
  if(performance.programNote()) {
    programNote = <div className="program_note long_description">
      <ReadMore lines={5} ellipsis={<span>... <a href='#'>Read more</a></span>}>
          <div dangerouslySetInnerHTML={{__html: formatMarkdown(performance.programNote())}} />
          <div className="byline" dangerouslySetInnerHTML={{__html: performance.programNoteByline()}} />
      </ReadMore>
    </div>
  }

  // Video
  // ====================
  const videoContainer = performance.videoRecording() ? (
    <CompositionVideoContainer performance_id={performance.id} composition_id={composition.id} />
  ) : null

   // Failsafe
   if(! audioSection && ! videoContainer) {
    audioSection = <div className="rights_message">Not available for streaming.</div>
   }

   return (
     <div className="composition_performance">
       <div className="header_row row">
       {showDate && <div className="columns">
         <div className="dates">{performance.dateDisplay()}{performance.premiere && <span className="premiere">&nbsp;SPCO Premiere</span>}</div>
         </div>
        }
       </div>
       <div className="row collapse">
         <div className="sidebar columns small-12 medium-4 small-order-2 medium-order-1">
           <div className="wrap">
             <div>{conductor}</div>
             <div>{musicians}</div>
             {showMPRLogo && performance.audioRecording() &&
               <div className="sponsor mpr">
                 <div className="label">Streaming Audio Provided By</div>
                 <SvgIcon icon={mpr} title="classicalMPR"/>
               </div>
             }
             {showUnderwriterLogo && performance.program.underwriter_logo &&
               <div className="sponsor underwriter_logo">
                 <p>Underwriting support provided by</p>
                 <img src={`${process.env.REACT_APP_MEDIA_BASE}/${performance.program.underwriter_logo}`} alt={performance.program.underwriter_name} />
               </div>
             }
           </div>
         </div>
         <div className="columns medium-8 small-12 main_container small-order-1 medium-order-2">
           {videoContainer}
           { audioSection }
           { programNote }
         </div>
       </div>
     </div>
  )
}

CompositionPerformance.propTypes = {
  performance: PropTypes.object,
  composition: PropTypes.object,
  queueHasItems: PropTypes.bool,
  showMPRLogo: PropTypes.bool,
  showUnderwriterLogo: PropTypes.bool,
  showDate: PropTypes.bool,
  playNow: PropTypes.func,
  addToQueue: PropTypes.func
}

CompositionPerformance.defaultProps = {
  showMPRLogo: true,
  showUnderwriterLogo: true,
  showDate: true
}

export default CompositionPerformance